<!-------------bannner------------>
<div class="banner-part"> <img src="/assets/images/donate-banner.jpg" alt="">
    <div class="container">
      <div class="wraper">
        <h1>{{ '::Title:MandirServices' | abpLocalization }}</h1>
      </div>
    </div>
  </div>
  <!-------------banner------------> 

<!--------Pooja Services---------->
<section class="upcoming-event Rt-e Pooja-Services" >
    <div class="container">          
        
      <div class="card-grid">

        <!------->
        <!-- <div class="col-12 col-md-4" *ngFor="let serviceDto of this.services"> -->
          <div class="bot-card" *ngFor="let serviceDto of this.services">
            <div class="image-s">
              <img [src]="serviceDto.poojaEvent.thumbnailUrl"  class="img-fluid" alt="Vaishno">
            </div>
            <div class="bot-card-ps">
              <div class="Donate-s" style="height: 148px; overflow: auto; padding-top: 10px;">
                <h3>{{serviceDto.poojaEvent.name}}</h3>
                <p [innerHTML]="serviceDto.poojaEvent.description"></p>
              </div>
              <div class="progress-psa">
                 <div class="processcontent d-flex  mb-2">
                    <span>{{ '::Capacity' | abpLocalization }}: {{serviceDto.poojaEvent.capacity}} </span> 
                    <span class="px-2">| </span> <span>{{ '::Duration' | abpLocalization }}: {{serviceDto.poojaEvent.duration}} </span>
                </div>      
              </div>
              <div class="button-more d-flex justify-content-between pt-1 mt-2">
                <div class="d-flex align-items-center">
                </div>
                <a class="btn btn-primary" (click)="showServiceDetails(serviceDto)"  role="button">{{ '::SendRequest' | abpLocalization }}</a>
              </div>
            </div>
          </div>
        <!-- </div> -->

       

        
      </div>
    </div>
  </section>
  <!--------Pooja Services---------->
      <!-- <div class="row member-services">
        <div class="col-lg-3 col-md-4" *ngFor="let serviceDto of this.services">
          <div class="single-foundation" (click)="showServiceDetails(serviceDto)" [style]="'cursor:pointer;'">
              <div class="foundation-image">
                  <a href="#"><img [src]="serviceDto.poojaEvent.thumbnailUrl" class="img-fluid" alt=""></a>
              </div>
              <div class="foundation-content">
                  <div class="location">
                      <a href="#" style="display: block;">
                          <h5>{{serviceDto.poojaEvent.name}}</h5>
                          <h5>
                            <small>
                              <strong>${{serviceDto.poojaEvent.cost}}</strong>
                            </small>
                          </h5>
                      </a>
                      
                  </div>
              </div>
          </div>
      </div>
      </div> -->

