<h2>{{ '::GoogleSettings' | abpLocalization }}</h2>
<h4>{{ message }}</h4>

<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::CalendarId' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <input
      type="text"
      style="width: 100%"
      pInputText
      class="p-inputtext-sm"
      [(ngModel)]="CalendarId.value"
    />
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::PoojaBookingCalendarId' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <input
      type="text"
      style="width: 100%"
      pInputText
      class="p-inputtext-sm"
      [(ngModel)]="PoojaBookingCalendarId.value"
    />
  </div>
</div>

<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::AuthJson' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <textarea
      type="text"
      style="width: 100%"
      rows="8"
      pInputTextarea
      class="p-inputtext-sm"
      [(ngModel)]="AuthJson.value"
    ></textarea>
  </div>
</div>
<div class="mt-3">
  <abp-button class="mt-3" (click)="saveSettings()">
    {{ 'AbpUi::Save' | abpLocalization }}
  </abp-button>
</div>