<h1>{{ '::MembershipForm:Title' | abpLocalization }}</h1>

<!-- <form action="?" method="POST">
  <div class="g-recaptcha" data-sitekey="6LeEYSIoAAAAALdRVq395Wff7YFz8jQwuEmEdaDk"></div>
  <br/>
  <input type="submit" value="Submit">
</form> -->

<form [formGroup]="FeedBack" class="form" style="max-width: 95%;" (ngSubmit)="onSubmit()">
  <p-steps [model]="items" [readonly]="true" [activeIndex]="stepActiveIndex"></p-steps>
<br>
<div *ngIf="activeStep === 'Personal'">
  <div class="row">

  <div class="col-lg-6">
    <div class="form-group">
      <label>{{ '::MembershipForm:FirstName' | abpLocalization }}<span class="give-required-indicator">*</span></label>
      <input
      type="text"
      class="no-margin-bottom"
      id="Name"
      [readOnly]="currentUserInfo"
      formControlName="Name"
    />    </div>
  </div>
  <div class="col-lg-6">
    <div class="form-group">
      <label>{{ '::MembershipForm:SurName' | abpLocalization }}<span class="give-required-indicator">*</span></label>
      <input
      type="text"
      class="no-margin-bottom"
      id="SurName"
      [readOnly]="currentUserInfo"
      formControlName="SurName"
    />
          </div>
  </div>
  <div class="col-lg-6">
    <div class="form-group">
      <label>{{ '::MembershipForm:Email' | abpLocalization }}<span class="give-required-indicator">*</span></label>
      <input
        type="text"
        class="no-margin-bottom"
        id="Email"
        formControlName="Email"
        placeholder="{{ '::MembershipForm:EmailPlaceholder' | abpLocalization }}"
        [readOnly]="currentUserInfo"
      />
      <span *ngIf="FeedBack.controls.Email?.errors?.notAvailable" class="text-danger">
        {{ '::MembershipForm:EmailAlreadyUsed' | abpLocalization }}
    </span>
          </div>
  </div>
  <div class="col-lg-6">
    <div class="form-group">
      <label>{{ '::MembershipForm:PhoneNumber' | abpLocalization }}<span class="give-required-indicator">*</span></label>
      <input
      type="text"
      class="no-margin-bottom"
      id="PhoneNumber"
      formControlName="PhoneNumber"
      placeholder="{{ '::MembershipForm:PhonePlaceholder' | abpLocalization }}"
      [readOnly]="currentUserInfo"
    />    </div>
  </div>
  </div>
<div *ngIf="this.currentUserInfo" >
<div [hidden]="!hasMadePayment(this.currentUserInfo.id, this.currentYear)">
  <small>{{ '::MembershipForm:PaidMembership' | abpLocalization:currentYear }}</small>
  <br />
</div>
</div>
<div class="row" >
  <div class="col-12">
    <div class="form-group">
      <label>{{ '::MembershipForm:MembershipType' | abpLocalization }}<span class="give-required-indicator">*</span>
        <i class="fa fa-question"></i></label>
      <div *ngFor="let membershipType of membershipTypes" class="field-checkbox">
        <p-radioButton [inputId]="membershipType.id" [value]="membershipType.key" formControlName="Paid"></p-radioButton> &nbsp; &nbsp;
        <label [for]="membershipType.id" class="ml-2">{{ membershipType.name }}</label>
    </div>
  </div>
  </div>
</div>
<div class="row" *ngIf="showGoogleAddress">
  <div class="col-12">
    <div class="form-group">

    <label>{{ '::MembershipForm:EnterAddress' | abpLocalization }}<span class="give-required-indicator">*</span></label><br />
    <app-google-places (setAddress)="getAddress($event)" adressType="geocode"></app-google-places>
</div>
  </div>
</div>
  <div class="row mb-20" *ngIf="!showGoogleAddress">
    <div class="col-lg-6">
      <div class="form-group">
        <label>{{ '::MembershipForm:StreetAddress' | abpLocalization }}<span class="give-required-indicator">*</span></label>
        <input
        type="text"
        class="no-margin-bottom"
        id="AddressLine1"
        formControlName="AddressLine1"
      /> 
       </div>
    </div>
    <div class="col-lg-6">
      <div class="form-group">
        <label>{{ '::MembershipForm:City' | abpLocalization }}<span class="give-required-indicator">*</span></label>
        <input
        type="text"
        class="no-margin-bottom"
        id="AddressLine2"
        formControlName="AddressLine2"
      />    </div>
    </div>
    <div class="col-lg-6">
      <div class="form-group">
        <label>{{ '::MembershipForm:Province' | abpLocalization }}<span class="give-required-indicator">*</span></label>
        <input
        type="text"
        class="no-margin-bottom"
        id="AddressLine3"
        formControlName="AddressLine3"
      />    </div>
    </div>
    <div class="col-lg-6">
      <div class="form-group">
        <label>{{ '::MembershipForm:PostalCode' | abpLocalization }}<span class="give-required-indicator">*</span></label>
        <input
        type="text"
        class="no-margin-bottom"
        id="PostalCode"
        formControlName="PostalCode"
      />    </div>
    </div>
    
  
  
  </div>
  <div class="col-lg-12">
    <div class="next-butn">
      <button (click)="moveStep(1)" class="butn" type="submit">{{ '::MembershipForm:Next' | abpLocalization }}</button>
    </div>
  </div>
  
</div>
<div *ngIf="activeStep === 'Family'" >
  <div class="container">
    <div class="row">
      <div class="col-2"><strong>{{ '::MembershipForm:FirstName' | abpLocalization }}</strong></div>
      <div class="col-2"><strong>{{ '::MembershipForm:LastName' | abpLocalization }}</strong></div>
      <div class="col-2"><strong>{{ '::MembershipForm:Phone' | abpLocalization }}</strong></div>
      <div class="col-2"><strong>{{ '::MembershipForm:Email' | abpLocalization }}</strong></div>
      <div class="col-2"><strong>{{ '::MembershipForm:VotingMember' | abpLocalization }}</strong></div>
      <div class="col-2"></div>
    </div>
  </div>
  <div class="container" formArrayName="Rows">
    <div class="row form-group mb-20" *ngFor="let obj of formArr.controls; let i = index; let l = last"
    [formGroupName]="i">
      <div class="col-2"><input
        type="text"
        class="form-control"
        id="Name"
        formControlName="Name"
      /></div>
      <div class="col-2"><input
        type="text"
        class="form-control"
        id="SurName"
        formControlName="SurName"
      /></div>
      <div class="col-2"><input
        type="phone"
        class="form-control"
        id="PhoneNumber"
        formControlName="PhoneNumber"
        placeholder="{{ '::MembershipForm:PhonePlaceholder' | abpLocalization }}"
      /></div>
      <div class="col-2"><input
        type="email"
        class="form-control"
        id="Email"
        formControlName="Email"
        placeholder="{{ '::MembershipForm:EmailPlaceholder' | abpLocalization }}"
      />
      
      <span *ngIf="obj.controls.Email?.errors?.duplicate" class="text-danger">
        {{ '::MembershipForm:DuplicateEmail' | abpLocalization }}
    </span>
    <span *ngIf="obj.controls.Email?.errors?.notAvailable" class="text-danger">
      {{ '::MembershipForm:EmailAlreadyUsed' | abpLocalization }}
  </span>
    </div>
      <div class="col-2"><input
        type="checkbox"
        id="Paid"
        formControlName="Paid"
        [hidden]="hasMadePayment(FeedBack.value.Rows[i].Id, currentYear)"
      />
      
      <span *ngIf="hasMadePayment(FeedBack.value.Rows[i].Id, currentYear)">Already Paid for {{currentYear}}
      </span>
      
    </div>
      <div class="col-2"><button
        *ngIf="!FeedBack.value.Rows[i].Id"
        (click)="deleteRow(i)"
        class="btn btn-danger"
      >
        Delete
      </button></div>
    </div>
  </div>
  <div class="next-butn">
  <button (click)="addNewRow()" class="butn" >Add Family Member</button>
</div>
  <br />
  <div *ngIf="this.getPaidMemberCount() > 0" >
    <strong>Your total due for {{getPaidMemberCount()}} voting members is ${{getPaidMemberCount() * 51}}</strong>
  </div>

  <div class="col-lg-12">
    <div class="next-butn">
      <button (click)="moveStep(-1)" class="butn" >Back</button> &nbsp; &nbsp; &nbsp;

      <button 
      *ngIf="getPaidMemberCount() > 0"
      (click)="moveStep(1)" class="butn" >Next</button>
      <button 
      *ngIf="getPaidMemberCount() === 0"

        type="submit" class="butn" [disabled]="uiBusy">Submit</button>
    </div>
  </div>
  
</div>
<div *ngIf="activeStep === 'Payment'">
  <div *ngIf="this.getPaidMemberCount() > 0" >
    <strong>Your total due for {{getPaidMemberCount()}} voting members is ${{getPaidMemberCount() * 51}}</strong>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-accept-payment 
              #payment
              *ngIf="this.getPaidMemberCount() > 0" 
              [dollarAmount]="this.getPaidMemberCount() * 51" 
              (formOnSubmit)="onSubmit()"
              [uiBusy]="uiBusy"
              ></app-accept-payment>
      </div>
      <!-- <div  [ngClass]="{'col-6':FeedBack.value.PaymentType !== 'Credit/Debit Card', 'col-12' : FeedBack.value.PaymentType === 'Credit/Debit Card'}">
        <div class="col-12 col-md-6 col-lg-3"><strong>Payment Type</strong></div>
        <div class="col-9">
          <p-dropdown
            placeholder="Select an Option"
            [style]="{ width: '100%' }"          
            [options]="types"
            name="PaymentType"
            formControlName="PaymentType"
          ></p-dropdown>
        </div>
      </div>
      <div 
      [ngClass]="{'col-6':FeedBack.value.PaymentType !== 'Credit/Debit Card', 'col-12' : FeedBack.value.PaymentType === 'Credit/Debit Card'}"
      
      *ngIf="FeedBack.value.PaymentType === 'Credit/Debit Card'">
        <app-stripe
        #stripe
            [amount]="this.getPaidMemberCount() * 5100"
            description="'Membership fee for the year"
            name ="Vaishno Devi Mandir"
            
            
          ></app-stripe>
      </div>
      <div class="col-6" [hidden]="FeedBack.value.PaymentType === 'Credit/Debit Card'">
        <div class="col-12 col-md-6 col-lg-3"><strong>Payment Detail</strong></div>
        <div class="col-9">
          <input
        type="text"
        class="form-control"
        id="PaymentTypeDetail"
        formControlName="PaymentTypeDetail"
        
      />
          
        </div>
      </div> -->
    </div>
  </div>
  <br /><br />
  <!-- <div class="next-butn">
  
<button 
type="submit" class="butn" >
<span *ngIf="FeedBack.value.PaymentType === 'Credit/Debit Card'">Pay ${{getPaidMemberCount() * 51}}</span>
<span *ngIf="FeedBack.value.PaymentType !== 'Credit/Debit Card'">Submit</span>

</button>
</div> -->
</div>
<div *ngIf="activeStep === 'Confirmation'">
  <div class="mt-40 mb-40">{{confirmationMessage}}</div>
  <button class="btn btn-primary" (click)="ref.close()">Close</button>
</div>


  
  
  

            
  <!-- <pre>{{ FeedBack.value | json }}</pre>
  {{AddressInfo.Address1}} -->
</form>
<p-confirmDialog [style]="{width: '40vw'}"></p-confirmDialog>
