<!-------------bannner------------>
<br><br>
<!-------------banner------------> 
<div class="row">
  <div class="col-12" style="margin: auto;">
    <button class="btn btn-primary mt-40 mb-40" (click)="location.back()">&lt;&lt; {{ '::SlotConfirmation:Back' | abpLocalization }}</button>
  </div>
</div>
<div class="row mt-30 mb-20" *ngIf="!slot">
  <div class="col-12" style="padding: 10%">
    <h1>{{ '::SlotConfirmation:NoAccess' | abpLocalization }}</h1>
  </div>
</div>
<div class="row" *ngIf="slot">
  
  <!------->
  <div class="col-12 col-md-4" style="margin: auto;">
    <div class="bot-card">
      <!-- <div class="image-s">
        <img [src]="serviceDto.poojaEvent.thumbnailUrl"  class="img-fluid" alt="Vaishno">
      </div> -->
      <div class="bot-card-ps">
        <div class="Donate-s" >
          <h3>{{slot.eventSlot.name}}</h3>
          <p [innerHTML]="slot.eventSlot.description"></p>
          <h2 style="text-align: center;" ><span
            style="font-size: 2em; padding: 0.3em 0.55em; border: 1px solid red;
            background-color: red; border-radius: 50%; color: #FFF
            "
            >{{slot.slotBooking.slotNumber}}</span></h2> <br>
              <div *ngIf="slot.slotBooking.usedAt"
              style="text-align: center;">{{ '::SlotConfirmation:UsedAt' | abpLocalization }} {{slot.slotBooking.usedAt | date:'dd-MMM-yyyy hh:mm a'}}</div> 
        </div>
        
        <div class="button-more d-flex justify-content-between pt-1 mt-2">
          
          <!-- [disabled]="isBookedEvent(slot)" -->
          <button class="btn btn-primary"
          *ngIf="canValidate && !slot.slotBooking.usedAt"

          (click)="useSlot(slot)"  role="button">{{ '::SlotConfirmation:UseSlot' | abpLocalization }}</button>
        </div>
      </div>



      
    </div>
  </div>

 
</div>