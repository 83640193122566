import { PagedResultDto, downloadBlob } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventTicketTypeDto, EventTicketTypeService, EventTicketTypeWithNavigationPropertiesDto } from '@proxy/event-ticket-types';
import { PublicService } from '@proxy/public';
import { ScheduledEventDto } from '@proxy/scheduled-events';
import { GetTicketSalesInput, TicketSaleDto, TicketSaleService, TicketSaleWithNavigationPropertiesDto } from '@proxy/ticket-sales';
import { filter, finalize, switchMap, tap } from 'rxjs';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';

@Component({
  selector: 'app-event-sales',
  templateUrl: './event-sales.component.html',
  styleUrls: ['./event-sales.component.scss']
})
export class EventSalesComponent implements OnInit{

  uiBusy:boolean=false;
  events:ScheduledEventDto[]=[];
  selectedEvent: ScheduledEventDto;
  eventTypes: EventTicketTypeDto[] = [];
  ticketSales: TicketSaleWithNavigationPropertiesDto[] = [];

  form: FormGroup;
  isExportToExcelBusy = false;
  isModalBusy = false;
  isModalOpen = false;
  selected?: TicketSaleWithNavigationPropertiesDto;
  constructor(
    private publicService: PublicService,
    public readonly service: TicketSaleService,
    private confirmationService: ConfirmationService,
    private toasterService:ToasterService,
    private fb: FormBuilder

  ){
    
  }

  ngOnInit(): void {
    this.publicService.getTicketedEvents().subscribe(result =>{
      this.events = result;
      if(result.length> 0){
        this.selectedEvent = result[0];
        this.updateList();
      }
    });

    
    
  }

  updateList() {    
    this.publicService.getTicketSalesByEventId(this.selectedEvent.id).subscribe(result => {
      this.ticketSales = result;
      let types= this.ticketSales.map(t => t.eventTicketType);
      this.eventTypes = [];
      types.forEach(type => {
        if(this.eventTypes.filter(e => e.id == type.id).length == 0){
          this.eventTypes.push(type);
        }
      })
    })   
  }

  buildForm() {
    const {
      originalPrice,
      soldPrice,
      discountCode,
      paymentType,
      paymentConfirmationCode,
      quantitySold,
      location,
      ticketUsed,
      ticketNumber,
      eventTicketTypeId,
      buyerId,
      paymentId,
    } = this.selected?.ticketSale || {};

    this.form = this.fb.group({
      originalPrice: [originalPrice ?? null, []],
      soldPrice: [soldPrice ?? null, []],
      discountCode: [discountCode ?? null, []],
      paymentType: [paymentType ?? null, []],
      paymentConfirmationCode: [paymentConfirmationCode ?? null, []],
      quantitySold: [quantitySold ?? '0', []],
      location: [location ?? 'Table: To be assigned', []],
      ticketUsed: [ticketUsed ?? '[]', []],
      ticketNumber: [ticketNumber ?? null, []],
      eventTicketTypeId: [eventTicketTypeId ?? null, [Validators.required]],
      buyerId: [buyerId ?? null, []],
      paymentId: [paymentId ?? null, [Validators.required]],
    });
  }

  hideForm() {
    this.isModalOpen = false;
    this.form.reset();
  }

  showForm() {
    this.buildForm();
    this.isModalOpen = true;
  }

  submitForm() {
    if (this.form.invalid) return;

    const request = this.selected
      ? this.service.update(this.selected.ticketSale.id, {
          ...this.form.value,
          concurrencyStamp: this.selected.ticketSale.concurrencyStamp,
        })
      : this.service.create(this.form.value);

    this.isModalBusy = true;

    request
      .pipe(
        finalize(() => (this.isModalBusy = false)),
        tap(() => this.hideForm())
      )
      .subscribe(result => {
        this.updateList();
      });
  }

  create() {
    this.selected = undefined;
    this.showForm();
  }

  update(record: TicketSaleWithNavigationPropertiesDto) {
    this.selected = record;
    this.showForm();
  }


  // exportToExcel() {
  //   this.isExportToExcelBusy = true;
  //   this.service
  //     .getDownloadToken()
  //     .pipe(
  //       switchMap(({ token }) =>
  //         this.service.getListAsExcelFile({ downloadToken: token, filterText: this.list.filter })
  //       ),
  //       finalize(() => (this.isExportToExcelBusy = false))
  //     )
  //     .subscribe(result => {
  //       downloadBlob(result, 'TicketSale.xlsx');
  //     });
  // }

  getTickets(eventTypeId):TicketSaleWithNavigationPropertiesDto[]{
    var res= this.ticketSales.filter(t => t.ticketSale.eventTicketTypeId == eventTypeId);
    return res;
  }

  getTotalTicketsSold(eventTypeId):number{
    return this.getTickets(eventTypeId).reduce((acc, curr) => acc + curr.ticketSale.quantitySold * curr.eventTicketType.stubsIncluded, 0);
  }

  getTotalTicketsSoldAmount(eventTypeId): number {
    return this.getTickets(eventTypeId).reduce((acc, curr) => acc + Number(curr.payment.transactionAmount), 0);
  }

  getAllTicketsSoldCount():number{
    return this.ticketSales.reduce((acc, curr) => acc + curr.ticketSale.quantitySold * curr.eventTicketType.stubsIncluded, 0);
  }

  getAllTicketsSoldAmount(): number {
    return this.ticketSales.reduce((acc, curr) => acc + Number(curr.payment.transactionAmount), 0);
  }

  getEventwithTickets(){

  }
  sendEventEmail(item:any){
    this.confirmationService
      .info(
        '::SendEventEmailToDevotee',
        '::AreYouSureToSendEmail',
      )
      .pipe(filter(status => status === Confirmation.Status.confirm))
      .subscribe(()=>{
        this.publicService.sendEventTicketEmailByTicketIds(item.ticketSale.id).subscribe(result => {
          this.toasterService.success('::EmailSentSuccessfully', null);
        })
      });  
    return false;
  }
  sendEventEmailToAll(){
    var tickets=[];
    for (let index = 0; index < this.eventTypes.length; index++) {
      const element = this.eventTypes[index];
      var ticketsList= this.getTickets(element.id)
      for (let idxTicket = 0; idxTicket < ticketsList.length; idxTicket++) {
        const elementTickets = ticketsList[idxTicket];
        tickets.push(elementTickets.ticketSale.id);
      }
    }

    this.confirmationService
      .info(
        '::SendEventEmailToAllDevotee',
        '::AreYouSureToSendEmail',
      )
      .pipe(filter(status => status === Confirmation.Status.confirm))
      .subscribe(()=>{
        this.publicService.sendEventTicketEmailByTicketIds(tickets.toString()).subscribe(result => {
          this.toasterService.success('::EmailSentSuccessfully', null);
        })
      });
    return false;
  }
}
