import { LocalizationService, PermissionService, ReplaceableComponentsService, } from '@abp/ng.core';
import { eThemeLeptonXComponents } from '@volosoft/abp.ng.theme.lepton-x'
import { Component, OnInit, AfterViewInit  } from '@angular/core';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { SettingTabsService } from '@abp/ng.setting-management/config';
import { SettingComponent } from './setting/setting/setting.component';
import { GoogleSettingsComponent } from './setting/google-settings/google-settings.component';
import { PaymentSettingsComponent } from './setting/payment-settings/payment-settings.component';
import { LoadingService } from './services/loading-service';
import { delay } from 'rxjs';
import { eAccountComponents } from '@volo/abp.ng.account/public';
import { LoginComponent } from './accounts/login/login.component';
import { RegisterComponent } from './accounts/register/register.component';
import { ForgotPasswordComponent } from './accounts/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './accounts/reset-password/reset-password.component';
import { AccountLayoutComponent } from './accounts/account-layout/account-layout.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, AfterViewInit {

  loading: boolean = false;
  webSiteMode: string = environment.websiteMode;
  listenToLoading(): void {
    this._loading.loadingSub.pipe(delay(1)).subscribe(loading => {
      
      this.loading = loading;
    });
  }

  constructor(
    private permissionService: PermissionService,
    private settingTabs: SettingTabsService,
    private localizationService: LocalizationService,
    private replaceableComponents: ReplaceableComponentsService, // injected the service
    private _loading: LoadingService,
  ) {
    this.listenToLoading();
    this.replaceableComponents.add({
      component: PublicLayoutComponent,
      key: eThemeLeptonXComponents.EmptyLayout,
    });

    let allowSettings = this.permissionService.getGrantedPolicy('Mandir.Settings');

    if(allowSettings){
    settingTabs.add([
      {
        name: this.localizationService.instant('::Settings'),
        order: 1,
        component: SettingComponent,
      },
      
      {
        name: this.localizationService.instant('::GoogleSettings'),
        order: 1,
        component: GoogleSettingsComponent,
      },
      {
        name: this.localizationService.instant('::PaymentSettings'),
        order: 1,
        component: PaymentSettingsComponent,
      }
    ]);
    }
  }
  ngAfterViewInit(){
    // if(environment.websiteMode != 'PROD'){
    //   setTimeout(() => {
    //     document.documentElement.style.setProperty('--lpx-primary-color', environment.primaryColor);
    //   }, 1000);
    // }
  }
  ngOnInit() {
    this.replaceableComponents.add({
      key: eAccountComponents.Login,
      component: LoginComponent,
    });
    
    this.replaceableComponents.add({
      key: eAccountComponents.Register,
      component: RegisterComponent,
    });

    this.replaceableComponents.add({
      key: eAccountComponents.ForgotPassword,
      component: ForgotPasswordComponent,
    });

    this.replaceableComponents.add({
      key: eAccountComponents.ResetPassword,
      component: ResetPasswordComponent,
    });

    this.replaceableComponents.add({
      key: eThemeLeptonXComponents.AccountLayout,
      component: AccountLayoutComponent,
    });
  }

  
}
