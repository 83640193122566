import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const SENT_EMAIL_LOGS_SENT_EMAIL_LOG_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/sent-email-logs',
        name: '::Menu:SentEmailLogs',
        iconClass: 'fas fa-envelope',
        layout: eLayoutType.empty,
        invisible: true,
        requiredPolicy: 'Mandir.SentEmailLogs',
      },
    ]);
  };
}
