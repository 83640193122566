<div class="banner-part"> <img src="/assets/images/donate-banner.jpg" alt="" class="short-banner">
  <div class="container">
    <div class="wraper">
      <h1>{{ '::EventDetails:Title' | abpLocalization }}</h1>
    </div>
  </div>
</div>
<div class="background-banner1">
<div class="container-small mt-40" [abpLoading]="uiBusy">
  <button class="btn btn-primary mt-40 mb-40" (click)="location.back()">&lt;&lt; {{ '::EventDetails:Back' | abpLocalization }}</button>
  <p-steps  *ngIf="event.hasTickets" [model]="items" [readonly]="false" [activeIndex]="stepActiveIndex"></p-steps>

    <div class="single-donation-wrap align-item-start justify-content-between mt-40">
      <div class="row" [hidden]="activeStep !== 'Event'">
        <div class="col-lg-5">
          <div class="donation-image">
            <img *ngIf="event.imageUrl" [src]="event.imageUrl" class="img-fluid" alt="{{ '::EventDetails:AltText' | abpLocalization }}">
            <img *ngIf="!event.imageUrl" src="/assets/images/icons/aum.png" class="img-fluid"
              alt="{{ '::EventDetails:AltText' | abpLocalization }}">


          </div>
        </div>
        <div class="col-lg-6">
          <div class="donation-content ml-lg-5">
            <div class="donation-title mb-30">
              <h4 class="mb-15">{{event.name}}</h4>
              <div class="event-date"><span>{{event.date | date}}</span> <span> &#64; {{event.startsAt | date:'hh:mm a'}} to
                  {{event.endsAt | date:'hh:mm a'}}</span></div>
              <p style="font-size: 1.125rem;" [innerHTML]="event.description"></p>
              <a class="btn btn-primary"
                        *ngIf="event.hasSlots" [routerLink]="['/event-slot']" role="button">{{ '::EventDetails:VirtualQueue' | abpLocalization }}</a>
            </div>

            <div class="container" *ngIf="event.hasTickets && eventTypes.length">
              <div class="row select-options cursor-pointer" (click)="selectOption(item)"
              for="evnetType_{{item.id}}" [ngClass]="{'disabled' : isSoldOut(item)}"
                *ngFor="let item of eventTypes">
                <div class="col-1 text-end">
                  <input type="radio" id="evnetType_{{item.id}}"
                  [(ngModel)]="selectedTicketType" [value]="item" [abpDisabled]="isSoldOut(item)"
                    (click)="getAvailableTickets(item)" [ngModelOptions]="{standalone: true}" />
                </div>
                <div class="col-11">
                  <div class="row">
                    <div class="col-9">
                      <strong>{{item.name}} <span *ngIf="isSoldOut(item)">({{ '::EventDetails:SoldOut' | abpLocalization }})</span></strong>
                      <p>{{item.description}}</p>

                    </div>
                    <div class="col-3">
                      <h3>${{item.price}}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="row" *ngIf="selectedTicketType.id">
                <div class="col-4">
                  <span>{{ '::EventDetails:Quantity' | abpLocalization }}</span>
                  <p-dropdown [ngModelOptions]="{standalone: true}" [options]="tickeCount" [(ngModel)]="quantity"
                    optionLabel="name" optionValue="id"></p-dropdown>

                </div>
                <!-- <div class="col-8">
                  <div>
                    <span>Promo Code</span>
                  <div class="p-inputgroup">
                    <input type="text" pInputText [ngModelOptions]="{standalone: true}" [options]="tickeCount" [(ngModel)]="promoCode"/>
                    <button type="button" 
                    (click)="applyPromo()"
                    pButton icon="fa fa-check" styleClass="p-button-warn"></button>
                  </div>
                  <div *ngIf="promoMessage">{{promoMessage}}</div>

                  </div>
                 </div> -->
                </div>
                 <div class="row" *ngIf="selectedTicketType.id">
                
                <div class="col-12" style="padding-top:10px">
                  <h4>{{ '::EventDetails:Total' | abpLocalization }}: ${{getAmount()}}</h4>
                </div>
              </div>
              <br />


              <div class="row">
                <div class="col-12 next-butn">
                  <button (click)="ticketSelected()" class="butn">{{ '::EventDetails:Next' | abpLocalization }}</button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div [hidden]="activeStep !== 'Personal'">
        <form [formGroup]="EventForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()" *ngIf="EventForm">

        <div  [hidden]="authService.isAuthenticated">
          <div class="row mb-20">
            <div class="col-12 col-md-6 col-lg-3">
              <strong>{{ '::EventDetails:FirstName' | abpLocalization }}</strong>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <input type="text" class="form-control" id="Name" formControlName="Name" />
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <strong>{{ '::EventDetails:LastName' | abpLocalization }}</strong>
            </div>
  
            <div class="col-12 col-md-6 col-lg-3">
              <input type="text" class="form-control" id="SurName" formControlName="SurName" />
            </div>
  
  
          </div>
          <div class="row mb-20" >
            <div class="col-12 col-md-6 col-lg-3">
              <strong>{{ '::EventDetails:Email' | abpLocalization }}</strong>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <input type="text" class="form-control" id="Email" formControlName="Email" placeholder="name@email.com" />
              <span *ngIf="EventForm.controls.Email?.errors?.notAvailable" class="text-danger">
                {{ '::EventDetails:EmailAlreadyUsed' | abpLocalization }}
              </span>
            </div>
            <div class="col-12 col-md-6 col-lg-3"><strong>{{ '::EventDetails:PhoneNumber' | abpLocalization }}</strong></div>
  
            <div class="col-12 col-md-6 col-lg-3"><input type="text" class="form-control" id="PhoneNumber"
                formControlName="PhoneNumber" placeholder="111-222-3333" /></div>
  
  
          </div>




          <div class="row mb-20" *ngIf="showGoogleAddress">
            <div class="col-12">
              <strong><label>{{ '::EventDetails:EnterAddress' | abpLocalization }}<span class="give-required-indicator">*</span></label></strong>
            </div>
            <div class="col-12">
              <app-google-places class="custom-control"
              (setAddress)="getAddress($event)" adressType="geocode"></app-google-places>
              <span *ngIf="EventForm.invalid && (EventForm.controls.AddressLine1?.errors?.required || EventForm.controls.AddressLine3?.errors?.required || EventForm.controls.PostalCode?.errors?.required)" class="text-danger">
                {{ '::EventDetails:AddressRequired' | abpLocalization }}
              </span>
            </div>
          </div>
          <div class="row mb-20" *ngIf="!showGoogleAddress">
            <div class="col-12 col-md-6 col-lg-3">
              <strong><label>{{ '::EventDetails:StreetAddress' | abpLocalization }}<span class="give-required-indicator">*</span></label></strong>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <input type="text" class="form-control" id="AddressLine1" formControlName="AddressLine1"  />
            </div>
            <div class="col-12 col-md-6 col-lg-3"><strong><label>{{ '::EventDetails:City' | abpLocalization }}<span class="give-required-indicator">*</span></label></strong></div>
  
            <div class="col-12 col-md-6 col-lg-3"><input type="text" class="form-control" id="AddressLine1"
                formControlName="AddressLine1"/></div>
          </div>
          <div class="row mb-20" *ngIf="!showGoogleAddress">
            <div class="col-12 col-md-6 col-lg-3">
              <strong><label>{{ '::EventDetails:Province' | abpLocalization }}<span class="give-required-indicator">*</span></label></strong>
            </div>
            <div class="col-12 col-md-6 col-lg-3">
              <input type="text" class="form-control" id="AddressLine3" formControlName="AddressLine3"  />
            </div>
            <div class="col-12 col-md-6 col-lg-3"><strong><label>{{ '::EventDetails:PostalCode' | abpLocalization }}<span class="give-required-indicator">*</span></label></strong></div>
  
            <div class="col-12 col-md-6 col-lg-3"><input type="text" class="form-control" id="PostalCode"
                formControlName="PostalCode" /></div>
          </div>
        </div>
        <div class="next-butn">
          <a (click)="confirmUser()" class="butn" >{{ '::EventDetails:Next' | abpLocalization }}</a>

        </div>
        

      </form>
      </div>
      <div class="row" *ngIf="activeStep === 'Payment'">
        <app-accept-payment 
              #payment
              *ngIf="selectedTicketType.id && quantity > 0" 
              [newUser]="newUser"
              [dollarAmount]="getAmount()" 
              (formOnSubmit)="onSubmit()"
              [uiBusy]="uiBusy"
              ></app-accept-payment>
        <!-- <div class="row">
          <div class="col-12">
            <div class="flex flex-wrap gap-3">
              <div class="flex align-items-center mb-20">
                <p-radioButton name="paymentMode" value="Online" [(ngModel)]="paymentMode" [ngModelOptions]="{standalone: true}"
                  inputId="paymentMode_Online"></p-radioButton>
                <label for="paymentMode_Online" class="ml-10">Debit/Credit Card</label>
              </div>
              
            </div>

          </div>
        </div>
        <div class="row" *ngIf="paymentMode ==='Online'">
          <div class="col-12">
            <app-stripe *ngIf="selectedTicketType.id && quantity > 0" #stripe
              [amount]="this.selectedTicketType.price * quantity * 100" description="Event Tickets for {{event.name}}"
              name="Vaishno Devi Mandir" (paymentVerified)="sendRequest($event)"></app-stripe>
          </div>
        </div>
        <div class="row" *ngIf="paymentMode !=='Online'">
          <div class="col-12">
            <span *ngIf="paymentMode ==='Interac'">Please provide interac email and confirmation number:</span>
            <span *ngIf="paymentMode ==='Cheque'">Please provide cheque number and account holder's name:</span>
            <span *ngIf="paymentMode ==='Cash'"> Please provide cash receipt number:</span>

          </div>
          <div class="col-12">
            <textarea rows="5" cols="50" *ngIf="paymentMode && paymentMode !=='Online'"
            [(ngModel)]="paymentDetail" [ngModelOptions]="{standalone: true}"></textarea>
          </div>
        </div> -->
        <!-- <div class="row mt-30">
          <div class="col-12 next-butn">
            <button class="butn" (click)="onSubmit()">Buy Ticket(s)</button>
          </div>
        </div> -->
      </div>
    

    </div>

</div>
</div>