import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountExtnService } from '@proxy/account';
import { finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  inProgress = false;
  emailSent = false;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountExtnService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit(): void {
    if (this.form.invalid || this.inProgress) {
      return;
    }

    this.inProgress = true;

    this.accountService
      .sendPasswordResetLink({
        email: this.form.get('email').value,
        appUrl: environment.application.baseUrl
      })
      .pipe(
        finalize(() => {
          this.inProgress = false;
        })
      )
      .subscribe(() => {
        this.emailSent = true;
      });
  }
} 