import { AuthService, ConfigStateService } from '@abp/ng.core';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ScriptLoaderService, ScriptModel } from '../services/script.service';
import { PublicService } from '@proxy/public';
import { ResourceLinkDto } from '@proxy/resource-links';
import { SessionStateService } from '@abp/ng.core';
import { MultiLanguageResourceLinkDto } from '@proxy/public/models';
import { ManidrSettingService } from '../services/settings.service';

declare const window: any;


@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
  providers:[ScriptLoaderService ]
})
export class PublicLayoutComponent implements OnInit, AfterViewInit{
  currentUser$ = this.configState.getOne$('currentUser');
  urlPath:string;
  resourceLinks: ResourceLinkDto[]=[];
  multiLanguageResourceLinks: MultiLanguageResourceLinkDto[]=[];
  currentLanguage: string = 'en-GB';
  logoUrl: string = '';
  userMenuItems: MenuItem[] =[
        
    {
      label: 'Logout',
      icon: 'pi pi-exit',
      command: () => {
          this.logout();
      }
    }
];


  get hasLoggedIn(): boolean {
    return this.authService.isAuthenticated
  }
  login() {
    this.authService.navigateToLogin();
  }
  logout(){
    this.authService.logout();
  }
  constructor(private authService: AuthService,
    private configState: ConfigStateService,
    public activatedRoute: ActivatedRoute,
    private scriptService: ScriptLoaderService,
    private publicService: PublicService,
    private sessionStateService: SessionStateService,
    private settingService: ManidrSettingService
    ){
      this.logoUrl = this.settingService.getLogoUrl();
    this.urlPath = window.location.pathname;
    // Get current language from ABP
    this.currentLanguage = this.sessionStateService.getLanguage() || 'en-GB';
    
    // For consistency, get the base language code for translations
    if (this.currentLanguage.includes('-')) {
      console.log('Original language from ABP:', this.currentLanguage);
      // Extract the base code while maintaining the full culture code
      const baseLang = this.currentLanguage.split('-')[0];
      console.log('Using base language code for translations:', baseLang);
    }
    
    // Use the new method for getting translated resource links
    this.publicService.getResourceLinksWithTranslationsByLanguages(['en', 'hi','gu','pa']).subscribe(result => {
      this.multiLanguageResourceLinks = result;
      this.updateResourceLinksForCurrentLanguage();
    });
  }

  // Helper method to get the appropriate resource links based on current language
  updateResourceLinksForCurrentLanguage() {
    if (!this.multiLanguageResourceLinks || this.multiLanguageResourceLinks.length === 0) {
      return;
    }

    // If language is English, use original content
    if (this.currentLanguage.startsWith('en')) {
      this.resourceLinks = this.multiLanguageResourceLinks.map(ml => ml.original);
    } 
    // For non-English languages, use translated content if available
    else if (this.currentLanguage !== 'en' && this.multiLanguageResourceLinks.length > 0) {
      const langCode = this.currentLanguage.split('-')[0]; // Get base language code
      
      // Check if translations exist for the current language
      if (this.multiLanguageResourceLinks[0].translations && this.multiLanguageResourceLinks[0].translations[langCode]) {
        this.resourceLinks = this.multiLanguageResourceLinks.map(ml => {
          const translatedLink = ml.translations[langCode];
          // Create a new object that matches the ResourceLinkDto structure
          return {
            id: translatedLink.id,
            name: translatedLink.name,
            url: translatedLink.url,
            target: translatedLink.target,
            sequence: translatedLink.sequence,
            parentId: translatedLink.parentId,
            concurrencyStamp: ml.original.concurrencyStamp,
            creationTime: ml.original.creationTime,
            creatorId: ml.original.creatorId,
            lastModificationTime: ml.original.lastModificationTime,
            lastModifierId: ml.original.lastModifierId,
          };
        });
      } else {
        // Fallback to original content if no translation available
        this.resourceLinks = this.multiLanguageResourceLinks.map(ml => ml.original);
      }
    }
  }

  ngOnInit(): void {
      
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
      
  }

  get isHomePage(){
    return this.activatedRoute.url;
    //this.urlPath =d[0].path
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      document.title = 'Vaishno Devi Mandir';
    }, 500)
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
      
    // setTimeout(() => {
    //   this.scriptService.load({
    //     name:'mainjs',
    //     src:'/assets/js/scripts.js'
    //           } as ScriptModel).subscribe(
    //             result => {
                    
    //             }
    //           )
    // }, 3000)
  }

  

}
