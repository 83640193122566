import { mapEnumToOptions } from '@abp/ng.core';

export enum Frequencies {
  None = 0,
  Daily = 1,
  Weekly = 2,
  BiWeekly = 3,
  Monthly = 4,
  Annual = 5,
}

export const frequenciesOptions = mapEnumToOptions(Frequencies);
