<h2>{{ '::PaymentSettings' | abpLocalization }}</h2>
<h4>{{ message }}</h4>

<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::StripeKey' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <input
      type="text"
      style="width: 100%"
      pInputText
      class="p-inputtext-sm"
      [(ngModel)]="StripeKey.value"
    />
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::StripeSecretKey' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <input
      type="text"
      style="width: 100%"
      pInputText
      class="p-inputtext-sm"
      [(ngModel)]="StripeSecretKey.value"
    />
  </div>
</div>


<div class="mt-3">
  <abp-button class="mt-3" (click)="saveSettings()">
    {{ 'AbpUi::Save' | abpLocalization }}
  </abp-button>
</div>