import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const PROGRAM_LIST_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/program-list',
        iconClass: 'fas fa-money',
        name: '::Menu:Payments',
        layout: eLayoutType.empty,
      },
    ]);
  };
}
