<div class="banner-part"> <img src="/assets/images/donate-banner.jpg" alt="" class="short-banner">
  <div class="container">
    <div class="wraper">
      <h1>{{ '::EventSales:Title' | abpLocalization }}</h1>
    </div>
  </div>
</div>
<div class="background-banner1">
<div class="container-small mt-40" [abpLoading]="uiBusy">
  <div class="row">
    <div class="col-8 mb-3">
      <label class="form-label mr-20" for="attendance-student-id">
        {{ '::Event' | abpLocalization }} *
      </label>

      <p-dropdown [options]="events" [(ngModel)]="selectedEvent" 
      (onChange)="updateList()"
      optionLabel="name"></p-dropdown>
    </div>
    <div class="col-4 mt-3 pt-1">
      <button (click)="sendEventEmailToAll()" class="btn btn-primary">{{'::SendEmailToAllDevotee'|abpLocalization}}</button>
    </div>
  </div>
  <div class="row mb-40"  *ngFor="let eventType of eventTypes" >
    <div class="col-12">
      <h2>      
        {{eventType.name}}
      </h2>
      <div class="row mb-20">
        <div class="col-3"><strong>{{ '::EventSales:Name' | abpLocalization }}</strong></div>
        <div class="col-2"><strong>{{ '::EventSales:Mode' | abpLocalization }}</strong></div>
        <div class="col-2"><strong>{{ '::EventSales:Date' | abpLocalization }}</strong></div>
        <div class="col-1"><strong>{{ '::EventSales:Qty' | abpLocalization }}</strong></div>
        <div class="col-1"><strong>{{ '::EventSales:Amt' | abpLocalization }}</strong></div>
        <div class="col-2"><strong>{{ '::EventSales:TicketNumber' | abpLocalization }}</strong></div>
        <div class="col-1"><strong>{{ '::EventSales:TableNumber' | abpLocalization }}</strong></div>

      </div>
      <div class="row" *ngFor="let item of getTickets(eventType.id)">
          <div class="col-3 ">
            <a class="col-3" 
            target="_blank"
            [routerLink]="['/membership/'+item.identityUser.id]">            
            {{item.identityUser.name}} {{item.identityUser.surname}}
            </a>
            &nbsp; &nbsp; <i class="fa fa-ticket cursor-pointer" [routerLink]="['/ticket/'+item.ticketSale.id]"></i>
            <a class="col-3" (click)="sendEventEmail(item)">            
              </a>
              &nbsp; &nbsp; <i class="fa fa-envelope cursor-pointer" (click)="sendEventEmail(item)"></i>
          </div>
          <div class="col-2">
            {{item.ticketSale.paymentType}}
          </div>
          <div class="col-2">
            {{item.ticketSale.creationTime | date: 'MM/dd/yyyy'}}
          </div>
          <div class="col-1">
            {{item.ticketSale.quantitySold}}
          </div>
          <div class="col-1">
            ${{item.payment?.transactionAmount}}
          </div>
          <div class="col-2">
            {{item.ticketSale.ticketNumber}}
            <i class="fa fa-pencil" (click)="update(item)"></i>
          </div>
          <div class="col-1">
            {{item.ticketSale.location}}
          </div>
      </div>
      <div class="row mb-20 mt-2">
        <div class="col-7" style="text-align: right;"><strong>{{ '::EventSales:SalesFor' | abpLocalization }} {{eventType.name}}</strong></div>
        <div class="col-1"><strong>{{getTotalTicketsSold(eventType.id)}}</strong></div>
        <div class="col-1"><strong>${{getTotalTicketsSoldAmount(eventType.id)}}</strong></div>
        <div class="col-2"><strong></strong></div>
        <div class="col-1"><strong></strong></div>

      </div>
    </div>
  </div>
  <div class="row mb-20 mt-2">
    <div class="col-7" style="text-align: right;"><strong>{{ '::EventSales:TotalSales' | abpLocalization }}</strong></div>
    <div class="col-1"><strong>{{getAllTicketsSoldCount()}}</strong></div>
    <div class="col-1"><strong>${{getAllTicketsSoldAmount()}}</strong></div>
    <div class="col-2"><strong></strong></div>
    <div class="col-1"><strong></strong></div>

  </div>
</div>
</div>
<abp-modal [busy]="isModalBusy" [(visible)]="isModalOpen">
  <ng-template #abpHeader>
    <h3>
      {{ (selected ? 'AbpUi::Edit' : '::NewTicketSale') | abpLocalization }}
    </h3>
  </ng-template>

  <ng-template #abpBody>
    <form [formGroup]="form" id="ticket-sale" (ngSubmit)="submitForm()" validateOnSubmit>
      <div class="mt-2 fade-in-top">
        
        <div class="mb-3">
          <label class="form-label" for="ticket-sale-location">
            {{ '::Table' | abpLocalization }}
          </label>

          <input id="ticket-sale-location" formControlName="location" class="form-control" />
        </div>
        
        <div class="mb-3">
          <label class="form-label" for="ticket-sale-ticket-number">
            {{ '::TicketNumber(s)' | abpLocalization }} (Qty: {{selected.ticketSale.quantitySold}})
          </label>

          <input
            id="ticket-sale-ticket-number"
            formControlName="ticketNumber"
            class="form-control"
          />
        </div>
        
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>

    <abp-button iconClass="fa fa-check" buttonType="submit" formName="ticket-sale">
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>