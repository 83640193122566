import { CoreModule } from '@abp/ng.core';
import { GdprConfigModule } from '@volo/abp.ng.gdpr/config';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommercialUiConfigModule } from '@volo/abp.commercial.ng.ui/config';
import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { AuditLoggingConfigModule } from '@volo/abp.ng.audit-logging/config';
import { IdentityConfigModule } from '@volo/abp.ng.identity/config';
import { LanguageManagementConfigModule } from '@volo/abp.ng.language-management/config';
import { registerLocale } from '@volo/abp.ng.language-management/locale';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { TextTemplateManagementConfigModule } from '@volo/abp.ng.text-template-management/config';
import { HttpErrorComponent, ThemeLeptonXModule } from '@volosoft/abp.ng.theme.lepton-x';
import { SideMenuLayoutModule } from '@volosoft/abp.ng.theme.lepton-x/layouts';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { OpeniddictproConfigModule } from '@volo/abp.ng.openiddictpro/config';
import { FeatureManagementModule } from '@abp/ng.feature-management';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { ServiceWorkerModule } from '@angular/service-worker';
import { VOLUNTEERS_VOLUNTEER_ROUTE_PROVIDER } from './volunteers/volunteer/providers/volunteer-route.provider';
import { SCHEDULED_EVENTS_SCHEDULED_EVENT_ROUTE_PROVIDER } from './scheduled-events/scheduled-event/providers/scheduled-event-route.provider';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { SERVICE_CATEGORIES_SERVICE_CATEGORY_ROUTE_PROVIDER } from './service-categories/service-category/providers/service-category-route.provider';
import { BOOKINGS_BOOKING_ROUTE_PROVIDER } from './bookings/booking/providers/booking-route.provider';

import { POOJA_EVENTS_POOJA_EVENT_ROUTE_PROVIDER } from './pooja-events/pooja-event/providers/pooja-event-route.provider';
import { EventListComponent } from './event-list/event-list.component';
import { CardModule } from 'primeng/card';
import { MembershipComponent } from './membership/membership.component';
import { PAYMENT_TYPES_PAYMENT_TYPE_ROUTE_PROVIDER } from './payment-types/payment-type/providers/payment-type-route.provider';
import { PAYMENTS_PAYMENT_ROUTE_PROVIDER } from './payments/payment/providers/payment-route.provider';
import { ContentPageComponent } from './content-page/content-page.component';
import { ARTICLES_ARTICLE_ROUTE_PROVIDER } from './articles/article/providers/article-route.provider';
import { MemberServicesComponent } from './member-services/member-services.component';
import { MembershipFormComponent } from './membership-form/membership-form.component';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';

import { SettingComponent } from './setting/setting/setting.component';
import { SNIPPETS_SNIPPET_ROUTE_PROVIDER } from './snippets/snippet/providers/snippet-route.provider';
import { EMAIL_TEMPLATES_EMAIL_TEMPLATE_ROUTE_PROVIDER } from './email-templates/email-template/providers/email-template-route.provider';
import { MANAGE_SNIPPETS_SNIPPET_ROUTE_PROVIDER } from './snippets/snippet/manage-snippet/providers/manage-snippet-route.provider';
import { DropdownModule } from 'primeng/dropdown';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { StripeModule } from 'stripe-angular';

import { SharedModule } from './shared/shared.module';
import { StepsModule } from 'primeng/steps';
import { CAMPAIGNS_CAMPAIGN_ROUTE_PROVIDER } from './campaigns/campaign/providers/campaign-route.provider';
import { DONATIONS_DONATION_ROUTE_PROVIDER } from './donations/donation/providers/donation-route.provider';
import { DonationComponent } from './donation/donation.component';
import { EmailTrialRunComponent } from './email-trial-run/email-trial-run.component';
import { CalendarModule } from 'primeng/calendar';
import { ANNOUNCEMENTS_ANNOUNCEMENT_ROUTE_PROVIDER } from './announcements/announcement/providers/announcement-route.provider';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BANNERS_BANNER_ROUTE_PROVIDER } from './banners/banner/providers/banner-route.provider';
import { RESOURCE_LINKS_RESOURCE_LINK_ROUTE_PROVIDER } from './resource-links/resource-link/providers/resource-link-route.provider';
import { EVENT_TICKET_TYPES_EVENT_TICKET_TYPE_ROUTE_PROVIDER } from './event-ticket-types/event-ticket-type/providers/event-ticket-type-route.provider';
import { TICKET_SALES_TICKET_SALE_ROUTE_PROVIDER } from './ticket-sales/ticket-sale/providers/ticket-sale-route.provider';
import { EventTicketModule } from './event-ticket/event-ticket.module';
import { EVENT_TICKET_ROUTE_PROVIDER } from './event-ticket/event-ticket-route.provider';
import { EventDetailsModule } from './shared/event-details/event-details.module';
import { EventTicketAdminComponent } from './event-ticket-admin/event-ticket-admin.component';
import { GooglePlacesModule } from './shared/google-places/google-places.module';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { MANAGE_PAYMENTS_PAYMENT_ROUTE_PROVIDER } from './payments/manage-payment/providers/manage-payment-route.provider';
import { SUBSCRIPTIONS_SUBSCRIPTION_ROUTE_PROVIDER } from './subscriptions/subscription/providers/subscription-route.provider';
import { UNSUBSCRIBE_ROUTE_PROVIDER } from './unsubscribe/unsubscribe-route-provider';
import { PROGRAMS_PROGRAM_ROUTE_PROVIDER } from './programs/program/providers/program-route.provider';
import { STUDENTS_STUDENT_ROUTE_PROVIDER } from './students/student/providers/student-route.provider';
import { PROGRAM_LIST_ROUTE_PROVIDER } from './program-list/program-list-route.provider';
import { ATTENDANCES_ATTENDANCE_ROUTE_PROVIDER } from './attendances/attendance/providers/attendance-route.provider';
import { GoogleSettingsComponent } from './setting/google-settings/google-settings.component';
import { ManidrSettingService } from './services/settings.service';
import { PaymentSettingsComponent } from './setting/payment-settings/payment-settings.component';
import { MANAGE_STUDENTS_STUDENT_ROUTE_PROVIDER } from './students/manage-student/providers/student-route.provider';
import { RadioButtonModule } from 'primeng/radiobutton';

import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { EventSalesComponent } from './event-sales/event-sales.component';
import { ReceiptsComponent } from './receipts/receipts.component';
import { DISCOUNT_CODES_DISCOUNT_CODE_ROUTE_PROVIDER } from './discount-codes/discount-code/providers/discount-code-route.provider';
import { REPORTS_ROUTE_PROVIDER } from './reports/reports-route.provider';
import { EVENT_SLOTS_EVENT_SLOT_ROUTE_PROVIDER } from './event-slots/event-slot/providers/event-slot-route.provider';
import { SLOT_BOOKINGS_SLOT_BOOKING_ROUTE_PROVIDER } from './slot-bookings/slot-booking/providers/slot-booking-route.provider';
import { SlotBookingModule } from './slot-booking/slot-booking.module';
import { ReceiptComponent } from './receipts/receipt/receipt.component';
import { PAYMENT_REQUESTS_PAYMENT_REQUEST_ROUTE_PROVIDER } from './payment-requests/payment-request/providers/payment-request-route.provider';
import { MANAGE_PAYMENT_REQUESTS_PAYMENT_REQUEST_ROUTE_PROVIDER } from './payment-requests/manage-payment-request/providers/manage-payment-request-route.provider';
import { CLASS_SCHEDULES_CLASS_SCHEDULE_ROUTE_PROVIDER } from './class-schedules/class-schedule/providers/class-schedule-route.provider';
import { CLOVER_PAYMENTS_CLOVER_PAYMENT_ROUTE_PROVIDER } from './clover-payments/clover-payment/providers/clover-payment-route.provider';
import { INTERAC_PAYMENTS_INTERAC_PAYMENT_ROUTE_PROVIDER } from './interac-payments/interac-payment/providers/interac-payment-route.provider';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from './services/http-interceptor';
import { EVENT_TICKETS_EVENT_TICKET_ROUTE_PROVIDER } from './event-tickets/event-ticket/providers/event-ticket-route.provider';
import { DynamicDialogModule, DialogService } from 'primeng/dynamicdialog';
import { SENT_EMAIL_LOGS_SENT_EMAIL_LOG_ROUTE_PROVIDER } from './sent-email-logs/sent-email-log/providers/sent-email-log-route.provider';
import { HttpErrorInterceptor } from './shared/http-error.interceptor';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ONLINE_PAYMENTS_ROUTE_PROVIDER } from './online-payments/online-payments-route.provider';

@NgModule({
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    EventListComponent,
    MembershipComponent,
    ContentPageComponent,
    MemberServicesComponent,
    MembershipFormComponent,
    SettingComponent,
    DonationComponent,
    EmailTrialRunComponent,
    EventTicketAdminComponent,
    GoogleSettingsComponent,
    PaymentSettingsComponent,
    EventSalesComponent,
    ReceiptsComponent,
    ReceiptComponent,
  ],
  imports: [
    MenuModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
      skipGetAppConfiguration: false,
    }),
    AbpOAuthModule.forRoot(),
    ThemeSharedModule.forRoot({
      httpErrorConfig: {
        errorScreen: {
          component: HttpErrorComponent,
          forWhichErrors: [401, 403, 404, 500],
          hideCloseIcon: true,
        },
      },
    }),
    AccountAdminConfigModule.forRoot(),
    AccountPublicConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    LanguageManagementConfigModule.forRoot(),
    SaasConfigModule.forRoot(),
    AuditLoggingConfigModule.forRoot(),
    OpeniddictproConfigModule.forRoot(),
    TextTemplateManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ThemeLeptonXModule.forRoot(),
    SideMenuLayoutModule.forRoot(),
    CommercialUiConfigModule.forRoot(),
    FeatureManagementModule.forRoot(),
    GdprConfigModule.forRoot(),
    GdprConfigModule.forRoot({
      privacyPolicyUrl: 'page/privacy-policy',
      cookiePolicyUrl: 'gdpr-cookie-consent/cookie',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    CardModule,
    TabViewModule,
    InputTextModule,
    InputTextareaModule,
    DropdownModule,
    ConfirmDialogModule,
    DialogModule,
    SharedModule,
    StepsModule,
    StripeModule.forRoot(''),
    CalendarModule,
    EventTicketModule,
    EventDetailsModule,
    GooglePlacesModule,
    RxReactiveFormsModule,
    RadioButtonModule,

    ProgressSpinnerModule,
    SlotBookingModule,
    DynamicDialogModule,
    ToastModule,
  ],
  providers: [
    ManidrSettingService,
    {
      provide: APP_INITIALIZER,
      useFactory: SetupApp,

      deps: [ManidrSettingService],
      multi: true,
    },
    APP_ROUTE_PROVIDER,
    VOLUNTEERS_VOLUNTEER_ROUTE_PROVIDER,
    SCHEDULED_EVENTS_SCHEDULED_EVENT_ROUTE_PROVIDER,
    SERVICE_CATEGORIES_SERVICE_CATEGORY_ROUTE_PROVIDER,
    BOOKINGS_BOOKING_ROUTE_PROVIDER,
    POOJA_EVENTS_POOJA_EVENT_ROUTE_PROVIDER,
    PAYMENT_TYPES_PAYMENT_TYPE_ROUTE_PROVIDER,
    PAYMENTS_PAYMENT_ROUTE_PROVIDER,
    ARTICLES_ARTICLE_ROUTE_PROVIDER,
    SNIPPETS_SNIPPET_ROUTE_PROVIDER,
    EMAIL_TEMPLATES_EMAIL_TEMPLATE_ROUTE_PROVIDER,
    MANAGE_SNIPPETS_SNIPPET_ROUTE_PROVIDER,
    CAMPAIGNS_CAMPAIGN_ROUTE_PROVIDER,
    DONATIONS_DONATION_ROUTE_PROVIDER,
    ANNOUNCEMENTS_ANNOUNCEMENT_ROUTE_PROVIDER,
    BANNERS_BANNER_ROUTE_PROVIDER,
    RESOURCE_LINKS_RESOURCE_LINK_ROUTE_PROVIDER,
    EVENT_TICKET_TYPES_EVENT_TICKET_TYPE_ROUTE_PROVIDER,
    TICKET_SALES_TICKET_SALE_ROUTE_PROVIDER,
    EVENT_TICKET_ROUTE_PROVIDER,
    MANAGE_PAYMENTS_PAYMENT_ROUTE_PROVIDER,
    SUBSCRIPTIONS_SUBSCRIPTION_ROUTE_PROVIDER,
    UNSUBSCRIBE_ROUTE_PROVIDER,
    PROGRAMS_PROGRAM_ROUTE_PROVIDER,
    STUDENTS_STUDENT_ROUTE_PROVIDER,
    PROGRAM_LIST_ROUTE_PROVIDER,
    ATTENDANCES_ATTENDANCE_ROUTE_PROVIDER,
    MANAGE_STUDENTS_STUDENT_ROUTE_PROVIDER,
    DISCOUNT_CODES_DISCOUNT_CODE_ROUTE_PROVIDER,
    REPORTS_ROUTE_PROVIDER,
    EVENT_SLOTS_EVENT_SLOT_ROUTE_PROVIDER,
    SLOT_BOOKINGS_SLOT_BOOKING_ROUTE_PROVIDER,
    PAYMENT_REQUESTS_PAYMENT_REQUEST_ROUTE_PROVIDER,
    MANAGE_PAYMENT_REQUESTS_PAYMENT_REQUEST_ROUTE_PROVIDER,
    CLASS_SCHEDULES_CLASS_SCHEDULE_ROUTE_PROVIDER,
    CLOVER_PAYMENTS_CLOVER_PAYMENT_ROUTE_PROVIDER,
    INTERAC_PAYMENTS_INTERAC_PAYMENT_ROUTE_PROVIDER,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    EVENT_TICKETS_EVENT_TICKET_ROUTE_PROVIDER,
    DialogService,
    SENT_EMAIL_LOGS_SENT_EMAIL_LOG_ROUTE_PROVIDER,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    ONLINE_PAYMENTS_ROUTE_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

//create a function outside in the class module
export function SetupApp(setup: ManidrSettingService) {
  return () => setup.initliaze();
}
