<div class="card styleCard">
    <div class="card-header">
      <h2 class="card-title d-inline-block styleCardTitle">
        {{ 'AbpAccount::Register' | abpLocalization }}
      </h2>
    </div>
    <div class="card-body">
<form *ngIf="isSelfRegistrationEnabled" [formGroup]="form" (ngSubmit)="onSubmit()" validateOnSubmit>
    <!-- <div class="mb-3">
      <label for="input-user-name" class="form-label">{{ 'AbpAccount::UserName' | abpLocalization }}</label
      ><span> * </span>
      <input
        autofocus
        type="text"
        id="input-user-name"
        class="form-control"
        formControlName="username"
      />
    </div> -->
    <div class="row mb-3">
      <div class="col-12 col-md-4 "><label for="input-name" class="form-label">{{ '::FirstName' | abpLocalization }}</label
        ><span> * </span></div>
      <div class="col-12 col-md-8 "><input autofocustype="text" id="input-name" class="form-control" formControlName="name" /></div>
      </div>  <div class="row mb-3">
      <div class="col-12 col-md-4 "> <label for="input-surname" class="form-label">{{ '::LastName' | abpLocalization }}</label
        ><span> * </span></div>
      <div class="col-12 col-md-8"><input type="text" id="input-surname" class="form-control" formControlName="surname" /></div>
  
    </div>
    <div class="row mb-3">
      <div class="col-12 col-md-4 "><label for="input-email-address" class="form-label">{{ 'AbpAccount::EmailAddress' | abpLocalization }}</label
        ><span> * </span></div>
      <div class="col-12 col-md-8 "><input type="email" id="input-email-address" class="form-control" formControlName="email" /></div>
    </div>  <div class="row mb-3">
      <div class="col-12 col-md-4 "><label for="input-password" class="form-label">{{ 'AbpAccount::Password' | abpLocalization }}</label
        ><span> * </span></div>
      <div class="col-12 col-md-8 "><input type="password" id="input-password" class="form-control" formControlName="password" /></div>
  
    </div>
    <div class="row mb-3">
      <div class="col-12 col-md-4 "><label for="input-phoneNumber" class="form-label">{{ '::Phone' | abpLocalization }}</label
        ><span> * </span></div>
      <div class="col-12 col-md-8 "><input type="phone" id="input-phoneNumber" class="form-control" formControlName="phoneNumber" /></div>
    </div>  
    <div class="row mb-3">
      <div class="col-12 col-md-4 ">
        <label for="input-address" class="form-label">{{ '::Address' | abpLocalization }}</label
        ><span> * </span></div>
      <div class="col-12 col-md-8 ">
        <input class="input"
          type="text"
          class="form-control no-margin-bottom"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="autocompleteInput"
          #addresstext 
          
          >
        <!-- <app-google-places class="custom-control"
        id="input-address"
                (setAddress)="getAddress($event)" adressType="geocode"></app-google-places> -->
                <!-- <span *ngIf="form.invalid && (form.controls.AddressLine1?.errors?.required || EventForm.controls.AddressLine3?.errors?.required || EventForm.controls.PostalCode?.errors?.required)" class="text-danger">
                  Address is required.
              </span> -->
      </div>
  
    </div>
    <div #recaptcha></div>
  
    <abp-button
      class="d-grid"
      buttonClass="mt-2 mb-3 btn btn-primary"
      [loading]="inProgress"
      buttonType="submit"
    >
      {{ 'AbpAccount::Register' | abpLocalization }}
    </abp-button>
  </form>
  
  {{ 'AbpAccount::AlreadyRegistered' | abpLocalization }}
  <a routerLink="/account/login">{{ 'AbpAccount::Login' | abpLocalization }}</a>
</div>
</div>