import type { GetTicketSalesInput, TicketSaleCreateDto, TicketSaleDto, TicketSaleExcelDownloadDto, TicketSaleUpdateDto, TicketSaleWithNavigationPropertiesDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { DownloadTokenResultDto, LookupDto, LookupRequestDto } from '../shared/models';

@Injectable({
  providedIn: 'root',
})
export class TicketSaleService {
  apiName = 'Default';
  

  create = (input: TicketSaleCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TicketSaleDto>({
      method: 'POST',
      url: '/api/app/ticket-sales',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  delete = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/ticket-sales/${id}`,
    },
    { apiName: this.apiName,...config });
  

  get = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TicketSaleDto>({
      method: 'GET',
      url: `/api/app/ticket-sales/${id}`,
    },
    { apiName: this.apiName,...config });
  

  getDownloadToken = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, DownloadTokenResultDto>({
      method: 'GET',
      url: '/api/app/ticket-sales/download-token',
    },
    { apiName: this.apiName,...config });
  

  getEventTicketTypeLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/app/ticket-sales/event-ticket-type-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getIdentityUserLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/app/ticket-sales/identity-user-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getList = (input: GetTicketSalesInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<TicketSaleWithNavigationPropertiesDto>>({
      method: 'GET',
      url: '/api/app/ticket-sales',
      params: { filterText: input.filterText, originalPriceMin: input.originalPriceMin, originalPriceMax: input.originalPriceMax, soldPriceMin: input.soldPriceMin, soldPriceMax: input.soldPriceMax, discountCode: input.discountCode, paymentType: input.paymentType, paymentConfirmationCode: input.paymentConfirmationCode, quantitySoldMin: input.quantitySoldMin, quantitySoldMax: input.quantitySoldMax, location: input.location, ticketUsed: input.ticketUsed, ticketNumber: input.ticketNumber, eventTicketTypeId: input.eventTicketTypeId, buyerId: input.buyerId, paymentId: input.paymentId, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListAsExcelFile = (input: TicketSaleExcelDownloadDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      responseType: 'blob',
      url: '/api/app/ticket-sales/as-excel-file',
      params: { downloadToken: input.downloadToken, filterText: input.filterText, originalPriceMin: input.originalPriceMin, originalPriceMax: input.originalPriceMax, soldPriceMin: input.soldPriceMin, soldPriceMax: input.soldPriceMax, discountCode: input.discountCode, paymentType: input.paymentType, paymentConfirmationCode: input.paymentConfirmationCode, quantitySoldMin: input.quantitySoldMin, quantitySoldMax: input.quantitySoldMax, location: input.location, ticketUsed: input.ticketUsed, ticketNumber: input.ticketNumber, eventTicketTypeId: input.eventTicketTypeId, buyerId: input.buyerId, paymentId: input.paymentId },
    },
    { apiName: this.apiName,...config });
  

  getPaymentLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/app/ticket-sales/payment-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getWithNavigationProperties = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TicketSaleWithNavigationPropertiesDto>({
      method: 'GET',
      url: `/api/app/ticket-sales/with-navigation-properties/${id}`,
    },
    { apiName: this.apiName,...config });
  

  update = (id: string, input: TicketSaleUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TicketSaleDto>({
      method: 'PUT',
      url: `/api/app/ticket-sales/${id}`,
      body: input,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
