import { Environment } from '@abp/ng.core';

const baseUrl = 'https://vaishnodevi.ca';

const oAuthConfig = {
  issuer: 'https://api.vaishnodevi.ca/',
  redirectUri: baseUrl,
  clientId: 'Mandir_Public_App',
  responseType: 'token',
  scope: 'offline_access Mandir',
  requireHttps: true,
};

export const environment = {
  production: true,
  websiteMode:'PROD',
  primaryColor:'#cf2129',
  application: {
    baseUrl,
    name: 'Mandir',
  },
  oAuthConfig,
  apis: {
    default: {
      url: 'https://api.vaishnodevi.ca',
      rootNamespace: 'Mandir',
    },
    AbpAccountPublic: {
      url: oAuthConfig.issuer,
      rootNamespace: 'AbpAccountPublic',
    },
  },
  stripeKey: 'pk_live_51JtaS3FrOhkM1MXVvN3PkuCYPdXtFy4fMZm0s8844sPRfW79yyOKwa6fJ9i5J2hxG67eexw5wxukjBasoRdJEfEL00TBVITjWH'
  // remoteEnv: {
  //   url: '/getEnvConfig',
  //   mergeStrategy: 'deepmerge'
  // }
} as Environment;
