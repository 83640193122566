import { LocalizationService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { PoojaEventWithNavigationPropertiesDto } from '@proxy/pooja-events';
import { MultiLanguagePoojaEventDto, PublicService } from '@proxy/public';
import { DialogService } from 'primeng/dynamicdialog';
import { PoojaDetailsComponent } from '../shared/pooja-details/pooja-details.component';
import { SessionStateService } from '@abp/ng.core';

@Component({
  selector: 'app-member-services',
  templateUrl: './member-services.component.html',
  styleUrls: ['./member-services.component.scss'],
  providers: [DialogService],

})
export class MemberServicesComponent implements OnInit{

  services: PoojaEventWithNavigationPropertiesDto[]=[];
  multiLanguageServices: MultiLanguagePoojaEventDto[]=[];
  currentLanguage: string = 'en-GB';

  constructor(
    private publicService: PublicService,
    private dialogService: DialogService,
    private localizationService: LocalizationService,
    private sessionStateService: SessionStateService
  ){
    // Get current language from ABP
    this.currentLanguage = this.sessionStateService.getLanguage() || 'en-GB';
    
    // For consistency, get the base language code for translations
    if (this.currentLanguage.includes('-')) {
      console.log('Original language from ABP:', this.currentLanguage);
      // Save the full culture for ABP but use the base code for translations
      this.currentLanguage = this.currentLanguage.split('-')[0];
      console.log('Using base language code for translations:', this.currentLanguage);
    }
  }

  ngOnInit(): void {
    // Use the new service with translations
    this.publicService.getServicesWithTranslationsByCountAndLanguages(1000, ['en', 'hi','gu','pa']).subscribe(data => {
      this.multiLanguageServices = data;
      
      // Set services based on current language
      this.updateServicesForCurrentLanguage();
    });
  }

  // Helper method to get the appropriate service based on current language
  updateServicesForCurrentLanguage() {
    if (!this.multiLanguageServices || this.multiLanguageServices.length === 0) {
      return;
    }

    // If language is English, use original content
    if (this.currentLanguage.startsWith('en')) {
      this.services = this.multiLanguageServices.map(ms => ms.original);
    } 
    // For non-English languages, use translated content if available
    else if (this.currentLanguage !== 'en' && this.multiLanguageServices.length > 0) {
      const langCode = this.currentLanguage.split('-')[0]; // Get base language code
      
      // Check if translations exist for the current language
      if (this.multiLanguageServices[0].translations && this.multiLanguageServices[0].translations[langCode]) {
        this.services = this.multiLanguageServices.map(ms => {
          const translatedService = ms.translations[langCode];
          // Create a new object that matches the PoojaEventWithNavigationPropertiesDto structure
          return {
            poojaEvent: {
              id: translatedService.poojaEvent.id,
              name: translatedService.poojaEvent.name,
              description: translatedService.poojaEvent.description,
              imageUrl: translatedService.poojaEvent.imageUrl,
              cost: translatedService.poojaEvent.cost,
              capacity: translatedService.poojaEvent.capacity,
              duration: translatedService.poojaEvent.duration,
              thumbnailUrl: translatedService.poojaEvent.thumbnailUrl,
              nonMemberCost: translatedService.poojaEvent.nonMemberCost,
              sequence: translatedService.poojaEvent.sequence,
              serviceCategoryId: translatedService.poojaEvent.serviceCategoryId,
              concurrencyStamp: ms.original.poojaEvent.concurrencyStamp,
              creationTime: ms.original.poojaEvent.creationTime,
              creatorId: ms.original.poojaEvent.creatorId,
              lastModificationTime: ms.original.poojaEvent.lastModificationTime,
              lastModifierId: ms.original.poojaEvent.lastModifierId,
              isDeleted: ms.original.poojaEvent.isDeleted,
              deleterId: ms.original.poojaEvent.deleterId,
              deletionTime: ms.original.poojaEvent.deletionTime
            },
            serviceCategory: {
              id: translatedService.serviceCategory.id,
              name: translatedService.serviceCategory.name,
              description: translatedService.serviceCategory.description,
              imageUrl: translatedService.serviceCategory.imageUrl,
              concurrencyStamp: ms.original.serviceCategory.concurrencyStamp,
              creationTime: ms.original.serviceCategory.creationTime,
              creatorId: ms.original.serviceCategory.creatorId,
              lastModificationTime: ms.original.serviceCategory.lastModificationTime,
              lastModifierId: ms.original.serviceCategory.lastModifierId,
              isDeleted: ms.original.serviceCategory.isDeleted,
              deleterId: ms.original.serviceCategory.deleterId,
              deletionTime: ms.original.serviceCategory.deletionTime
            }
          };
        });
      } 
      // Fallback to original content for other languages
      else {
        this.services = this.multiLanguageServices.map(ms => ms.original);
      }
    }
  }

  showServiceDetails(service: PoojaEventWithNavigationPropertiesDto){
    const dialog = this.dialogService
    .open(PoojaDetailsComponent, {
      width: '80%',
      data: service
    })
    .onClose.subscribe(result => {
    });
  }
}
