import { mapEnumToOptions } from '@abp/ng.core';

export enum RegistrationStatus {
  Received = 0,
  Confirmed = 1,
  Cancelled = 2,
  NotEnrolled = 3,
  Waitlisted = 4,
}

export const registrationStatusOptions = mapEnumToOptions(RegistrationStatus);
