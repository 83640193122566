import type { Appointment } from './models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleCalendaryService {
  apiName = 'Default';
  

  createEventByAptAndCalenderId = (apt: Appointment, calenderId: string = "primary", config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/google-calendary/event/${calenderId}`,
      body: apt,
    },
    { apiName: this.apiName,...config });
  

  getEvents = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'GET',
      url: '/api/app/google-calendary/events',
    },
    { apiName: this.apiName,...config });
  

  getEventsByDateAndCalenderId = (date: string, calenderId: string = "primary", config?: Partial<Rest.Config>) =>
    this.restService.request<any, Appointment[]>({
      method: 'GET',
      url: `/api/app/google-calendary/events/${calenderId}`,
      params: { date },
    },
    { apiName: this.apiName,...config });
  

  saveFileByFileIdAndNewFileName = (fileId: string, newFileName: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: `/api/app/google-calendary/save-file/${fileId}`,
      params: { newFileName },
    },
    { apiName: this.apiName,...config });
  

  setupGoogleServices = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/google-calendary/setup-google-services',
    },
    { apiName: this.apiName,...config });
  

  syncPoojaBookings = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/google-calendary/sync-pooja-bookings',
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
