import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';@Injectable({
  providedIn: 'root',
})
export class PublicDownloadService {
  apiName = 'Default';
  getReceiptByHtml = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      url: '/api/app/public/GetReceipt',
      params: { id },
      responseType: 'blob' as 'json',

    },
    { apiName: this.apiName,...config });

    getAnnualReceiptByHtml = (token: string, year:number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, Blob>({
      method: 'GET',
      url: '/api/app/public/GetAnnualReceipt',
      params: { token, year },
      responseType: 'blob' as 'json',

    },
    { apiName: this.apiName,...config });
  

  constructor(private restService: RestService) {}
}
