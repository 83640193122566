import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const SERVICE_CATEGORIES_SERVICE_CATEGORY_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/service-categories',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:ServiceCategories',
        layout: eLayoutType.application,
        requiredPolicy: 'Mandir.ServiceCategories',
      },
    ]);
  };
}
