import { AuthService, ConfigStateService } from '@abp/ng.core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray, Validators } from '@angular/forms';
import { IdentityUserDto } from '@volo/abp.ng.identity/proxy';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MemberAddress } from '../shared/mandir.model';
import { HostInfoDto, UserInfoDto } from '@proxy/shared';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AddMemberVM, PaymentDto } from '@proxy/payments';
import { SettingsService } from '@proxy/settings';
import { AddMemberInfo, MembershipInfo } from '../shared/membership-application.model';
import { PublicService } from '@proxy/public';
import { PaymentTypeDto } from '@proxy/payment-types';
import { Observable, observable } from 'rxjs';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { uniqueEmail } from '../shared/unique-email-validator.function';
import { ToasterService } from '@abp/ng.theme.shared';
import { AcceptPaymentComponent } from '../shared/accept-payment/accept-payment.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-membership-form',
  templateUrl: './membership-form.component.html',
  styleUrls: ['./membership-form.component.scss'],
  providers: [ConfirmationService],

})

export class MembershipFormComponent implements OnInit {
  @ViewChild('payment') payment: AcceptPaymentComponent;

  //@ViewChild('stripe') stripe: StripeComponent;
  uiBusy:boolean=false;
  showGoogleAddress:boolean=true;
  showAddressDetails:boolean=false;
  items: MenuItem[] = [];
  //Sample test data it can be dynamic as well.
  ExistingMembers: any[] = [];
  FeedBack!: FormGroup;

  AddressInfo: HostInfoDto = {} as HostInfoDto;

  currentUser$ = this.configState.getDeep$('currentUser');
  currentUser: any = {};
  currentUserInfo: UserInfoDto;
  types: string[] = ['Credit/Debit Card'];
  payments: PaymentDto[] = [];
  currentYear: string;
  input: MembershipInfo = {} as MembershipInfo;
  allMembershipCategories:string[] = [];
  membershipCategory: PaymentTypeDto;
  activeStep: string = 'Personal';
  confirmationMessage: string = "";
  
  stepActiveIndex: number = 0;
  steps: string[] = ['Personal', 'Family', 'Payment', 'Confirmation'];
  isAdmin:boolean=false;

  membershipTypes: any[] = [
    { name: 'Voting Member', key: true, id: '1' },
    { name: 'Associate Member', key: false, id: '0' }
];
  constructor(private formBuilder: FormBuilder,
    public ref: DynamicDialogRef,
    private configState: ConfigStateService,
    public config: DynamicDialogConfig,
    private confirmService: ConfirmationService,
    private publicService: PublicService,
    private router: Router
  ) {
    if (this.config.data) {

      this.input = this.config.data;
      this.currentYear = this.input.currentYear.toString();
      this.currentUserInfo = this.input.hostMember;
      this.ExistingMembers = this.input.familyMembers || [];

      this.payments = this.input.existingPayments || [];

    }

    this.currentUser$.subscribe(result => {
      
      console.log(result);
      this.isAdmin = result.roles.indexOf("admin") > -1;
      if(this.isAdmin){
        this.types = ['Credit/Debit Card', 'Cheque', 'Cash'];

      }
    })

  }

  ngOnInit(): void {
    this.items = [
      {
        label: 'Personal',
      },
      {
        label: 'Family',
      },
      {
        label: 'Payment',
      },
      {
        label: 'Confirmation',
      }
    ];


    this.publicService.getPaymentTypesByPayeeId('00000000-0000-0000-0000-000000000000').subscribe(data => {
      this.allMembershipCategories = data.filter(m => m.name =="Membership Fee" || m.name.indexOf("Membership") > -1 || m.name == "NR Membership").map(c => c.id);
      var membershipCategories = data.filter(m => m.name == "Membership Fee" || m.name.indexOf("Membership") > -1);

      if (membershipCategories.length) {
        this.membershipCategory = membershipCategories[0];
      }
    })
    this.createContactForm(); // init form data
    this.initialData();
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

  }

  hasMadePayment(userId: string, year: string) {
    return (this.payments.filter(p => p.payeeId == userId 
      && p.accountingYear.toString() == year
      && this.allMembershipCategories.indexOf(p.paymentTypeId) > -1
      ).length > 0);
  }

  createContactForm() {
    this.showGoogleAddress = !this.currentUserInfo;
    this.showAddressDetails = (this.currentUserInfo && this.currentUserInfo.addressLine1 != null);
    this.FeedBack = this.formBuilder.group({
      Id: [this.currentUserInfo ? this.currentUserInfo.id : ''],
      Name: [this.currentUserInfo ? this.currentUserInfo.name : '', [Validators.required]],
      SurName: [this.currentUserInfo ? this.currentUserInfo.surname : '', [Validators.required]],
      PhoneNumber: [this.currentUserInfo ? this.currentUserInfo.phoneNumber : '', RxwebValidators.mask({mask:'(999)-999 9999' })],
      Email: [this.currentUserInfo ? this.currentUserInfo.email : '', {updateOn: 'blur', validators: [Validators.required, Validators.email]},],
      AddressLine1: [this.currentUserInfo ? this.currentUserInfo.addressLine1 : '', [Validators.required]],
      AddressLine2: [this.currentUserInfo ? this.currentUserInfo.addressLine2 : ''],
      AddressLine3: [this.currentUserInfo ? this.currentUserInfo.addressLine3 : '', [Validators.required]],
      PostalCode: [this.currentUserInfo ? this.currentUserInfo.postalCode : '', [Validators.required]],
      Paid: [this.currentUserInfo ? !this.hasMadePayment(this.currentUserInfo.id, this.currentYear) : false, [Validators.required]],
      PaymentType: [this.isAdmin ? 'Cheque':'Credit/Debit Card', [Validators.required]],

      //      PaymentType: ['Credit/Debit Card',[Validators.required]],
      PaymentTypeDetail: ['', []],
      Rows: this.formBuilder.array([]),
    });
    if(!this.currentUserInfo){
      this.subscribeEmailAvailable(this.FeedBack.get("Email"));
    }
  }

  subscribeEmailAvailable(control){
    control.valueChanges.subscribe(x => {
      this.publicService.emailAvailableByEmail(x).subscribe(result => {
        if(result){
          if (control.hasError('notAvailable')) {
            delete control.errors['notAvailable'];
            control.updateValueAndValidity();
          }
        }
        else{
          control.setErrors({notAvailable:true});
        }
      })
   })
  }

  moveStep(num: number) {

    if (num > -1) {
      Object.keys(this.FeedBack.controls).forEach(field => {
        const control = this.FeedBack.get(field);
        control.updateValueAndValidity();
      });
    }
    // if(this.stepActiveIndex == 1){
    //       let hostEmail = this.FeedBack.value.Email;
    //       let emails = this.formArr.controls.map(c => c.value.Email);
    //       emails.push(hostEmail);

    //       let unique = [...new Set(emails)];
    //       if(emails.length > unique.length){
    //         this.formArr.controls.forEach(c =>{
              
    //           c.Email.setErrors({duplicate:true})
    //         })
    //       }

    //   return;
    // }
    if (this.FeedBack.valid) {
      this.stepActiveIndex = this.stepActiveIndex + num;
      this.activeStep = this.steps[this.stepActiveIndex];
    }

  }

  //getter function ease up to get the form controls
  get formArr() {
    return this.FeedBack.get('Rows') as FormArray;
  }

  initialData() {

    this.ExistingMembers.forEach((u) => {
      if (u) {
        this.formArr.push(this.addRow({
          Id: u.id,
          Name: u.name,
          SurName: u.surname,
          PhoneNumber: u.phoneNumber,
          Email: u.email,
          Paid: !this.hasMadePayment(u.id, this.currentYear),
          ParentId: this.currentUserInfo ? this.currentUserInfo.id : '00000000-0000-0000-0000-000000000000'

        }));
      }
    });
  }

  getAddress($event){
    this.showGoogleAddress=false;

    let street_number =  $event.address_components.filter(a => a.types.indexOf('street_number') > -1);
    let street_name =  $event.address_components.filter(a => a.types.indexOf('route') > -1);
    let subpremise =  $event.address_components.filter(a => a.types.indexOf('subpremise') > -1);
    
    let city =  $event.address_components.filter(a => a.types.indexOf('locality') > -1);

    let province =  $event.address_components.filter(a => a.types.indexOf('administrative_area_level_1') > -1);

    let postal_code =  $event.address_components.filter(a => a.types.indexOf('postal_code') > -1);
    

    if(street_number.length){
      let addressLine1 = street_number[0].short_name;
            if(street_name.length){
        addressLine1 = addressLine1 + ' ' + street_name[0].short_name
      }

      if(subpremise.length){
        addressLine1 = addressLine1 + ' ' + subpremise[0].short_name
      }
      this.FeedBack.get("AddressLine1").patchValue(addressLine1);
      //this.AddressInfo.addressLine1 = street_number[0].short_name + street_name[0].short_name;
    }

    if(city.length){
      this.FeedBack.get("AddressLine2").patchValue(city[0].short_name);
    }
    if(province.length){
      this.FeedBack.get("AddressLine3").patchValue(province[0].short_name);
    }
    if(postal_code.length){
      this.FeedBack.get("PostalCode").patchValue(postal_code[0].short_name);
    }
    
  }

  addRow(obj) {
    let controls = this.formBuilder.group({
      Id: [obj.Id],
      Name: [obj.Name, Validators.required],
      SurName: [obj.SurName, Validators.required],
      PhoneNumber: [obj.PhoneNumber, RxwebValidators.mask({mask:'(999)-999 9999' })],
      Email: [obj.Email, {updateOn: 'blur', validators: [Validators.required, Validators.email]}],
      Paid: [!this.hasMadePayment(obj.Id, this.currentYear)],
      ParentId: [this.currentUserInfo ? this.currentUserInfo.id : '00000000-0000-0000-0000-000000000000']
    })
    this.subscribeEmailAvailable(controls.get("Email"));
    return controls;

  }

  addNewRow() {
    let obj1 = {
      Name: '', 
      SurName: '',
      PhoneNumber: '',
      Email: '',
      Paid: true,
      ParentId: this.currentUserInfo ? this.currentUserInfo.id : '00000000-0000-0000-0000-000000000000'
    };
    this.formArr.push(this.addRow(obj1));
  }

  deleteRow(index: number) {
    this.formArr.removeAt(index);
  }

  async onSubmit() {

    let message = '';
    //&& this.FeedBack.value.Rows.length
    let paidMemberCount = this.getPaidMemberCount();
    if (paidMemberCount === 0) {
      if(this.FeedBack.value.Paid)
      {
        message = 'Since you don\'t have any membership dues. Only the member details will be updated now';
      }
    else{
      message = 'Are you sure you don\'t want to take full voting membership of the mandir?';
    }
    }

    if (paidMemberCount > 0) {
      message = 'Your total due for ' + paidMemberCount + ' voting members is $' + (paidMemberCount * 51) + '';
    }


    if (this.FeedBack.valid) {

      this.submitMembershipForm(this.payment?.paymentIntent);

      // this.confirmService.confirm(
      //   {
      //     message: message + ", do you want to proceed?",
      //     accept: async () => {
      //       if (paidMemberCount > 0 && this.FeedBack.value.PaymentType == 'Credit/Debit Card') {


              
      //         if (this.stripe.form.invalid) {
      //           return;
      //         }

      //         const { paymentIntent } = await this.stripe.stripe.confirmPayment({
      //           elements: this.stripe.elements,

      //           confirmParams: {
      //             return_url: `${window.location.origin}/return.html`,
      //           },
      //           redirect: 'if_required'
      //         });

      //         console.log(paymentIntent);
      //         if (paymentIntent) {
      //           this.submitMembershipForm(paymentIntent);
      //         }
      //         else{
      //           this.toasterService.error("Payment Declined.")
      //         }
      //       }
      //       else {
      //         this.submitMembershipForm({});
      //       }
      //     }
      //   }
      // )
    }
  }

  submitMembershipForm(paymentToken: any) {
    if(!paymentToken)
      return;

    this.AddressInfo = {
      id: this.FeedBack.value.Id,
      name: this.FeedBack.value.Name,
      surName: this.FeedBack.value.SurName,
      phoneNumber: this.FeedBack.value.PhoneNumber,
      email: this.FeedBack.value.Email,
      addressLine1: this.FeedBack.value.AddressLine1,
      addressLine2: this.FeedBack.value.AddressLine2 ? this.FeedBack.value.AddressLine2 : '',
      addressLine3: this.FeedBack.value.AddressLine3,
      postalCode: this.FeedBack.value.PostalCode,
      paid: this.FeedBack.value.Paid && !this.hasMadePayment(this.FeedBack.value.Id, this.currentYear),
      isMember: true,
    } as HostInfoDto;

    var members = [...this.FeedBack.value.Rows];
    members.forEach(m => {
      //m.Id= (m.Id != null ? m.Id : '00000000-0000-0000-0000-000000000000');
      m.AddressLine1 = this.FeedBack.value.AddressLine1;
      m.AddressLine2 = this.FeedBack.value.AddressLine2;
      m.AddressLine3 = this.FeedBack.value.AddressLine3;
      m.PostalCode = this.FeedBack.value.PostalCode

    })

    this.uiBusy = true;
    let paymentType = paymentToken.paymentMode; //this.FeedBack.value.PaymentType
    let paymentDetail = paymentToken.paymentDetail.detail; //this.FeedBack.value.PaymentTypeDetail
    this.processMembershipFormResult(
      {
        Members: members,
        PaymentType: paymentType,
        PaymentTypeDetail: paymentDetail,
        HostMember: this.AddressInfo,
        PaymentToken: paymentToken
      } as AddMemberInfo
    ).subscribe(resp => {
      this.uiBusy = false;
      this.confirmationMessage = "Thank you!  Your membership is processed.  Please look for an email from the Mandir with your login details.";
      this.stepActiveIndex = 3;
      this.activeStep = this.steps[this.stepActiveIndex];
      this.router.navigate(['/membership/'+resp.payeeId]);

      //this.ref.close(resp);
    });

    //console.log('Your form data : ', this.FeedBack.value);
  }

  processMembershipFormResult(result): Observable<PaymentDto> {
    var paymentInfo = {};
    if (result.PaymentType === 'Credit/Debit Card') {
      paymentInfo = {
        confirmationCode: (result.PaymentToken ? result.PaymentToken.client_secret : ''),
        accountingYear: parseInt(this.currentYear),
        paymentTypeId: this.membershipCategory.id,
        payeeId: '00000000-0000-0000-0000-000000000000',
        amount: '51',
        transactionAmount: (result.Members.filter(m => m.Paid).length * 51).toString(),

        transactionId: "Online"
      }
    }
    else {
      paymentInfo = {
        confirmationCode: result.PaymentType + ',' + result.PaymentTypeDetail,
        accountingYear: parseInt(this.currentYear),
        paymentTypeId: this.membershipCategory.id,
        payeeId: '00000000-0000-0000-0000-000000000000',
        amount: '51',
        transactionAmount: (result.Members.filter(m => m.Paid).length * 51).toString(),

        transactionId: result.PaymentType
      }
    }

    let input = {
      paymentInfo: paymentInfo,
      hostMember: result.HostMember,
      members: result.Members
    } as AddMemberVM;
    return this.publicService.addMembersAndPaymentByModel(input);

  }

  getPaidMemberCount() {
    var totalPaidMemberCount = 0;
    var isHostAVotingMember = this.FeedBack.value.Paid;
    
    var hostHasPaid = this.currentUserInfo != null && this.hasMadePayment(this.currentUserInfo.id, this.currentYear);

    totalPaidMemberCount = totalPaidMemberCount+(isHostAVotingMember?  ((hostHasPaid) ? 0 : 1): 0);

    this.FeedBack.value.Rows.forEach(m => {
      totalPaidMemberCount = totalPaidMemberCount + (m.Paid && !this.hasMadePayment(m.Id, this.currentYear) ? 1 : 0);
    })
    return totalPaidMemberCount;
    // return this.FeedBack.value.Rows.filter(m => m.Paid).length + (isHostAVotingMember?  ((hostHasPaid) ? 0 : 1): 0);



  }

  checkBasicInfo(user) {
    return user.Name
  }

  sendRequest(data: any) {

    if (data.status == "succeeded") {
      this.submitMembershipForm(data)
    }
    
  }
}
