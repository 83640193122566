<header class="header d-print-none fixed-top">
    <div *ngIf="webSiteMode != 'PROD'" class="test-site-warning">
        {{ '::TestSiteWarning' | abpLocalization:'https://www.vaishnodevi.ca' }}
    </div>
  <nav class="navbar navbar-expand-lg">
    <div class="container position-relative">
      <!-- Logo for mobile -->
      <a class="navbar-brand d-lg-none" [routerLink]="['/']">
        <img [src]="logoUrl" alt="{{ '::Logo' | abpLocalization }}" class="logo">
      </a>

      <!-- Mobile Toggle Button -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mainNavbar" 
        aria-controls="mainNavbar" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Center Logo (visible on desktop, hanging over the navbar) -->
      <a class="navbar-brand center-logo d-none d-lg-block" [routerLink]="['/']">
        <img [src]="logoUrl" alt="{{ '::Logo' | abpLocalization }}" class="logo">
      </a>

      <!-- Main Navigation -->
      <div class="collapse navbar-collapse" id="mainNavbar">
        <!-- Left Navigation Items -->
        <ul class="navbar-nav me-auto">
          <li class="nav-item" *ngFor="let parentLink of getParentResourceLinks()">
            <a *ngIf="!hasChildren(parentLink.id)"
               class="nav-link" 
               [routerLink]="[parentLink.url]"
               target="{{parentLink.target}}">
              {{parentLink.name}}
            </a>
            <div class="dropdown nav-item" *ngIf="hasChildren(parentLink.id)">
              <a class="nav-link dropdown-toggle" 
                 role="button" 
                 data-bs-toggle="dropdown" 
                 aria-expanded="false">
                {{parentLink.name}}
              </a>
              <ul class="dropdown-menu">
                <li *ngFor="let link of getChildResourceLinks(parentLink.id)">
                  <a class="dropdown-item" href="{{link.url}}" target="{{link.target}}">{{link.name}}</a>
                </li>
              </ul>
            </div>
          </li>
          
          <!-- Admin Menu -->
          <li class="nav-item" *abpPermission="'Mandir.Reports.MembersList'">
            <div class="dropdown">
              <a class="nav-link dropdown-toggle" 
                 role="button" 
                 data-bs-toggle="dropdown" 
                 aria-expanded="false">
                {{ '::Header:Admin' | abpLocalization }}
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="/manage-services">{{ '::Header:ManageServices' | abpLocalization }}</a></li>
                <li><a class="dropdown-item" href="/manage-resource-links">{{ '::Header:ManageResourceLinks' | abpLocalization }}</a></li>
                <li><a class="dropdown-item" href="/manage-articles">{{ '::Header:ManagePages' | abpLocalization }}</a></li>
                <li><a class="dropdown-item" href="/manage-programs">{{ '::Header:ManageClasses' | abpLocalization }}</a></li>
                <li *abpPermission="'Mandir.App.AllowUserMerge'"><a class="dropdown-item" [routerLink]="['/duplicate-users']">{{ '::Header:DuplicateUsers' | abpLocalization }}</a></li>
                <li *abpPermission="'Mandir.App.SendMessage'"><a class="dropdown-item" [routerLink]="['/messaging']">{{ '::Header:Messaging' | abpLocalization }}</a></li>
                <li *abpPermission="'Mandir.Reports.MembersList'"><a class="dropdown-item" [routerLink]="['/reports/members-list']">{{ '::Header:MembershipReport' | abpLocalization }}</a></li>
                <li *abpPermission="'Mandir.EmailTemplates.Create'"><a class="dropdown-item" [routerLink]="['/manage-email-templates']">{{ '::Header:Newsletters' | abpLocalization }}</a></li>
                <li *abpPermission="'Mandir.EmailTemplates.Create'"><a class="dropdown-item" [routerLink]="['/manage-email-templates/templates']">{{ '::Header:AutomatedEmails' | abpLocalization }}</a></li>
                <li *abpPermission="'Mandir.EmailTemplates.Create'"><a class="dropdown-item" [routerLink]="['/manage-snippets']">{{ '::Header:Snippets' | abpLocalization }}</a></li>
                <li *abpPermission="'Mandir.FileManager'"><a class="dropdown-item" [routerLink]="['/file-manager']">{{ '::Header:FileManager' | abpLocalization }}</a></li>
              </ul>
            </div>
          </li>
        </ul>
        
        <!-- Logo placeholder for desktop -->
        <div class="logo-placeholder d-none d-lg-block"></div>
        
        <!-- Right Navigation Items -->
        <ul class="navbar-nav ms-auto">
             <!-- Donate Button -->
          <li class="nav-item">
            <a class="btn btn-primary donate-btn position-relative d-inline-flex align-items-center gap-2 px-4 py-2" [routerLink]="['/donate-now']">
              <i class="fas fa-heart animate-heartbeat"></i>
              <span>{{ '::Header:DonateNow' | abpLocalization }}</span>
              <!-- Pulsing border effect -->
              <span class="position-absolute w-100 h-100 top-0 start-0 rounded border border-primary animate-ping"></span>
            </a>
          </li>
          <!-- Language Selector -->
          <li class="nav-item" *ngIf="isMultilingualEnabled">
            <div class="dropdown">
              <a class="nav-link dropdown-toggle" 
                 role="button" 
                 data-bs-toggle="dropdown" 
                 aria-expanded="false">
                {{ getLanguageDisplayName() }}
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <li><a class="dropdown-item" href="javascript:void(0)" (click)="switchLanguage('en-GB')">English</a></li>
                <li><a class="dropdown-item" href="javascript:void(0)" (click)="switchLanguage('hi')">हिंदी</a></li>
                <li><a class="dropdown-item" href="javascript:void(0)" (click)="switchLanguage('gu')">ગુજરાતી</a></li>
                <li><a class="dropdown-item" href="javascript:void(0)" (click)="switchLanguage('pa')">ਪੰਜਾਬੀ</a></li>
              </ul>
            </div>
          </li>
          
          <!-- About & Contact Links -->
          <!-- <li class="nav-item">
            <a class="nav-link" [routerLink]="['/page','about-us']">{{ '::Header:About' | abpLocalization }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/page','contact']">{{ '::Header:Contact' | abpLocalization }}</a>
          </li> -->
          
         
          
          <!-- Login/User Profile -->
          <li class="nav-item" *ngIf="!hasLoggedIn">
            <a class="btn btn-outline-primary login-btn" (click)="login()">
              {{ '::Header:Login' | abpLocalization }}
            </a>
          </li>
          
          <!-- User Dropdown when logged in -->
          <li class="nav-item user-dropdown" *ngIf="hasLoggedIn">
            <div class="dropdown">
              <a class="nav-link dropdown-toggle" 
                 role="button" 
                 data-bs-toggle="dropdown" 
                 aria-expanded="false"
                 *ngIf="currentUser$ | async as user">
                <span class="user-name">{{user.name}} {{user.surName}}</span>
              </a>
              <ul class="dropdown-menu dropdown-menu-end">
                <!-- Member Management -->
                <li *abpPermission="'Mandir.App.AllowOfflinePayments'">
                  <a class="dropdown-item" [routerLink]="['/manage-members']">
                    {{ '::Header:ManageMembers' | abpLocalization }}
                  </a>
                </li>
                <li *abpPermission="'Mandir.StripePayments'">
                  <a class="dropdown-item" [routerLink]="['/online-payments']">
                    {{ '::Header:OnlinePayments' | abpLocalization }}
                  </a>
                </li>
                <!-- Booking Management -->
                <li *abpPermission="'Mandir.App.AllowManageBookings'">
                  <a class="dropdown-item" [routerLink]="['/manage-bookings/Received']" routerLinkActive="active">
                    {{ '::Header:BookingRequests' | abpLocalization }}
                  </a>
                </li>
                <li *abpPermission="'Mandir.App.AllowManageBookings'">
                  <a class="dropdown-item" [routerLink]="['/manage-bookings/Confirmed']" routerLinkActive="active">
                    {{ '::Header:ConfirmedBookings' | abpLocalization }}
                  </a>
                </li>
                
                <!-- Payment Management -->
                <li *abpPermission="'Mandir.App.AllowOfflinePayments'">
                  <a class="dropdown-item" [routerLink]="['/manage-payments']">
                    {{ '::Header:VerifyPayments' | abpLocalization }}
                  </a>
                </li>
                
                <!-- Reports -->
                <li *abpPermission="'Mandir.Reports.PaymentReport'">
                  <a class="dropdown-item" [routerLink]="['/reports/payments']">
                    {{ '::Header:PaymentsReport' | abpLocalization }}
                  </a>
                </li>
                <li *abpPermission="'Mandir.Reports.PaymentReport'">
                  <a class="dropdown-item" [routerLink]="['/reports/payments/General Donation']">
                    {{ '::Header:DailyDonation' | abpLocalization }}
                  </a>
                </li>
                <!-- <li *abpPermission="'Mandir.Reports.PaymentReport'">
                  <a class="dropdown-item" [routerLink]="['/reports/interac']">
                    {{ '::Header:InteracReport' | abpLocalization }}
                  </a>
                </li> -->
                
                
                <!-- Event Sales -->
                <li *abpPermission="'Mandir.App.AllowEventSales'">
                  <a class="dropdown-item" [routerLink]="['/event-sales']">
                    {{ '::Header:EventSales' | abpLocalization }}
                  </a>
                </li>
                
                <!-- Email Logs -->
                <li *abpPermission="'Mandir.SentEmailLogs'">
                  <a class="dropdown-item" [routerLink]="['/sent-email-logs']">
                    {{ '::Header:SentEmailLogs' | abpLocalization }}
                  </a>
                </li>
                
                <li><hr class="dropdown-divider"></li>
                
                <!-- User-specific links -->
                <li>
                  <a class="dropdown-item" [routerLink]="['/receipts']">
                    {{ '::Header:MyReceipts' | abpLocalization }}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" [routerLink]="['/ticket']">
                    {{ '::Header:MyEventTickets' | abpLocalization }}
                  </a>
                </li>
                <li>
                  <a class="dropdown-item" (click)="logout()">
                    {{ '::Header:Logout' | abpLocalization }}
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>