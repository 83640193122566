import { LocalizationService, PermissionService } from '@abp/ng.core';
import { ConfirmationService } from '@abp/ng.theme.shared';
import { Component } from '@angular/core';
import { SettingsService } from '@proxy/settings';
import { SettingValue } from '@proxy/volo/abp/settings';
import { MessageService } from 'primeng/api';
import { MandirSettingValue } from '../setting/setting.model';

@Component({
  selector: 'app-google-settings',
  templateUrl: './google-settings.component.html',
  styleUrls: ['./google-settings.component.scss'],
  providers: [MessageService]
})
export class GoogleSettingsComponent {
  message: string;

  original: SettingValue[] = [];
  settings: string[] = [
    'MandirSecure.Google.CalendarId',
    'MandirSecure.Google.PoojaBookingCalendarId',
    'MandirSecure.Google.AuthJson'
  ];
  
  CalendarId:SettingValue = { name: 'MandirSecure.Google.CalendarId' } as any;
  PoojaBookingCalendarId:SettingValue = { name: 'MandirSecure.Google.PoojaBookingCalendarId' } as any;
  AuthJson:SettingValue = { name: 'Mandir.Google.AuthJson' } as any;
  
  constructor(
    public service: SettingsService,
    private confirmation: ConfirmationService,
    private localizationService: LocalizationService,
    private messageService: MessageService,
    private permissionService: PermissionService){
      this.service.getSettingsByNames(this.settings.join(',')).subscribe(result => {
        this.original = result;
        this.CalendarId = this.getSetting(result, 'MandirSecure.Google.CalendarId');
        this.PoojaBookingCalendarId = this.getSetting(result, 'MandirSecure.Google.PoojaBookingCalendarId');
        this.AuthJson = this.getSetting(result, 'MandirSecure.Google.AuthJson');
        
      });
  }
  getSetting(items: SettingValue[], name: string) {
    let values = items.filter(item => {
      return item.name == name;
    });
    if (!values.length) {
      return { name: name } as any;
    }
    return values[0];
  }

  getArraySetting(items: SettingValue[], name: string) {
    let values = items.filter(item => {
      return item.name == name;
    });
    if (!values.length) {
      return { name: name } as MandirSettingValue<string[]>;
    }

    return { name: name, value: values[0].value.split(',') } as MandirSettingValue<string[]>;
  }

  getDateSetting(items: SettingValue[], name: string) {
    let values = items.filter(item => {
      return item.name == name;
    });
    if (!values.length) {
      return { name: name } as MandirSettingValue<Date>;
    }

    return { name: name, value: new Date(values[0].value) } as MandirSettingValue<Date>;
  }

  saveSettings() {
    let updatedSettings: SettingValue[] = [];
    let settings = [
      this.CalendarId,
      this.PoojaBookingCalendarId,
      this.AuthJson
    ];
    settings.forEach(setting => {
      if (Array.isArray(setting.value)) {
        updatedSettings.push({
          name: setting.name,
          value: setting.value.join(','),
        } as any);
      } else {
        updatedSettings.push({ name: setting.name, value: setting.value } as any);
      }
    });
    this.service.setSettingsByInput(updatedSettings).subscribe(result => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: this.localizationService.instant('::SettingsUpdatedSuccessfully'),
      });
    });
  }

}
