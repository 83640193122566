import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@abp/ng.core';
import { QRCodeModule } from 'angularx-qrcode';
import { SlotBookingComponent } from './slot-booking/slot-booking.component';
import { SlotConfirmationComponent } from './slot-confirmation/slot-confirmation.component';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';



@NgModule({
  declarations: [SlotBookingComponent, SlotConfirmationComponent],
  imports: [
    CommonModule,
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    QRCodeModule,

  ]
})
export class SlotBookingModule { }
