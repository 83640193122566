import { AuthService, ConfigStateService } from '@abp/ng.core';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { BookingCreateDto, BookingDto, BookingPaymentCreateDto } from '@proxy/bookings';
import { PoojaEventWithNavigationPropertiesDto } from '@proxy/pooja-events';
import { PublicService } from '@proxy/public';
import { SettingsService } from '@proxy/settings';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as moment from 'moment';
import { BookingStatus, bookingStatusOptions } from '@proxy/enums';
import { UserInfoDto } from '@proxy/shared';

@Component({
  selector: 'app-pooja-details',
  templateUrl: './pooja-details.component.html',
  styleUrls: ['./pooja-details.component.scss']
})
export class PoojaDetailsComponent implements OnInit {
  service: PoojaEventWithNavigationPropertiesDto = {} as PoojaEventWithNavigationPropertiesDto;
  currentUser = this.configState.getOne('currentUser');
  currentUserInfo:UserInfoDto={} as UserInfoDto;
  poojaDate: string;
  fields:any={};
  myBookings: BookingDto[] = [];

  @ViewChild('payElement') payElement;

  @Input() amount: number;
  @Input() label: string;
  elements: any;
  paymentRequest: any;
  prButton: any

  paymentHandler: any = null;
  currentYear:number;
  disabledDates: Date[]=[];

  constructor(
    private authService: AuthService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private publicService: PublicService,
    private configState: ConfigStateService,
    private datePipe: DatePipe,
    private toastService: ToasterService,
    private settingService: SettingsService,
    private confirmationService: ConfirmationService
  ) {

  }

  
  
  
  ngOnInit(): void {
    //this.loadScript('https://js.stripe.com/v3/');
    if (this.config.data) {
      this.service = this.config.data;
      this.currentUserInfo = this.config.data.hostMember;
    }
    this.getMyBookings();
    this.fetchDateAvailability({
      month: moment().format("MM"),
      year: moment().format("YYYY")
    });
// this.validateUserLogin();
    this.settingService.getSettingsByNames("Mandir.CurrentAccountYear").subscribe(
      setting => {
        if(setting.length){
          this.currentYear = parseInt(setting[0].value);

        }
      })
      this.fields={
        email:'',
        address:'',
        name:'',
        phoneNumber:'',
  
      }
      
      if(this.currentUser && this.currentUser.id){
      this.publicService.getUserInfoById(this.currentUser.id).subscribe(data => {
        this.currentUserInfo=data;
        this.fields={
          email:this.currentUser.email,
          address:(this.currentUserInfo.addressLine1 && this.currentUserInfo.addressLine1!=null?this.currentUserInfo.addressLine1+' ':'') + 
            (this.currentUserInfo.addressLine2 && this.currentUserInfo.addressLine2!=null?this.currentUserInfo.addressLine2+' ':'')+
            (this.currentUserInfo.addressLine3 && this.currentUserInfo.addressLine3!=null?this.currentUserInfo.addressLine3+' ':''),
          name:this.currentUser.name + ' '+this.currentUser.surName,
          phoneNumber:this.currentUser.phoneNumber,
    
        }
      });
      }
  }

  getStatus(status){
    
      if(status == null)
        return '';
        
    if(status == 0){
      return 'Pending Date Availability By Mandir';
    }
    let item = bookingStatusOptions[status];
    if(!item)
      return '';

    return item.key;
  }
  getPaymentConfirmation(input, date){

    return '';// input;
    if(!input)
      return "Pending";
    var confirmation = JSON.parse(input);
    if(confirmation.id)
    return "Payment Received" + (date ? ' on '+this.datePipe.transform(date, 'dd MMM, YYYY') : '');
    else
    return "Payment Failed";

  }

  getMyBookings() {
    if(this.authService.isAuthenticated){
      this.publicService.getMyBookings().subscribe(data => {
      
        this.myBookings = data.filter(b => b.poojaEventId == this.service.poojaEvent.id);
        console.log(this.myBookings)
      })
    }
  }

  get hasLoggedIn(): boolean {
    return this.authService.isAuthenticated
  }

  loadScript(scriptUrl) {
    let body = <HTMLDivElement>document.body;
    let script = document.createElement('script');
    script.innerHTML = '';
    script.src = scriptUrl;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  bookPooja() {
    this.validateUserLogin();
    if(!this.fields.name){
      this.toastService.error("Please provide the name first");
      return;
    }
    if(!this.fields.phoneNumber){
      this.toastService.error("Please provide the Phone Number first");
      return;
    }
    if(!this.fields.poojaDate){
      this.toastService.error("Please select a date first");
      return;
    }
    if(new Date(this.fields.poojaDate)<new Date()){
      this.toastService.error("Please select a valid date");
      return;
    }
    


    this.confirmationService.info("Upon submitting your request will be sent to the mandir, someone from the mandir committee will contact you confirm the details and date based on availability.  Submitting this form doesn't confirm your request automatically.", 'Disclaimer').subscribe(result => {
      if(result === Confirmation.Status.confirm){
        let amount = this.authService.isAuthenticated ? this.service.poojaEvent.cost : this.service.poojaEvent.nonMemberCost;
          var booking = {
            name: this.service.poojaEvent.name + ' for ' + this.currentUser.name,
            poojaEventId: this.service.poojaEvent.id,
            userId: this.currentUser.id,
            contactPhone:this.fields.phoneNumber,
            address: this.fields.address,
            contactName:this.fields.name,
            occasion:this.fields.occasion,
            contactEmail:this.fields.email,
            remarks:'',
            transactionAmount: amount,
            fromDate:this.fields.poojaDate,
            toDate:this.fields.poojaDate,
            amount: amount,
            accountingYear: this.currentYear,
            date: this.fields.poojaDate,
            status: BookingStatus.Received
          } as BookingPaymentCreateDto;
          this.publicService.addBookingByModel(booking).subscribe(data => {
            this.getMyBookings();
            this.fields.poojaDate='';
            this.fields.occasion='';
            this.toastService.success("Booking request added successfully");
          })
          //this.makePayment(51);
      }
    })

    
    
    

    // var booking = {
    //   name: this.service.poojaEvent.name + ' for ' + this.currentUser.name,
    //   poojaEventId: this.service.poojaEvent.id,
    //   userId: this.currentUser.id,
    //   date: this.poojaDate
    // } as BookingCreateDto;
    // this.publicService.addBookingByModel(booking).subscribe(data => {
    //   this.getMyBookings();
    // })


  }
  validateUserLogin(){
    if (!this.hasLoggedIn) {
      this.confirmationService.info("Please login to submit request for the service.", 'Info',{yesText:'Login'}).subscribe(result => {
        if(result === Confirmation.Status.confirm){
          this.ref.close();
          this.authService.navigateToLogin();
        }
        else{
          this.ref.close();
          return;
        }
      });
      return;
    }
  }

  fetchDateAvailability($event){
    // var startDate = moment($event.year +'-'+ $event.month +'-01').format("YYYY-MM-DD");
    // var endDate = moment($event.year +'-'+ $event.month +'-01').add(1, "M") .format("YYYY-MM-DD");
    // var poojaEventId = this.service.poojaEvent.id;
    
    // 
    // this.publicService.getBookedDatesByStartDateAndEndDateAndPoojaEventId(startDate, endDate, poojaEventId).subscribe(result => {
    //   
    //   var resp:Date[] = [];
    //   result.forEach(r => resp.push(new Date(r)));
    //   this.disabledDates = resp;
    // })
  }
  clearFilters() {
    this.fields.poojaDate='';
    this.fields.occasion='';
  }
}
