<div class="single-donation-wrap row align-items-top justify-content-between" style="margin-bottom: 120px;">
  <div class="col-lg-6">
      <div class="donation-image">
          <img [src]="service.poojaEvent.imageUrl" class="img-fluid" alt="Donation Image">
      </div>
    <div class="col-12 col-md-6 mt-4">
      <div *ngIf="myBookings.length">
        <h6>{{ '::PoojaDetails:YourBookings' | abpLocalization }}</h6>
      <div class="row" *ngFor="let item of myBookings">
        <div class="col-12">
          <div><strong>{{ '::Booking' | abpLocalization }}</strong>  {{item.name}} </div>
          <div><strong>{{ '::Date' | abpLocalization }}</strong>  {{item.date | date}} </div>
          <div><strong>{{ '::Status' | abpLocalization }}</strong>  {{getStatus(item.status) }} </div>
        </div>
      </div>
      </div>
    </div>
  </div>
  
  <div class="col-12 col-md-6 mt-2">
    <div class="col-lg-12">
      <div class="donation-content ml-lg-5">
          <div class="donation-title mb-30">
              <h4 class="mb-15">{{service.poojaEvent.name}}</h4>
              <div class="event-date">
                <span>{{ '::PoojaDetails:PerFamilyCost' | abpLocalization }} <strong>${{service.poojaEvent.cost}}</strong> </span> 
                <!-- <span class="px-2">| </span>
                <span>Non-Member Cost: <strong>${{service.poojaEvent.nonMemberCost}}</strong></span> -->
              </div>
              <div class="event-date">
                <span>{{ '::Capacity' | abpLocalization }} <strong>{{service.poojaEvent.capacity}}</strong> </span> <span class="px-2">| </span>
                <span>{{ '::Duration' | abpLocalization }} <strong>{{service.poojaEvent.duration}}</strong></span>
              </div>
          </div>

          <p>{{service.poojaEvent.description}}</p>

      </div>
  </div>
    <div class="col-12">
      <div class="col-12 mt-2">
        <div><strong>{{ '::Name' | abpLocalization }}</strong>  {{fields?.name}} </div>
        <div><strong>{{ '::Email' | abpLocalization }}</strong>  {{fields?.email}} </div>
        <div><strong>{{ '::PhoneNumber' | abpLocalization }}</strong>  {{fields?.phoneNumber}} </div>
        <!-- <div><strong>Address:</strong>  {{selected.booking.Address}} </div> -->
      </div>
<!-- 
      <div class="col-12 col-sm-auto">
        
        <div class="mb-3">
          <label class="form-label" for="nameFilter">
            {{ '::Name' | abpLocalization }}
          </label>
  
          <input
            id="nameFilter"
            class="form-control"
            [(ngModel)]="fields.name"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
      </div>
      <div class="col-12 col-sm-auto">
        <div class="mb-3">
          <label class="form-label" for="emailFilter">
            {{ '::Email' | abpLocalization }}
          </label>
  
          <input
            id="emailFilter"
            class="form-control"
            [(ngModel)]="fields.email"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
      </div>
      <div class="col-12 col-sm-auto">
        <div class="mb-3">
          <label class="form-label" for="phoneNumberFilter">
            {{ '::PhoneNumber' | abpLocalization }}
          </label>
  
          <input
            id="phoneNumberFilter"
            class="form-control"
            [(ngModel)]="fields.phoneNumber"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
      </div> -->
  
      <div class="col-12 col-sm-auto mt-2">
        <div class="mb-3">
          <label class="form-label" for="DateAndTime">
            {{ '::DateAndTime' | abpLocalization }}
          </label>
  
          <div class="col-12" validationTarget>
            <div class="formGroup">
            <p-calendar 
              inputId="calendar-12h" 
              [(ngModel)]="fields.poojaDate" 
              [showTime]="true" 
              [hourFormat]="24" />
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-12 col-sm-auto">
        <div class="mb-3">
          <label class="form-label" for="occasionFilter">
            {{ '::Occasion' | abpLocalization }}
          </label>
  
          <input
            id="occasionFilter"
            class="form-control"
            [(ngModel)]="fields.occasion"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
      </div>
      <div class="col-12 col-sm-auto">
        <div class="mb-3">
          <label class="form-label" for="addressFilter">
            {{ '::Address' | abpLocalization }}
          </label>
  
          <input
            id="addressFilter"
            class="form-control"
            [(ngModel)]="fields.address"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>
      </div>
  
      <div class="col-12 col-sm-auto align-self-end mb-3">
        <div class="row">
          <div class="header-btn text-end" style="float: left;">
            <a (click)="clearFilters()"  class="btn-circle btn-default btn" role="button"
            >{{ '::Clear' | abpLocalization }}</a
          >
          <a (click)="bookPooja()"  class="btn-circle btn-default btn" role="button"
          ><i class="fa fa-cart-plus me-1" aria-hidden="true"></i>{{ '::SendRequest' | abpLocalization }}</a
        >
      </div>
    </div>
    <!-- <div class="col-12">
      <div><strong>Pick a Date:</strong></div>
      <p-calendar [(ngModel)]="poojaDate" [inline]="true" [showWeek]="false"
      
      (onMonthChange)="fetchDateAvailability($event)"
      (onYearChange)="fetchDateAvailability($event)"
      [disabledDates] = "disabledDates"
      ></p-calendar>
  
    </div> -->
  </div>
</div>
<div class="row">
  <div class="col-lg-6 col-md-12"></div>
  <div class="col-lg-6 col-md-12">
    <div #payElement>
      <!-- Your Stripe Elemnt render here -->
      </div>
      
  </div>
</div>


<!-- <div class="row">
  <div class="col-4">
    <div class="row">
      <div class="col-lg-12">
        <img class="img-fluid" [src]="service.poojaEvent.imageUrl" alt="">
      </div>
    </div>
  </div>
  <div class="col-8">
    <div class="row mb-10">
      <div class="col-12">
        <h2>{{service.poojaEvent.name}}</h2>
      </div>
    </div>
    <div class="row mb-10">
          <div class="col-6">
          <div class="row">
            <div class="col-8">
              <span class="event-header">Member Cost:</span>
            </div>
            <div class="col-4">
              <span class="event-detail">${{service.poojaEvent.cost}}</span>
            </div>
          </div>  
          </div>
            <div class="col-6">
              <div class="row">
                <div class="col-8">
                  <span class="event-header">Non-Member Cost:</span> 
                </div>
                <div class="col-4">
                  <span class="event-detail">${{service.poojaEvent.nonMemberCost}}</span>
                </div>
              </div>
            </div>
    </div>
    
    <div class="row mb-10">
          <div class="col-6">
            <div class="row">
              <div class="col-8">
                <span class="event-header">Capacity:</span> 
              </div>
              <div class="col-4">
                <span class="event-detail">{{service.poojaEvent.capacity}}</span>
              </div>
            </div>
          </div>
            <div class="col-6">
              <div class="row">
                <div class="col-8">
                  <span class="event-header">Duration:</span> 
                </div>
                <div class="col-4">
                  <span class="event-detail">{{service.poojaEvent.duration}}</span>
                </div>
              </div>
          </div>
        </div>

    
  </div>
  <div class="row">
    <div class="col-lg-12">
      <p>{{service.poojaEvent.description}}</p>
    </div>
  </div>
</div> -->