import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@abp/ng.core';
import { ManidrSettingService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-account-layout',
  templateUrl: './account-layout.component.html',
  styleUrls: ['./account-layout.component.scss']
})
export class AccountLayoutComponent implements OnInit, AfterViewInit {
  title: string = '';
  logoUrl: string = '';
  constructor(
    public authService: AuthService,
    private router: Router,
    private settingService: ManidrSettingService
  ) { }

  ngOnInit(): void {
    this.logoUrl = this.settingService.getLogoUrl();
    if (this.router.url.includes('login')) {
      this.title = 'Login';
    } else if (this.router.url.includes('register')) {
      this.title = 'Register';
    } else if (this.router.url.includes('forgot-password')) {
      this.title = 'Forgot Password';
    } else if (this.router.url.includes('reset-password')) {
      this.title = 'Reset Password';
    }
  }

  ngAfterViewInit(): void {
    document.body.style.padding = '0px';
  }
} 