import type { RegisterExtnDto, SendPasswordResetLinkDto } from './models';
import { RestService } from '@abp/ng.core';

import { Injectable } from '@angular/core';
import { IdentityUserDto } from '@volo/abp.ng.identity/proxy';

@Injectable({
  providedIn: 'root',
})
export class AccountExtnService {
  apiName = 'Default';
  
  register = (input: RegisterExtnDto) =>
    this.restService.request<any, IdentityUserDto>({
      method: 'POST',
      url: '/api/accountExtn/register',
      body: input,
    },
    { apiName: this.apiName });

  sendPasswordResetLink = (input: SendPasswordResetLinkDto) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/accountExtn/send-password-reset-link',
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
