import { AuthService, ConfigStateService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { AfterViewInit, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IdentityLinkUserService, LinkUserInput} from '@volo/abp.ng.account/public/proxy';
import { from, of, pipe, throwError } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { eAccountComponents, RecaptchaService, RECAPTCHA_STRATEGY, getRedirectUrl, 
    SecurityCodeData, 
    PERIODICALLY_CHANGE_PASSWORD,
    REQUIRES_TWO_FACTOR,
    SHOULD_CHANGE_PASSWORD_ON_NEXT_LOGIN } from '@volo/abp.ng.account/public';
import { PublicService } from '@proxy/public';


const { maxLength, required } = Validators;

@Component({
  selector: 'abp-login',
  templateUrl: './login.component.html',
  providers: [RecaptchaService],
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('recaptcha', { static: false })
  recaptchaRef: ElementRef<HTMLDivElement>;

  form: UntypedFormGroup;

  inProgress: boolean;

  isSelfRegistrationEnabled = true;

  authWrapperKey = eAccountComponents.AuthWrapper;

  linkUser: LinkUserInput;

  protected fb: UntypedFormBuilder;
  protected toasterService: ToasterService;
  protected authService: AuthService;
  protected configState: ConfigStateService;
  protected route: ActivatedRoute;
  protected router: Router;
  protected identityLinkUserService: IdentityLinkUserService;
  protected recaptchaService: RecaptchaService;
  protected errorMessage: string;
  protected publicService: PublicService;

  constructor(protected injector: Injector) {
    this.fb = injector.get(UntypedFormBuilder);
    this.toasterService = injector.get(ToasterService);
    this.authService = injector.get(AuthService);
    this.configState = injector.get(ConfigStateService);
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.identityLinkUserService = injector.get(IdentityLinkUserService);
    this.recaptchaService = injector.get(RecaptchaService);
    this.publicService = injector.get(PublicService);
  }

  ngOnInit() {
    this.init();
    this.buildForm();
    this.setLinkUserParams();
  }

  ngAfterViewInit() {
    this.recaptchaService.setStrategy(
      RECAPTCHA_STRATEGY.Login(this.configState, this.recaptchaRef.nativeElement),
    );
  }

  protected setLinkUserParams() {
    const {
      linkUserId: userId,
      linkToken: token,
      linkTenantId: tenantId,
    } = this.route.snapshot.queryParams;

    if (userId && token) {
      this.identityLinkUserService.verifyLinkToken({ token, userId, tenantId }).subscribe(res => {
        if (res) {
          this.linkUser = { userId, token, tenantId };
        }
      });
    }
  }

  protected init() {
    this.isSelfRegistrationEnabled =
      (
        (this.configState.getSetting('Abp.Account.IsSelfRegistrationEnabled') as string) || ''
      ).toLowerCase() !== 'false';
  }

  protected buildForm() {
    this.form = this.fb.group({
      username: ['', [required, maxLength(255)]],
      password: ['', [required, maxLength(128)]],
      rememberMe: [false],
    });
  }

  onSubmit() {
    if (this.form.invalid) return;

    this.errorMessage = '';
    this.inProgress = true;

    const { username, password, rememberMe } = this.form.value;
    const redirectUrl = getRedirectUrl(this.injector) || (this.linkUser ? null : '/');
    const loginParams = { username, password, rememberMe, redirectUrl };

    this.checkRecaptcha()
      .pipe(
        switchMap(isValid => 
          isValid 
            ? this.publicService.validateUserEmailByUserName(username)
            : of(null)
        ),
        switchMap(accountCount => {
          if (!accountCount) {
            throw new Error('Invalid username or password');
          }
          
          if (accountCount > 1) {
            throw new Error(`Your account is locked, please contact contact us on info@vaishnodevi.ca to get your account fixed.`);
          }
          
          // If accountCount is 1, proceed with login
          return this.authService
            .login(loginParams)
            //.pipe(this.handleLoginError(loginParams))
            .pipe(this.linkUser ? this.switchToLinkUser() : tap());
        }),
        finalize(() => (this.inProgress = false)),
      )
      .subscribe({
        error: (err) => {
          this.recaptchaService.reset();
          this.errorMessage = err.name == "Error" ?(err.message || err.error?.error_description || 'Invalid username or password') : err.error?.error_description || 'Invalid username or password';
          this.toasterService.error(
            this.errorMessage,
            'Login Failed',
            { life: 7000 },
          );
        }
      });
  }

  private checkRecaptcha() {
    return this.recaptchaService.isEnabled ? this.recaptchaService.validate() : of(true);
  }

  private switchToLinkUser() {
    return pipe(
      switchMap(() => this.identityLinkUserService.link(this.linkUser)),
      tap(() => {
        this.router.navigate(['/account/link-logged'], {
          queryParams: this.route.snapshot.queryParams,
        });
      }),
    );
  }

  private handleLoginError(loginParams?: Omit<SecurityCodeData, 'twoFactorToken' | 'userId'>) {
    return catchError(err => {
      const errorDescription = err.error?.error_description;

      switch (errorDescription) {
       
        case PERIODICALLY_CHANGE_PASSWORD:
        case SHOULD_CHANGE_PASSWORD_ON_NEXT_LOGIN: {
          const queryParams = {
            token: err.error.changePasswordToken,
            redirectUrl: loginParams.redirectUrl,
            username: loginParams.username,
          };
          return from(
            this.router.navigate(['/account/change-password'], {
              queryParams,
            }),
          );
        }
      }

      this.recaptchaService.reset();
      this.errorMessage = err.error?.error_description ||
          err.error?.error?.message ||
          'Invalid username or password';
      this.toasterService.error(
        this.errorMessage,
        'Login Failed',
        { life: 7000 },
      );
      return throwError(err);
    });
  }
}
