import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const TICKET_SALES_TICKET_SALE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/ticket-sales',
        iconClass: 'fas fa-ticket',
        name: '::Menu:TicketSales',
        layout: eLayoutType.application,
        requiredPolicy: 'Mandir.TicketSales',
      },
    ]);
  };
}
