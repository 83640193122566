<footer class="footer d-print-none">
    <div class="container">
        <div class="row align-items-center">
            <!-- Left Menu -->
            <div class="col-12 col-md-4">
                <div class="left-menu">
                    <nav class="navbar navbar-expand justify-content-md-end justify-content-center">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/']">{{ '::Home' | abpLocalization }}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/page','about-us']">{{ '::Header:About' | abpLocalization }}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/page','privacy-policy']">{{ '::Header:PrivacyPolicy' | abpLocalization }}</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            
            <!-- Center Logo -->
            <div class="col-12 col-md-4">
                <div class="logo-ps">
                    <a [routerLink]="['/']">
                        <img [src]="logoUrl" alt="{{ '::Logo' | abpLocalization }}" class="img-fluid">
                    </a>
                </div>
            </div>
            
            <!-- Right Menu -->
            <div class="col-12 col-md-4">
                <div class="right-menu">
                    <nav class="navbar navbar-expand justify-content-md-start justify-content-center">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/events']">{{ '::Header:Events' | abpLocalization }}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/services']">{{ '::Header:Services' | abpLocalization }}</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" [routerLink]="['/page','contact']">{{ '::Header:Contact' | abpLocalization }}</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        
        <!-- Optional: Add social media icons -->
        <!-- <div class="row mt-4">
            <div class="col-12">
                <div class="social-icons">
                    <a href="#" target="_blank" aria-label="Facebook"><i class="fab fa-facebook-f"></i></a>
                    <a href="#" target="_blank" aria-label="Twitter"><i class="fab fa-twitter"></i></a>
                    <a href="#" target="_blank" aria-label="Instagram"><i class="fab fa-instagram"></i></a>
                    <a href="#" target="_blank" aria-label="YouTube"><i class="fab fa-youtube"></i></a>
                </div>
            </div>
        </div> -->
    </div>
</footer>

<div class="footer-copyright d-print-none">
    <div class="container">
        <p>{{ '::Footer:Copyright' | abpLocalization }}</p>
    </div>
</div> 