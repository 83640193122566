import type { CheckoutSessionResponse, DonationCheckoutDto, DonationSessionResponse, StripePaymentDto, StripePaymentSearchDto } from './dtos/models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  apiName = 'Default';
  

  createCheckoutSessionByAmountAndCurrencyAndEmailAndNameAndSuccessUrlAndCancelUrl = (amount: number, currency: string, email?: string, name?: string, successUrl?: string, cancelUrl?: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/app/stripe/create-checkout-session',
      params: { amount, currency, email, name, successUrl, cancelUrl },
    },
    { apiName: this.apiName,...config });
  

  createCustomerPortalSessionByCustomerId = (customerId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/stripe/create-customer-portal-session',
      params: { customerId },
    },
    { apiName: this.apiName,...config });
  

  createDonationCheckoutSessionByInput = (input: DonationCheckoutDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DonationSessionResponse>({
      method: 'POST',
      url: '/api/app/stripe/create-donation-checkout-session',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  getPayments = (input: StripePaymentSearchDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<StripePaymentDto>>({
      method: 'POST',
      url: '/api/app/stripe/GetPayments',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  getStripeIntentByAmountAndCurrencyAndEmailAndName = (amount: number, currency: string, email?: string, name?: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/app/stripe/GetStripeIntent',
      params: { amount, currency, email, name },
    },
    { apiName: this.apiName,...config });
  

  getStripeIntentStatusByPaymentIntentId = (paymentIntentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/app/stripe/GetStripeIntentStatus',
      params: { paymentIntentId },
    },
    { apiName: this.apiName,...config });
  

  getStripeIntentsByEmail = (email: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/app/stripe/GetStripeIntents',
      params: { email },
    },
    { apiName: this.apiName,...config });
  

  getUnprocessedPayments = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, StripePaymentDto[]>({
      method: 'GET',
      url: '/api/app/stripe/GetUnprocessedPayments',
    },
    { apiName: this.apiName,...config });
  

  handleWebhook = (json: string, stripeSignature: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/stripe/HandleWebhook',
      params: { json, stripeSignature },
    },
    { apiName: this.apiName,...config });
  

  processPayment = (id: string, category: string, notes: string, customerEmail?: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, StripePaymentDto>({
      method: 'POST',
      url: '/api/app/stripe/ProcessPayment',
      params: { id, category, notes, customerEmail },
    },
    { apiName: this.apiName,...config });
  

  retrieveCheckoutSessionBySessionId = (sessionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CheckoutSessionResponse>({
      method: 'GET',
      url: '/api/app/stripe/retrieve-checkout-session',
      params: { sessionId },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
