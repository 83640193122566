import { LocalizationService, PermissionService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicService } from '@proxy/public';
import { SafeHtmlPipe } from '../../pipes/safehtml.pipe';

@Component({

  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
  providers:[SafeHtmlPipe]
})
export class ReceiptComponent implements OnInit{

  html:string;
  constructor(
    private publicService: PublicService,
    private activatedRoute: ActivatedRoute,
    private permissionService: PermissionService,
    private safe: SafeHtmlPipe,
    private localizationService: LocalizationService
  ){

  }

  ngOnInit(): void {
    let allowOfflinePayment = this.permissionService.getGrantedPolicy('Mandir.App.AllowOfflinePayments');
    this.activatedRoute.params.subscribe(params => {
      if(params){
        if(params.id){
        if(allowOfflinePayment){
          this.publicService.getReceiptDetailsById(params.id).subscribe(result => {
            if(result == null){
              this.html = this.localizationService.instant('::Receipt:NotExistOrNoAccess');
            }else{
            this.html = result;
            }
          })
        }
        }

        if(params.uid){

        if(allowOfflinePayment){
          this.publicService.getReceiptDetailsById(params.id).subscribe(result => {
            if(result == null){
              this.html = this.localizationService.instant('::Receipt:NotExistOrNoAccess');
            }else{
            this.html = result;
            }
          })
        }
        }
      }
    })
    
  }

  getSafe(value){
    return this.safe.transform(value,'html');
  }
}
