import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const PAYMENT_REQUESTS_PAYMENT_REQUEST_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/payment-requests',
        iconClass: 'fas fa-money',
        name: '::Menu:PaymentRequests',
        layout: eLayoutType.application,
        requiredPolicy: 'Mandir.PaymentRequests',
      },
    ]);
  };
}
