import { AuthService, ConfigStateService } from '@abp/ng.core';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PaymentTypeDto } from '@proxy/payment-types';
import { AddMemberVM, MemberInfo, PaymentCreateDto, PaymentDto } from '@proxy/payments';
import { MemberInfoDto, PublicService } from '@proxy/public';

import { DialogService } from 'primeng/dynamicdialog';
import { MembershipFormComponent } from '../membership-form/membership-form.component';
import { PublicDownloadService } from '../services/public-download.service';
import { UserInfoDto } from '@proxy/shared';
import { AddMemberInfo, MembershipInfo } from '../shared/membership-application.model';
import { SettingsService } from '@proxy/settings';
import { ActivatedRoute } from '@angular/router';
import { IdentityUserDto } from '@volo/abp.ng.identity/proxy';

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.scss'],
  providers:[DialogService]

})
export class ReceiptsComponent implements OnInit{
  currentUser$ = this.configState.getDeep$('currentUser');
  currentUser: any;
  activeUserId: string;

  memberInfo: MemberInfoDto={} as MemberInfoDto;
  membershipPayments:PaymentDto[]=[];
  currentYear:number=2024;
  paymentHandler: any = null;
  familyMembers:IdentityUserDto[]=[];
  currentUserInfo: UserInfoDto;
  displayMessage:boolean=false;
  messageContent:string='';
  uiBusy:boolean=false;
  previousYears: number[] = [];
  constructor(
    private authService: AuthService,
    private configState: ConfigStateService,
    private publicService: PublicService,
    private dialogService: DialogService,
    private downloadService: PublicDownloadService,
    private settingService: SettingsService,
    private activatedRoute: ActivatedRoute
  ){

  }

  get hasLoggedIn(): boolean {
    return this.authService.isAuthenticated
  }

  login() {
    this.authService.navigateToLogin({
      returnUrl : '/membership'
    } );
  }

  hasCurrentYearMembership(){
    return this.membershipPayments.filter(p => p.accountingYear == this.currentYear).length;
    
  }
  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    this.previousYears = Array.from({length: 5}, (_, i) => currentYear - (i + 1));

    this.currentUser$.subscribe(val => {
      this.currentUser = val;
      this.activeUserId = this.currentUser.id;
      if(this.hasLoggedIn){
        if(this.currentUser 
          && this.currentUser.roles
          && this.currentUser.roles.length
          && this.currentUser.roles.indexOf("admin") > -1){
            this.activatedRoute.params.subscribe(p => {
              if(p.id){
                this.activeUserId = p.id.toLowerCase();
                this.loadData(p.id);
  
              }
              else{
                this.loadData(this.activeUserId);
              }
            })
          }
          else{
            this.loadData(this.activeUserId);
          }
      }
      

    });
    this.settingService.getSettingsByNames("Mandir.CurrentAccountYear").subscribe(
      setting => {
        if(setting.length){
          this.currentYear = parseInt(setting[0].value);

        }
      })
    
    
    
    
  }

  loadData(userId){
    
    this.publicService.getUserInfoById(userId).subscribe(data => {
      
      this.currentUserInfo = data;
    });

    this.publicService.getMemberInfoByUserId(userId).subscribe(data => {
      
      this.memberInfo = data;
      this.familyMembers = data.familyMembers;
      var membershipCategories = data.paymentTypes.filter(m => m.name =="Membership Fee" || m.name.indexOf("Membership") > -1 || m.name == "NR Membership").map(c => c.id);
      if(membershipCategories.length){
        this.membershipPayments = data.payments.filter(p=> membershipCategories.indexOf(p.paymentTypeId) > -1);
      }
      

    });
  }
  

  getAnnualReceipt(id, year){
    this.uiBusy=true;
    
    this.downloadService.getAnnualReceiptByHtml(id+":" + this.activeUserId, year).subscribe(newBlob=>{
      
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a'); 
    link.href = data;
    link.download = "VaishnoDevi-Mandir-Annual-Receipt-"+year+".pdf";
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    this.uiBusy=false;
  
    })
  }

  getPaymentType(id:string){
    var types = this.memberInfo.paymentTypes.filter(t => t.id == id);
    if(!types.length)
      return '';

      return types[0].name;
  }


  

  getPaymentsByUser(membershipPayments, userId){
    return membershipPayments.filter(p => p.payeeId == userId);
  }

  getReceipt(id){
    this.uiBusy = true;
    this.downloadService.getReceiptByHtml(id).subscribe(newBlob=>{
      
      //var newBlob = new Blob([x as any], { type: "application/pdf" });
    console.log(newBlob);
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a'); 
    link.href = data;
    link.download = "VaishnoDevi-Mandir-Receipt_"+id+".pdf";
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      this.uiBusy = false;
    });

  }

  showReceipt(id){
    this.uiBusy = true;
    this.publicService.getReceiptDetailsById(id).subscribe(newBlob=>{
      
      //var newBlob = new Blob([x as any], { type: "application/pdf" });
    console.log(newBlob);
    this.uiBusy = false;
   

  })
}
}
