import { AuthService } from '@abp/ng.core';
import { ToasterService } from '@abp/ng.theme.shared';
import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '@volo/abp.ng.account/public';
import { finalize } from 'rxjs/operators';
import { getPasswordValidators } from '@abp/ng.theme.shared';

const { required } = Validators;

@Component({
  selector: 'abp-reset-password',
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {
  form: UntypedFormGroup;
  inProgress: boolean;
  resetComplete = false;
  private userId: string;
  private resetToken: string;
  
  protected fb: UntypedFormBuilder;
  protected toasterService: ToasterService;
  protected accountService: AccountService;
  protected route: ActivatedRoute;
  protected router: Router;
  protected authService: AuthService;

  constructor(protected injector: Injector) {
    this.fb = injector.get(UntypedFormBuilder);
    this.toasterService = injector.get(ToasterService);
    this.accountService = injector.get(AccountService);
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.authService = injector.get(AuthService);
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    const encodedToken = this.route.snapshot.queryParamMap.get('resetToken');
    this.resetToken = encodedToken ? decodeURIComponent(encodedToken) : '';
  }

  ngOnInit() {
    this.form = this.fb.group({
      password: ['', [required, ...getPasswordValidators(this.injector)]],
      confirmPassword: ['', [required, ...getPasswordValidators(this.injector)]],
    }, {
      validator: this.passwordMatchValidator
    });
  }

  private passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('password').value === g.get('confirmPassword').value
      ? null
      : { mismatch: true };
  }

  onSubmit() {
    if (this.form.invalid || this.inProgress) return;

    this.inProgress = true;

    this.accountService
      .resetPassword({
        userId: this.userId,
        resetToken: this.resetToken,
        password: this.form.get('password').value,
      })
      .pipe(finalize(() => (this.inProgress = false)))
      .subscribe({
        next: () => {
          this.resetComplete = true;
          this.toasterService.success(
            'AbpAccount::PasswordResetSuccess',
            'Success',
            { life: 5000 }
          );
          this.router.navigate(['/account/login']);
        },
        error: (err) => {
          this.toasterService.error(
            err.error?.error_description ||
              err.error?.error?.message ||
              'AbpAccount::DefaultErrorMessage',
            'Error',
            { life: 5000 }
          );
        },
      });
  }
} 