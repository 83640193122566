import { NgModule } from '@angular/core';
import { PageModule } from '@abp/ng.components/page';
import { SharedModule } from '../../shared/shared.module';
import { EventDetailsRoutingModule } from './event-details-routing.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { EventDetailsComponent } from './event-details.component';
import { StepsModule } from 'primeng/steps';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { GooglePlacesModule } from '../google-places/google-places.module';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [EventDetailsComponent],
  imports: [
    SharedModule, 
    EventDetailsRoutingModule,
    StepsModule,
    DynamicDialogModule,
    DropdownModule,
    RadioButtonModule ,
    GooglePlacesModule,
    RxReactiveFormsModule,
    InputTextModule,
    ButtonModule
  
  ],
})
export class EventDetailsModule {}
