import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";


@Injectable()
export class StyleLoaderService {
    private Styles: StyleModel[] = [];

    public load(Style: StyleModel): Observable<StyleModel> {
        return new Observable<StyleModel>((observer: Observer<StyleModel>) => {
            var existingStyle = this.Styles.find(s => s.name == Style.name);

            // Complete if already loaded
            if (existingStyle && existingStyle.loaded) {
                observer.next(existingStyle);
                observer.complete();
            }
            else {
                // Add the Style
                this.Styles = [...this.Styles, Style];

                // Load the Style
                let StyleElement = document.createElement("style");
                

                if(Style.content){
                    StyleElement.innerHTML = Style.content;
                }

                StyleElement.onload = () => {
                    Style.loaded = true;
                    observer.next(Style);
                    observer.complete();
                };

                StyleElement.onerror = (error: any) => {
                    observer.error("Couldn't load Style " + Style.src);
                };

                document.getElementsByTagName('body')[0].appendChild(StyleElement);
            }
        });
    }
}

export interface StyleModel {
    name: string,
    src: string,
    content:string,
    loaded: boolean
}