import { Component } from '@angular/core';
import { ManidrSettingService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  logoUrl: string = '';
  currentYear: number = new Date().getFullYear();
  
  constructor(private settingService: ManidrSettingService) {
    this.logoUrl = this.settingService.getLogoUrl();
  }
} 