<!-------------bannner------------>
<div class="banner-part"> <img src="/assets/images/donate-banner.jpg" alt="">
  <div class="container">
    <div class="wraper">
      <h1>VIRTUAL QUEUE 
        <span *ngIf="authService.isAuthenticated">FOR {{currentUser.name}} {{currentUser.surname}}</span></h1>
    </div>
  </div>
</div>
<!-------------banner------------> 

<!--------Pooja Services---------->
<section>
  <div style="margin-top: 40px; padding: 20px 40px; ">
    <h2>Why Virtual Queue?</h2>
  <p style="font-size: 1.5em">Many a times when you come to the Mandir, you are pleasantly greeted with many devotees there.  Since we only have limited space and we have to follow the fire code, we all can't go for the Pooja or Darshan all at once and have to get in a queue.  In the past we only offered the "Tokens" at the Mandir when you arrive.  <br><br>You can now plan your visit ahead of time and get your "eToken" to join the virtual queue.  Once you have the eToken, please don't forget to take a screenshot or printout to bring with you when you come to visit the Mandir.  This eToken will be <strong>valid only for the time slot you have selected</strong> so please make sure to use it at the right time. We recommend getting to the mandir premises a few minutes early to give yourself enough time for parking and walking to the doors.<br><br>  Don't worry,  if you were not able to get eToken online, or if you are late for any reason, you can always take the a new physical token as usual when you get to the Mandir and join the regular queue here.    <br /> <br />
  </p>
  </div>
</section>
<section class="upcoming-event Rt-e Pooja-Services" >
  <div class="container">          
    <div class="row pt-5" *ngFor="let event of events">
      <div class="col-12 head-title">
        <h2 >{{getEvent(event).name}}  ({{getEvent(event).date | date : 'dd-MMM-yyyy'}})</h2>
        <h5 
        style="font-size: 1.5em; color: #FFF; text-align: center;"
        [innerHTML]="getEventDescription(event)"></h5>
      </div>
      <!------->
      <div class="col-12 col-md-4" *ngFor="let slot of getSlots(event)">
        <div class="bot-card" >
          <!-- <div class="image-s">
            <img [src]="serviceDto.poojaEvent.thumbnailUrl"  class="img-fluid" alt="Vaishno">
          </div> -->
          <div class="bot-card-ps" *ngIf="!isBookedSlot(slot)">
            <div class="Donate-s" style="height: 200px; overflow: auto;">
              <h3 class="cursor-pointer"
              
              (click)="showSlotBookings(slot.eventSlot.id)">{{slot.eventSlot.name}}</h3>
              <p [innerHTML]="slot.eventSlot.description"></p>
            </div>
            <div class="progress-psa">
               <div class="processcontent d-flex  mb-2">
                  <span>Availability: {{slot.eventSlot.maxAvailable - slot.eventSlot.currentAvailable}}</span> 
                  <!-- <span class="px-2"> out of </span><span>{{slot.eventSlot.maxAvailable}} </span> -->
              </div>      
            </div>
            <div class="button-more d-flex justify-content-between pt-1 mt-2">
              
              <!-- [disabled]="isBookedEvent(slot)" -->
              <button class="btn btn-primary"
              *ngIf="!isBookedEvent(slot) && (slot.eventSlot.maxAvailable - slot.eventSlot.currentAvailable) > 0"

              (click)="bookSlot(slot)"  role="button">Book Now</button>
            </div>
          </div>



          <div class="bot-card-ps"  *ngIf="isBookedSlot(slot)">
            <div class="Donate-s text-center">
              <h3>{{slot.eventSlot.name}} <br> <small>({{currentUser.name}} {{currentUser.surName}})</small></h3>
            </div>
            <div>
              <qr-code [value]="myAngularxQrCode+getBooking(slot.eventSlot.id).id" size="100" level="M"
              [routerLink]="['/slot-confirmation/'+getBooking(slot.eventSlot.id).id]"
              ></qr-code>
              <h2 style="float:right; margin-top: 20px" >
                Token #: &nbsp; <span
                style="font-size: 2em; padding: 0.1em 0.4em; border: 1px solid red;
                background-color: red; border-radius: 50%; color: #FFF
                "
                >{{getBooking(slot.eventSlot.id).slotNumber}}</span></h2>
              
            </div>
            
            <div class="button-more justify-content-between pt-1 mt-2">
              Please make sure to take a screenshot or print out and bring it with you for entry during the selected time slot.<br><br>
              <button class="btn btn-primary"
              (click)="removeSlot(slot)"  role="button">Cancel Slot</button>
            </div>
          </div>
        </div>
      </div>

     
    </div>
  </div>
</section>

<abp-modal [(visible)]="modalVisible" [busy]="modalBusy" [options]="{ size: 'xl' }">
  <ng-template #abpHeader>
    <h5>{{ 'SlotStatus' | abpLocalization }}</h5>
  </ng-template>
  <ng-template #abpBody>
    <div class="row">
      <div class="col-8">Name</div>
      <div class="col-2">Number</div>
      <div class="col-2">Status</div>
    </div>
    <div class="row" *ngFor="let reservation of slotReservations">
      <!-- {{reservation | json}} -->
      <div class="col-8">
        {{reservation.slotBooking.name}}
      </div>
      <div class="col-2">
        {{reservation.slotBooking.slotNumber}}
      </div>
      <div class="col-2">
        {{(reservation.slotBooking.usedAt ? "Used" : "--")}}
      </div>
    </div>
  </ng-template>
  <ng-template #abpFooter>
    <button abpClose type="button" class="btn btn-secondary">
      {{ 'AbpAccount::Cancel' | abpLocalization }}
    </button>
    <abp-button type="abp-button" iconClass="fa fa-check" (click)="confirmPhoneNumber()">
      {{ 'AbpAccount::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>