import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoadingService } from './loading-service';

@Injectable({ providedIn: 'root' })
export class HttpRequestInterceptor implements HttpInterceptor {

  blockUIEndpoints: (string | RegExp)[] = [];

  constructor(private _loading: LoadingService) {
    console.log('HttpRequestInterceptor started');
    
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      this.blockUIEndpoints = (window as any).blockUIEndPoints;
    } catch {}
    try {
      
      if (this.blockUIEndpoints && this.blockUIEndpoints.length > 0) {
        
        if (request.url && this.blockUIEndpoints.includes(request.url.split('app/')[1])) {
          
          this._loading.setLoading(true, request.url);
        }
      }
    } catch {}
    return next
      .handle(request)
      .pipe(
        catchError(err => {
          this._loading.setLoading(false, request.url);
          return err;
        })
      )
      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            this._loading.setLoading(false, request.url);
          }
          return evt;
        })
      );
  }
}
