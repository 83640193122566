<!-------------bannner------------>
<div class="banner-part" *ngIf="page.content"> <img src="/assets/images/donate-banner.jpg" alt="">
  <div class="container">
    <div class="wraper">
      <h1>{{page.title}}</h1>
    </div>
  </div>
</div>
<!-------------banner------------> 
<div class="container" style="padding: 40px;">
  <div class="row">
    <div class="col-12" *ngIf="page.content">
      <h2>{{page.title}}</h2>
      <span [innerHTML]="page.content"></span>
    </div>
    <div class="col-12" *ngIf="!page.content">
      <h2>{{ '::ContentPage:NotFound' | abpLocalization }}</h2>
      <p>{{ '::ContentPage:NotFoundMessage' | abpLocalization }}</p>
    </div>
  </div>
</div>