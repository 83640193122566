import { RoutesService, eLayoutType } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/',
        name: '::Menu:Home',
        iconClass: 'fas fa-home',
        order: 1,
        layout: eLayoutType.empty,
        },
        {
          path: '/manage-services',
          name: '::Menu:Services',
          iconClass: 'fas fa-home',
          order: 20,
          layout: eLayoutType.empty,
          invisible: true,
        },
        ,
      {
        path: '/manage-resource-links',
        name: '::Menu:Resources',
        iconClass: 'fas fa-home',
        order: 21,
        layout: eLayoutType.empty,
        invisible: true,
      },
      {
        path: '/manage-articles',
        name: '::Menu:Articles',
        iconClass: 'fas fa-home',
        order: 22,
        layout: eLayoutType.empty,
        invisible: true,
      },
      {
        path: '/manage-programs',
        name: '::Menu:Classes',
        iconClass: 'fas fa-home',
        order: 23,
        layout: eLayoutType.empty,
        invisible: true,
      },
      {
        path: '/stripe-payments',
        name: '::Menu:StripePayments',
        iconClass: 'fas fa-credit-card',
        order: 24,
        layout: eLayoutType.empty,
        requiredPolicy: 'Mandir.StripePayments',
        invisible: true,
      }
      
    ]);
  };
}
