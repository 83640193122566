import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { GetInteracPaymentsInput, GetInteracPaymentsDateRangeInput, InteracPaymentDto } from '../interac-payments/models';

@Injectable({
  providedIn: 'root',
})
export class InteracService {
  apiName = 'Default';
  

  getList = (input: GetInteracPaymentsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<InteracPaymentDto>>({
      method: 'GET',
      url: '/api/app/interac',
      params: { filterText: input.filterText, senderName: input.senderName, message: input.message, referenceNumber: input.referenceNumber, amount: input.amount, dateReceived: input.dateReceived, receiptIssued: input.receiptIssued, senderEmail: input.senderEmail, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getListByDateRange = (input: GetInteracPaymentsDateRangeInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<InteracPaymentDto>>({
      method: 'GET',
      url: '/api/app/interac/by-date-range',
      params: { 
        senderEmail: input.senderEmail,
        startDate: input.startDate?.toISOString(),
        endDate: input.endDate?.toISOString(),
        receiptIssued: input.receiptIssued,
        sorting: input.sorting,
        skipCount: input.skipCount,
        maxResultCount: input.maxResultCount
      },
    },
    { apiName: this.apiName,...config });

  verifyPaymentsByReferenceNumber = (referenceNumber: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, InteracPaymentDto>({
      method: 'POST',
      url: '/api/app/interac/verify-payments',
      params: { referenceNumber },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
