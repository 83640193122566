import type { MemberInfoDto, MultiLanguageCampaignDto, MultiLanguagePoojaEventDto, MultiLanguageProgramDto, MultiLanguageResourceLinkDto, MultiLanguageScheduledEventDto } from './models';
import { RestService, Rest } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { AnnouncementDto } from '../announcements/models';
import type { ArticleDto } from '../articles/models';
import type { AttendanceCreateDto, AttendanceDto } from '../attendances/models';
import type { BannerDto } from '../banners/models';
import type { BookingCreateDto, BookingDto, BookingPaymentCreateDto, BookingUpdateDto, BookingWithNavigationPropertiesDto, GetBookingsInput } from '../bookings/models';
import type { CampaignDto } from '../campaigns/models';
import type { ClassScheduleDto } from '../class-schedules/models';
import type { DiscountCodeDto } from '../discount-codes/models';
import type { AddDonationDto } from '../donations/models';
import type { EmailTemplateDto } from '../email-templates/models';
import type { EventSlotWithNavigationPropertiesDto } from '../event-slots/models';
import type { EventTicketTypeDto } from '../event-ticket-types/models';
import type { ActionResult } from '../microsoft/asp-net-core/mvc/models';
import type { APIResonseDto, BulkTextVM } from '../models';
import type { PaymentRequestDto } from '../payment-requests/models';
import type { PaymentTypeDto } from '../payment-types/models';
import type { AddMemberVM, GetPaymentsInput, PaymentDto, PaymentWithNavigationPropertiesDto } from '../payments/models';
import type { GetPoojaEventsInput, PoojaEventDto, PoojaEventWithNavigationPropertiesDto } from '../pooja-events/models';
import type { UserProgramDto } from '../programs/models';
import type { ResourceLinkDto } from '../resource-links/models';
import type { ScheduledEventDto } from '../scheduled-events/models';
import type { CustomLookupDto, HostInfoDto, LookupDto, LookupRequestDto, UserInfoDto } from '../shared/models';
import type { SlotBookingDto, SlotBookingWithNavigationPropertiesDto } from '../slot-bookings/models';
import type { StudentCreateDto, StudentDto } from '../students/models';
import type { SubscriptionCreateDto } from '../subscriptions/models';
import type { TicketInfoDto, TicketSaleDto, TicketSaleWithNavigationPropertiesDto } from '../ticket-sales/models';
import { IdentityUserDto } from '@volo/abp.ng.identity/proxy';


@Injectable({
  providedIn: 'root',
})
export class PublicService {
  apiName = 'Default';
  

  addBookingByModel = (model: BookingPaymentCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BookingDto>({
      method: 'POST',
      url: '/api/app/public/bookings',
      body: model,
    },
    { apiName: this.apiName,...config });
  

  addDonationByModel = (model: AddDonationDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PaymentDto>({
      method: 'OPTIONS',
      url: '/api/app/public/AddDonation',
      body: model,
    },
    { apiName: this.apiName,...config });
  

  addMembersAndPaymentByModel = (model: AddMemberVM, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PaymentDto>({
      method: 'POST',
      url: '/api/app/public/AddMembersAndPayment',
      body: model,
    },
    { apiName: this.apiName,...config });
  

  addSlotBookingBySlotBookingDto = (slotBookingDto: SlotBookingDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SlotBookingDto>({
      method: 'POST',
      url: '/api/app/public/AddSlotBooking',
      body: slotBookingDto,
    },
    { apiName: this.apiName,...config });
  

  addStudentByInput = (input: StudentCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'POST',
      responseType: 'text',
      url: '/api/app/public/AddStudent',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  addSubscriptionsByNameAndSurnameAndEmailAndPhone = (name: string, surname: string, email: string, phone: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/public/AddSubscriptions',
      params: { name, surname, email, phone },
    },
    { apiName: this.apiName,...config });
  

  cancelScheduledJobByTemplateIdAndJobId = (templateId: string, jobId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/public/CancelScheduledJob',
      params: { templateId, jobId },
    },
    { apiName: this.apiName,...config });
  

  clearAllCache = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/public',
    },
    { apiName: this.apiName,...config });
  

  clearCacheByCategoryName = (categoryName: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/public/ClearCache',
      params: { categoryName },
    },
    { apiName: this.apiName,...config });
  

  cloneEmailTemplateBySourceId = (sourceId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, EmailTemplateDto>({
      method: 'POST',
      url: '/api/app/public/CloneEmailTemplate',
      params: { sourceId },
    },
    { apiName: this.apiName,...config });
  

  confirmUserByInputUserAndFamilyHostIdAndUpdateProperties = (inputUser: HostInfoDto, familyHostId: string, updateProperties: boolean = true, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdentityUserDto>({
      method: 'POST',
      url: '/api/app/public/ConfirmUser',
      params: { familyHostId, updateProperties },
      body: inputUser,
    },
    { apiName: this.apiName,...config });
  

  createBooking = (input: BookingCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BookingDto>({
      method: 'POST',
      url: '/api/app/public/CreateBookingAsync',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  createUser = (inputUser: HostInfoDto, familyHostId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdentityUserDto>({
      method: 'POST',
      url: '/api/app/public/CreateUser',
      params: { familyHostId },
      body: inputUser,
    },
    { apiName: this.apiName,...config });
  

  deleteBooking = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: '/api/app/public/DeleteBookingAsync',
      params: { id },
    },
    { apiName: this.apiName,...config });
  

  emailAvailableByEmail = (email: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/public/EmailAvailable',
      params: { email },
    },
    { apiName: this.apiName,...config });
  

  getAllEvents = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, ScheduledEventDto[]>({
      method: 'GET',
      url: '/api/app/public/events/all',
    },
    { apiName: this.apiName,...config });
  

  getAnnouncements = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, AnnouncementDto[]>({
      method: 'GET',
      url: '/api/app/public/GetAnnouncements',
    },
    { apiName: this.apiName,...config });
  

  getAnnualReceiptByTokenAndYear = (token: string, year: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ActionResult>({
      method: 'GET',
      url: '/api/app/public/GetAnnualReceipt',
      params: { token, year },
    },
    { apiName: this.apiName,...config });
  

  getArticleBySlug = (slug: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ArticleDto>({
      method: 'GET',
      url: `/api/app/public/page/${slug}`,
    },
    { apiName: this.apiName,...config });
  

  getBanners = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, BannerDto[]>({
      method: 'GET',
      url: '/api/app/public/GetBanners',
    },
    { apiName: this.apiName,...config });
  

  getBookedDatesByStartDateAndEndDateAndPoojaEventId = (startDate: string, endDate: string, PoojaEventId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string[]>({
      method: 'GET',
      url: '/api/app/public/booked-dates',
      params: { startDate, endDate, poojaEventId: PoojaEventId },
    },
    { apiName: this.apiName,...config });
  

  getBookingByIdById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BookingDto>({
      method: 'GET',
      url: '/api/app/public/GetBookingById',
      params: { id },
    },
    { apiName: this.apiName,...config });
  

  getBookingList = (input: GetBookingsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<BookingWithNavigationPropertiesDto>>({
      method: 'POST',
      url: '/api/app/public/GetBookingListAsync',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  getBookingsByEventId = (eventId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SlotBookingWithNavigationPropertiesDto[]>({
      method: 'GET',
      url: '/api/app/public/GetBookings',
      params: { eventId },
    },
    { apiName: this.apiName,...config });
  

  getCampaignByCampaignId = (campaignId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CampaignDto>({
      method: 'GET',
      url: '/api/app/public/GetCampaign',
      params: { campaignId },
    },
    { apiName: this.apiName,...config });
  

  getCampaignWithTranslationsByIdAndLanguages = (id: string, languages: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, MultiLanguageCampaignDto>({
      method: 'GET',
      url: '/api/app/public/GetCampaignWithTranslations',
      params: { id, languages },
    },
    { apiName: this.apiName,...config });
  

  getCampaigns = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, CampaignDto[]>({
      method: 'GET',
      url: '/api/app/public/GetCampaigns',
    },
    { apiName: this.apiName,...config });
  

  getCampaignsWithTranslationsByLanguages = (languages: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, MultiLanguageCampaignDto[]>({
      method: 'GET',
      url: '/api/app/public/GetCampaignsWithTranslations',
      params: { languages },
    },
    { apiName: this.apiName,...config });
  

  getClassSchedule = (programId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ClassScheduleDto[]>({
      method: 'GET',
      url: '/api/app/public/GetClassScheduleAsync',
      params: { programId },
    },
    { apiName: this.apiName,...config });
  

  getEventById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ScheduledEventDto>({
      method: 'GET',
      url: '/api/app/public/event',
      params: { id },
    },
    { apiName: this.apiName,...config });
  

  getEventDatesByCount = (count: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string[]>({
      method: 'GET',
      url: '/api/app/public/eventDates',
      params: { count },
    },
    { apiName: this.apiName,...config });
  

  getEventSlots = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, EventSlotWithNavigationPropertiesDto[]>({
      method: 'POST',
      url: '/api/app/public/GetEventSlots',
    },
    { apiName: this.apiName,...config });
  

  getEventWithTranslationsByIdAndLanguages = (id: string, languages: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, MultiLanguageScheduledEventDto>({
      method: 'GET',
      url: '/api/app/public/event-with-translations',
      params: { id, languages },
    },
    { apiName: this.apiName,...config });
  

  getEventsByCount = (count: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ScheduledEventDto[]>({
      method: 'GET',
      url: '/api/app/public/events',
      params: { count },
    },
    { apiName: this.apiName,...config });
  

  getEventsByStartAndEnd = (start: string, end: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ScheduledEventDto[]>({
      method: 'GET',
      url: '/api/app/public/eventsByDate',
      params: { start, end },
    },
    { apiName: this.apiName,...config });
  

  getEventsListingByStartAndEnd = (start: string, end: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/app/public/GetEventsListing',
      params: { start, end },
    },
    { apiName: this.apiName,...config });
  

  getEventsWithTranslationsByCountAndLanguages = (count: number, languages: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, MultiLanguageScheduledEventDto[]>({
      method: 'GET',
      url: '/api/app/public/events-with-translations-by-count',
      params: { count, languages },
    },
    { apiName: this.apiName,...config });
  

  getEventsWithTranslationsByLanguages = (languages: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, MultiLanguageScheduledEventDto[]>({
      method: 'GET',
      url: '/api/app/public/events-with-translations',
      params: { languages },
    },
    { apiName: this.apiName,...config });
  

  getEventwithTickets = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, ScheduledEventDto[]>({
      method: 'GET',
      url: '/api/app/public/EventwithTickets',
    },
    { apiName: this.apiName,...config });
  

  getIdentityUserLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/app/public/identity-user-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getMemberInfoByUserId = (userId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MemberInfoDto>({
      method: 'POST',
      url: '/api/app/public/memberInfo',
      params: { userId },
    },
    { apiName: this.apiName,...config });
  

  getMembershipId = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, number>({
      method: 'GET',
      url: '/api/app/public/GetMembershipId',
    },
    { apiName: this.apiName,...config });
  

  getMyBookings = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, BookingDto[]>({
      method: 'GET',
      url: '/api/app/public/bookings',
    },
    { apiName: this.apiName,...config });
  

  getMyReceiptEmailByPaymentId = (paymentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/public/GetMyReceiptEmail',
      params: { paymentId },
    },
    { apiName: this.apiName,...config });
  

  getMySlotBookingsByUserId = (userId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SlotBookingDto[]>({
      method: 'POST',
      url: '/api/app/public/GetMySlotBookings',
      params: { userId },
    },
    { apiName: this.apiName,...config });
  

  getMyStudentAttendance = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttendanceDto[]>({
      method: 'GET',
      url: '/api/app/public/GetMyStudentAttendance',
    },
    { apiName: this.apiName,...config });
  

  getPaymentList = (input: GetPaymentsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PaymentWithNavigationPropertiesDto>>({
      method: 'POST',
      url: '/api/app/public/GetPaymentListAsync',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  getPaymentLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<CustomLookupDto<string>>>({
      method: 'GET',
      url: '/api/app/public/payment-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getPaymentRequestByPaymentRequestId = (paymentRequestId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PaymentRequestDto>({
      method: 'GET',
      url: '/api/app/public/GetPaymentRequest',
      params: { paymentRequestId },
    },
    { apiName: this.apiName,...config });
  

  getPaymentTypesByPayeeId = (payeeId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PaymentTypeDto[]>({
      method: 'GET',
      url: '/api/app/public/GetPaymentTypes',
      params: { payeeId },
    },
    { apiName: this.apiName,...config });
  

  getPoojaEventByIdById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PoojaEventDto>({
      method: 'GET',
      url: '/api/app/public/GetPoojaEventById',
      params: { id },
    },
    { apiName: this.apiName,...config });
  

  getPoojaEventList = (input: GetPoojaEventsInput, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<PoojaEventWithNavigationPropertiesDto>>({
      method: 'POST',
      url: '/api/app/public/GetPoojaEventListAsync',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  getPoojaEventLookup = (input: LookupRequestDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>({
      method: 'GET',
      url: '/api/app/public/pooja-event-lookup',
      params: { filter: input.filter, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName,...config });
  

  getProgramsByIsTeacher = (isTeacher: boolean, config?: Partial<Rest.Config>) =>
    this.restService.request<any, UserProgramDto[]>({
      method: 'GET',
      url: '/api/app/public/Programs',
      params: { isTeacher },
    },
    { apiName: this.apiName,...config });
  

  getProgramsWithTranslationsByIsTeacherAndLanguages = (isTeacher: boolean, languages: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, MultiLanguageProgramDto[]>({
      method: 'GET',
      url: '/api/app/public/programs-with-translations',
      params: { isTeacher, languages },
    },
    { apiName: this.apiName,...config });
  

  getPromoByCodeAndEventTypeId = (code: string, eventTypeId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, DiscountCodeDto>({
      method: 'POST',
      url: '/api/app/public/GetPromo',
      params: { code, eventTypeId },
    },
    { apiName: this.apiName,...config });
  

  getReceiptById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ActionResult>({
      method: 'GET',
      url: '/api/app/public/GetReceipt',
      params: { id },
    },
    { apiName: this.apiName,...config });
  

  getReceiptDetailsById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/app/public/GetReceiptDetails',
      params: { id },
    },
    { apiName: this.apiName,...config });
  

  getReceiptInfoById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PaymentWithNavigationPropertiesDto>({
      method: 'GET',
      url: '/api/app/public/GetReceiptInfo',
      params: { id },
    },
    { apiName: this.apiName,...config });
  

  getRecepientCountByTemplateId = (templateId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, number>({
      method: 'GET',
      url: '/api/app/public/GetRecepientCount',
      params: { templateId },
    },
    { apiName: this.apiName,...config });
  

  getResourceLinks = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, ResourceLinkDto[]>({
      method: 'GET',
      url: '/api/app/public/GetResourceLinks',
    },
    { apiName: this.apiName,...config });
  

  getResourceLinksWithTranslationsByLanguages = (languages: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, MultiLanguageResourceLinkDto[]>({
      method: 'GET',
      url: '/api/app/public/resource-links-with-translations',
      params: { languages },
    },
    { apiName: this.apiName,...config });
  

  getServicesByCount = (count: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PoojaEventWithNavigationPropertiesDto[]>({
      method: 'GET',
      url: '/api/app/public/services',
      params: { count },
    },
    { apiName: this.apiName,...config });
  

  getServicesWithTranslationsByCountAndLanguages = (count: number, languages: string[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, MultiLanguagePoojaEventDto[]>({
      method: 'GET',
      url: `/api/app/public/services-with-translations/${count}`,
      params: { languages },
    },
    { apiName: this.apiName,...config });
  

  getSlotBookingById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SlotBookingWithNavigationPropertiesDto>({
      method: 'GET',
      url: '/api/app/public/GetSlotBooking',
      params: { id },
    },
    { apiName: this.apiName,...config });
  

  getSlotBookingsByEventSlotId = (eventSlotId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SlotBookingWithNavigationPropertiesDto[]>({
      method: 'GET',
      url: '/api/app/public/GetSlotBookings',
      params: { eventSlotId },
    },
    { apiName: this.apiName,...config });
  

  getStudentRegistrationsByName = (name: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, StudentDto[]>({
      method: 'GET',
      url: '/api/app/public/StudentRegistrations',
      params: { name },
    },
    { apiName: this.apiName,...config });
  

  getTicketDetailsByTicketId = (ticketId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TicketInfoDto>({
      method: 'GET',
      url: '/api/app/public/GetTicketDetails',
      params: { ticketId },
    },
    { apiName: this.apiName,...config });
  

  getTicketSalesByEventId = (eventId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TicketSaleWithNavigationPropertiesDto[]>({
      method: 'GET',
      url: '/api/app/public/TicketSales',
      params: { eventId },
    },
    { apiName: this.apiName,...config });
  

  getTicketTypesByEventIdAndIsActive = (eventId: string, IsActive: boolean = true, config?: Partial<Rest.Config>) =>
    this.restService.request<any, EventTicketTypeDto[]>({
      method: 'GET',
      url: '/api/app/public/TicketTypes',
      params: { eventId, isActive: IsActive },
    },
    { apiName: this.apiName,...config });
  

  getTicketedEvents = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, ScheduledEventDto[]>({
      method: 'GET',
      url: '/api/app/public/GetTicketedEvents',
    },
    { apiName: this.apiName,...config });
  

  getTickets = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, TicketInfoDto[]>({
      method: 'GET',
      url: '/api/app/public/GetTickets',
    },
    { apiName: this.apiName,...config });
  

  getTicketsByTicketTypeId = (ticketTypeId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TicketInfoDto[]>({
      method: 'GET',
      url: '/api/app/public/GetTicketsList',
      params: { ticketTypeId },
    },
    { apiName: this.apiName,...config });
  

  getUserInfoById = (id?: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, UserInfoDto>({
      method: 'GET',
      url: '/api/app/public/GetUserInfo',
      params: { id },
    },
    { apiName: this.apiName,...config });
  

  getUserRegistrations = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, StudentDto[]>({
      method: 'GET',
      url: '/api/app/public/MyStudents',
    },
    { apiName: this.apiName,...config });
  

  getUserRegistrationsByProgramId = (programId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, StudentDto[]>({
      method: 'GET',
      url: '/api/app/public/MyStudentsByProgramId',
      params: { programId },
    },
    { apiName: this.apiName,...config });
  

  initializePasswordById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdentityUserDto>({
      method: 'POST',
      url: '/api/app/public/InitPassword',
      params: { id },
    },
    { apiName: this.apiName,...config });
  

  markSlotBookingUsedById = (id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SlotBookingDto>({
      method: 'POST',
      url: '/api/app/public/MarkSlotBookingUsed',
      params: { id },
    },
    { apiName: this.apiName,...config });
  

  purchaseEventTicketsByUserIdAndEventTicketTypeIdAndQuantityAndAmountAndPaymentConfiramtionCode = (userId: string, eventTicketTypeId: string, quantity: number, amount: string, paymentConfiramtionCode: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TicketSaleDto>({
      method: 'POST',
      url: '/api/app/public/PurchaseEventTickets',
      params: { userId, eventTicketTypeId, quantity, amount, paymentConfiramtionCode },
    },
    { apiName: this.apiName,...config });
  

  removeSlotBookingByIdAndUserId = (Id: string, userId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/public/RemoveSlotBooking',
      params: { id: Id, userId },
    },
    { apiName: this.apiName,...config });
  

  reportAbsenceByModel = (model: AttendanceCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AttendanceDto>({
      method: 'POST',
      url: '/api/app/public/ReportAbsence',
      body: model,
    },
    { apiName: this.apiName,...config });
  

  sendAdhocNotificationsByNumbersAndMessageAndMode = (numbers: string, message: string, mode: string = "whatsapp", config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/public/SendAdhocNotifications',
      params: { numbers, message, mode },
    },
    { apiName: this.apiName,...config });
  

  sendAdhocTextNotificationsByNumbersAndMessage = (numbers: string, message: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/public/SendAdhocTextNotifications',
      params: { numbers, message },
    },
    { apiName: this.apiName,...config });
  

  sendAllAnnualReceiptEmailsByYear = (year: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'GET',
      url: '/api/app/public/SendAllAnnualReceiptEmails',
      params: { year },
    },
    { apiName: this.apiName,...config });
  

  sendAnnualReceiptEmailByPaymentIdAndUserIdAndYear = (paymentId: string, userId: string, year: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/public/SendAnnualReceiptEmail',
      params: { paymentId, userId, year },
    },
    { apiName: this.apiName,...config });
  

  sendBulkAdhocNotificationsByMessagesAndMode = (messages: BulkTextVM[], mode: string = "whatsapp", config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/public/SendBulkAdhocNotifications',
      params: { mode },
      body: messages,
    },
    { apiName: this.apiName,...config });
  

  sendEventEmailsByTemplateIdAndStartAndEndAndScheduleForAndEmails = (templateId: string, start: string, end: string, scheduleFor: string, emails?: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string[]>({
      method: 'POST',
      url: '/api/app/public/SendEventEmails',
      params: { templateId, start, end, scheduleFor, emails },
    },
    { apiName: this.apiName,...config });
  

  sendEventTicketEmailByTicketIds = (ticketIds: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/public/SendEventTicketEmail',
      params: { ticketIds },
    },
    { apiName: this.apiName,...config });
  

  sendMessageByPhoneNumberAndMessageAndMode = (phoneNumber: string, message: string, mode: string = "whatsapp", config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/public/SendMessage',
      params: { phoneNumber, message, mode },
    },
    { apiName: this.apiName,...config });
  

  sendMessageToAllMembersByMessageAndMode = (message: string, mode: string = "whatsapp", config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/public/SendMessageToAllMembers',
      params: { message, mode },
    },
    { apiName: this.apiName,...config });
  

  sendMessageToParentsAndTeachersByMessageAndMode = (message: string, mode: string = "whatsapp", config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/public/SendMessageToParentsAndTeachers',
      params: { message, mode },
    },
    { apiName: this.apiName,...config });
  

  sendMessageToSubscribedMembersByMessageAndMode = (message: string, mode: string = "whatsapp", config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/public/SendMessageToSubscribedMembers',
      params: { message, mode },
    },
    { apiName: this.apiName,...config });
  

  sendWelcomeEmailById = (Id: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/public/SendWelcomeEmail',
      params: { id: Id },
    },
    { apiName: this.apiName,...config });
  

  subscribeBySubscription = (subscription: SubscriptionCreateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, APIResonseDto>({
      method: 'POST',
      url: '/api/app/public/Subscribe',
      body: subscription,
    },
    { apiName: this.apiName,...config });
  

  unsubscribeByIdAndContactInfo = (id: string, contactInfo: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, APIResonseDto>({
      method: 'POST',
      url: '/api/app/public/Unsubscribe',
      params: { id, contactInfo },
    },
    { apiName: this.apiName,...config });
  

  updateBooking = (id: string, input: BookingUpdateDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, BookingDto>({
      method: 'POST',
      url: '/api/app/public/UpdateBookingAsync',
      params: { id },
      body: input,
    },
    { apiName: this.apiName,...config });
  

  updatePaymentByInput = (input: PaymentDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/public/UpdatePayment',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  updatePaymentRequestByPaymentRequestIdAndPaymentId = (paymentRequestId: string, paymentId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, PaymentRequestDto>({
      method: 'PUT',
      url: '/api/app/public/UpdatePaymentRequest',
      params: { paymentRequestId, paymentId },
    },
    { apiName: this.apiName,...config });
  

  updateUserByIdAndUser = (id: string, user: IdentityUserDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, IdentityUserDto>({
      method: 'POST',
      url: '/api/app/public/UpdateUser',
      params: { id },
      body: user,
    },
    { apiName: this.apiName,...config });
  

  useTicketByTicketIdAndIds = (ticketId: string, ids: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, TicketInfoDto>({
      method: 'POST',
      url: '/api/app/public/UseTicket',
      params: { ticketId, ids },
    },
    { apiName: this.apiName,...config });
  

  validateUserEmailByUserName = (userName: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, number>({
      method: 'POST',
      url: '/api/app/public/ValidateUserEmail',
      params: { userName },
    },
    { apiName: this.apiName,...config });
  

  voidPaymentByPayment = (payment: PaymentDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: '/api/app/public/VoidPayment',
      body: payment,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
