import { AuthService, ConfigStateService, PermissionService } from '@abp/ng.core';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PaymentTypeDto } from '@proxy/payment-types';
import { AddMemberVM, MemberInfo, PaymentCreateDto, PaymentDto } from '@proxy/payments';
import { MemberInfoDto, PublicService } from '@proxy/public';
import { IdentityUserDto } from '@volo/abp.ng.identity/proxy';
import { DialogService } from 'primeng/dynamicdialog';
import { MembershipFormComponent } from '../membership-form/membership-form.component';
import { PublicDownloadService } from '../services/public-download.service';
import { UserInfoDto } from '@proxy/shared';
import { AddMemberInfo, MembershipInfo } from '../shared/membership-application.model';
import { SettingsService } from '@proxy/settings';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter } from 'rxjs';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import * as moment from 'moment';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss'],
  providers:[DialogService]
})
export class MembershipComponent implements OnInit{
  emptyGuid:string='00000000-0000-0000-0000-000000000000';

  currentUser$ = this.configState.getDeep$('currentUser');
  currentUser: any;
  activeUserId: string;
  previousYear:number=moment().subtract(1, 'year').year();
  memberInfo: MemberInfoDto={} as MemberInfoDto;
  membershipPayments:PaymentDto[]=[];
  currentYear:number=2024;
  paymentHandler: any = null;
  familyMembers:IdentityUserDto[]=[];
  currentUserInfo: UserInfoDto;
  displayMessage:boolean=false;
  messageContent:string='';
  canAddNewMembers:boolean=false;
  canSeeAnnualReport:boolean=false;
  uiBusy:boolean=false;
  modalVisible:boolean=false;
  modalBusy:boolean=false;
  editModalVisible:boolean=false;
  paymentModalVisible:boolean=false;
  paymentModalBusy:boolean=false;
  editModalBusy:boolean=false;
  selected:PaymentDto;
  form: FormGroup;
  editForm: FormGroup;
  allowVoid:boolean=false;
  paymentTypes: PaymentTypeDto[] = [];
  ref:string;
  amt:string;
  mode:string;
  previousYears: number[] = [];
  constructor(
    private authService: AuthService,
    private configState: ConfigStateService,
    private publicService: PublicService,
    private dialogService: DialogService,
    private downloadService: PublicDownloadService,
    private settingService: SettingsService,
    protected toaster: ToasterService,
    private activatedRoute: ActivatedRoute,
    private permissionService: PermissionService,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,


  ){

  }

  get hasLoggedIn(): boolean {
    return this.authService.isAuthenticated
  }

  login() {
    this.authService.navigateToLogin({
      returnUrl : '/membership'
    } );
  }

  getMembershipYear(date: string) {
    const dt = moment(date);
    const calendarYear = parseInt(dt.format('YYYY'));
    
    // If date is before April 1st of the calendar year, it belongs to the previous membership year
    // which started on April 1st of the previous year
    if (dt.isBefore(moment(`${calendarYear}-04-01`))) {
      return calendarYear;
    }
    
    // If date is on or after April 1st, it belongs to the next membership year
    // which will end on March 31st of the next year
    return calendarYear + 1;
  }
  hasCurrentYearMembership(){
    return this.membershipPayments.filter(p => this.getMembershipYear(p.creationTime.toString()) == this.currentYear).length;
    
  }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      
      if(params['ref']){
        this.ref = params['ref'];
      }
      if(params['amt']){
        this.amt = params['amt'];
      }
      if(params['mode']){
        this.mode = params['mode'];
      }
    });
    this.canAddNewMembers = this.permissionService.getGrantedPolicy('Mandir.App.AddNewMembers');
    this.canSeeAnnualReport = this.permissionService.getGrantedPolicy('Mandir.App.AllowAnnualReport');
    
    this.allowVoid = this.permissionService.getGrantedPolicy('Mandir.App.AllowVoidPayments');
    this.initData();
    this.settingService.getSettingsByNames("Mandir.CurrentAccountYear").subscribe(
      setting => {
        if(setting.length){
          this.currentYear = parseInt(setting[0].value);

        }
      })
    
    const currentYear = new Date().getFullYear();
    this.previousYears = Array.from({length: 5}, (_, i) => currentYear - (i + 1));
  }


  initData(){
    this.currentUser$.subscribe(val => {
      this.currentUser = val;
      this.activeUserId = this.currentUser.id;
      if(this.hasLoggedIn){
        if(this.currentUser 
          && (
            (this.currentUser.roles
              && this.currentUser.roles.length
              && this.currentUser.roles.indexOf("admin") > -1
            )
          || this.canAddNewMembers)
          ){
            this.activatedRoute.params.subscribe(p => {
              if(p.id){
                this.activeUserId = p.id.toLowerCase();
                this.loadData(p.id);
  
              }
              else{
                this.loadData(this.activeUserId);
              }
            })
          }
          else{
            this.loadData(this.activeUserId);
          }
      }
      

    });
  }
  loadData(userId){
    
    this.publicService.getUserInfoById(userId).subscribe(data => {
      
      this.currentUserInfo = data;
    });

    this.publicService.getMemberInfoByUserId(userId).subscribe(data => {
      
      this.memberInfo = data;
      this.familyMembers = data.familyMembers;
      this.paymentTypes = data.paymentTypes;
      var membershipCategories = data.paymentTypes.filter(m => m.name =="Membership Fee" || m.name.indexOf("Membership") > -1 || m.name == "NR Membership").map(c => c.id);
      if(membershipCategories.length){
        this.membershipPayments = data.payments.filter(p=> membershipCategories.indexOf(p.paymentTypeId) > -1);
      }
      

    });
  }
  

  getPaymentType(id:string){
    var types = this.memberInfo.paymentTypes.filter(t => t.id == id);
    if(!types.length)
      return '';

      return types[0].name;
  }


  membershipForm(){
    
    const dialog = this.dialogService
    .open(MembershipFormComponent, {
      width: '80%',
      data: {
        currentYear: this.currentYear,
        hostMember: this.memberInfo.member,
        familyMembers: this.memberInfo.familyMembers,
        existingPayments: this.memberInfo.payments,
        paymentTypes: this.memberInfo.paymentTypes
      } as MembershipInfo
    })
    .onClose.subscribe(result => {
      
      if(result){
        this.loadData(this.activeUserId);
      }
      
    });
  }


  getPaymentsByUser(membershipPayments, userId): PaymentDto[]{
    return membershipPayments.filter(p => p.payeeId == userId);
  }

  getReceipt(id){
    this.uiBusy=true;
    this.downloadService.getReceiptByHtml(id).subscribe(newBlob=>{
      
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a'); 
    link.href = data;
    link.download = "VaishnoDevi-Mandir-Receipt.pdf";
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    this.uiBusy=false;
  
    })
  }

  getAnnualReceipt(id, year){
    this.uiBusy=true;
    
    this.downloadService.getAnnualReceiptByHtml(id+":" + this.activeUserId, year).subscribe(newBlob=>{
      
    const data = window.URL.createObjectURL(newBlob);

    var link = document.createElement('a'); 
    link.href = data;
    link.download = "VaishnoDevi-Mandir-Annual-Receipt-"+year+".pdf";
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    this.uiBusy=false;
  
    })
  }

  getReceiptNum(paymentId:string){
    return paymentId != null 
            ? paymentId.toUpperCase().slice(0,4) + '-'+ paymentId.toUpperCase().slice(paymentId.length-4,paymentId.length)
            :'';

  }

  sendAnnualEmail(paymentId:string, userId:string, year:number){
    this.confirmationService
      .info(
        'Are  you sure you want send this annual receipt on email?',
        'Send receipt on Email',
      )
      .pipe(filter(status => status === Confirmation.Status.confirm))
      .subscribe(result => {
        this.publicService.sendAnnualReceiptEmailByPaymentIdAndUserIdAndYear(
          paymentId,
          userId, year).subscribe();
      });
  }

  sendEmail(record:PaymentDto){
    this.confirmationService
      .info(
        'Are  you sure you want send this receipt on email?',
        'Send receipt on Email',
      )
      .pipe(filter(status => status === Confirmation.Status.confirm))
      .subscribe(result => {

          this.publicService.getMyReceiptEmailByPaymentId(record.id).subscribe();
      });
  }
  showVoidPayment(record:PaymentDto){
    this.selected = record;
    this.showForm();
  }
  showForm() {
    this.buildForm();
    this.modalVisible = true;
  }
  

  buildForm() {
    const { verificationNote } = this.selected || {};

    this.form = this.fb.group({
      verificationNote: [verificationNote ?? null, [Validators.required]]
    });
  }

  hideForm() {
    this.modalVisible = false;
    this.form.reset();
  }

  submitForm() {
    this.modalBusy = true;
    if (this.form.invalid) return;
    if(!this.selected) return;

    this.confirmationService
      .info(
        'Are  you sure you want to void this payment?  This can not be undone. ',
        'Void an existing payment.',
      )
      .pipe(filter(status => status === Confirmation.Status.confirm))
      .subscribe(result => {

          this.selected.verificationNote = this.form.value.verificationNote;

          this.publicService.voidPaymentByPayment(this.selected).subscribe(
            result => {
              this.modalBusy = false;
              this.hideForm();
              this.initData();
            }
          )
      });
  }


  showEditForm(record:PaymentDto) {
    this.selected = record;
    this.buildEditForm();
    this.editModalVisible = true;
  }
  
  showPaymentModal() {
    this.paymentModalVisible = true;
  }
  

  getConfirmationCodeDetail(){
    try {
      let confirmationCode = JSON.parse(this.selected.confirmationCode);
      if(confirmationCode && confirmationCode.detail){
        return confirmationCode.detail;
      }
    } catch (error) {
      return '';
    }
  }

  buildEditForm() {
    const { id, paymentTypeId, verificationNote } = this.selected || {};

    this.editForm = this.fb.group({
      id:[id],
      paymentTypeId: [paymentTypeId ?? null, [Validators.required]],
      verificationNote: ['' ?? null, [Validators.required]],
    });
  }

  hideEditForm() {
    this.editModalVisible = false;
    this.editForm.reset();
  }

  submitEditForm() {

    
    this.editModalBusy = true;
    if (this.editForm.invalid) return;
    if(!this.selected) return;

    this.confirmationService
      .info(
        'Are  you sure you want to change this payment?  This can not be undone. ',
        'Change an existing payment.',
      )
      .pipe(filter(status => status === Confirmation.Status.confirm))
      .subscribe(result => {
        this.editForm.value.concurrencyStamp = this.selected.concurrencyStamp;
        this.editForm.value.verificationNote = 
          (this.selected.verificationNote ? this.selected.verificationNote : '') + 
          
          '<br /> <b>'+ this.currentUser.name + ' ' + this.currentUser.surName +'</b> Changed Payment Type from '
          + this.getPaymentType(this.selected.paymentTypeId) +' to ' + this.getPaymentType(this.editForm.value.paymentTypeId) + ' on '+ moment().format('MM-DD-YYYY hh:mm a') +'.  Reason: '
          +this.editForm.value.verificationNote;

          this.publicService.updatePaymentByInput(this.editForm.value).subscribe(
            result => {
              this.editModalBusy = false;
              this.hideEditForm();
              this.initData();
            }
          )
      });
  }
  closeModal(){
    this.paymentModalVisible=false;
    this.loadData(this.activeUserId);
    this.toaster.success('Thanks, your donation is received.', '', { life: 5000 });
  }
}
