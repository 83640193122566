import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterService } from '@abp/ng.theme.shared';
import { CoreModule, LocalizationService } from '@abp/ng.core';

@Component({
  selector: 'app-payment-cancel',
  standalone: true,
  imports: [CoreModule],
  templateUrl: './payment-cancel.component.html',
  styleUrl: './payment-cancel.component.scss'
})
export class PaymentCancelComponent {
  constructor(
    private router: Router,
    private toasterService: ToasterService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit() {
    this.toasterService.info(this.localizationService.instant('::PaymentCancel:PaymentCancelled'));
    // Clear any payment details from session storage
    sessionStorage.removeItem('paymentDetails');
  }

  navigateHome() {
    this.router.navigate(['/']);
  }
}
