import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PaymentDetailErrors, PaymentModes } from './accept-payment.model';
import { PermissionService } from '@abp/ng.core';
import { IdentityUserDto } from '@volo/abp.ng.identity/proxy';
import { CloverService } from '@proxy/clover';
import * as moment from'moment';
import { ToasterService } from '@abp/ng.theme.shared';
import { InteracService } from '@proxy/interac';
import { StripeService } from '@proxy/stripe';

@Component({
  selector: 'app-accept-payment',
  templateUrl: './accept-payment.component.html',
  styleUrls: ['./accept-payment.component.scss']
})
export class AcceptPaymentComponent {
  //@ViewChild('stripe') stripe: StripeComponent;
  paymentIntent:any;
  @Input() dollarAmount:number;
  @Input() interacReference:string;
  @Input() uiBusy:boolean=false;
  @Input() newUser:IdentityUserDto;
  @Input() date:string;
  @Input() comments:string;
    

  @Input() paymentMode:string = 'Online';
  transactionId:string;
  transactionDate:string = moment().format('YYYY-MM-DD');

  cardNum:number;
  paymentDetail:string;
  paymentModeMessage:string;
  paymentDetailMissing:PaymentDetailErrors = {
    transactionDate:false,
    transactionId:false,
    cardNum:false,
    comments:false
  };
  
  paymentModes: PaymentModes[] = [];
  @Output() formOnSubmit: EventEmitter<any> = new EventEmitter();
  allowOfflinePayments:boolean=false;
  receiptCorrection:boolean=false;
  paymentStatus: string = '';

  constructor(    private permissionService: PermissionService,
    private cloverAppService: CloverService,
    private toastService: ToasterService,
    private interacService: InteracService,
    private stripeService: StripeService
    ){

  }

  setPaymentModeMessate(){
    switch(this.paymentMode){
      
        case 'Cheque':
        this.paymentModeMessage = "Please provide Bank Name and account holder's name"
        break;
        case 'Cash':
        this.paymentModeMessage = "Please enter the name of the person reciving cash or provide cash receipt number"
        break;
        case 'CardTap':
        case 'Stripe':
        case 'Interac':
        this.paymentModeMessage = "Please enter the purpose of the transaction"
        break;
    }
  }

  ngOnInit(): void {
    if(this.interacReference){
      this.transactionId = this.interacReference;
    }
    this.allowOfflinePayments = this.permissionService.getGrantedPolicy('Mandir.App.AllowOfflinePayments');
    this.receiptCorrection = this.permissionService.getGrantedPolicy('Mandir.App.ReceiptCorrection');
    if(this.allowOfflinePayments){
      this.paymentModes.push({
        key: 'Stripe',
        name: 'Received via Stripe'
      });
      this.paymentModes.push({
        key: 'Interac',
        name: 'Interac Transfer'
      });
      this.paymentModes.push({
        key: 'Cheque',
        name: 'Cheque'
      });
      this.paymentModes.push({
        key: 'Cash',
        name: 'Cash'
      });
      this.paymentModes.push({
        key: 'CardTap',
        name: 'In person Debit/Credit Card Sale'
      });
      
      if(!this.paymentMode){
        this.paymentMode = 'CardTap';
      }
      if(this.paymentMode === 'Stripe' && this.comments){
        this.paymentDetail = this.comments.split(':').splice(1).join(' ');
      }
      this.setPaymentModeMessate();
    }
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    
  }

  startLoading(){
    this.uiBusy=true;
  }

  stopLoading(){
    setTimeout(() => {
      this.uiBusy=false;
    }, 1300);
  } 

  async onSubmit(){
    this.startLoading();
    if(this.paymentMode === 'Online'){
      // if (!this.stripe || this.stripe.form.invalid) {
      //   this.stopLoading();
      //   return;
      // }
      
      // const { paymentIntent } = await this.stripe.stripe.confirmPayment({
      //   elements: this.stripe.elements,
  
      //   confirmParams: {
      //     return_url: `${window.location.origin}/return.html`,
      //   },
      //   redirect: 'if_required'
      // });
  
      // console.log(paymentIntent);
      // if (paymentIntent) {
      //   this.paymentIntent= {
      //     paymentMode: this.paymentMode,
      //     paymentDetail: paymentIntent
      //   };
      // }
    }
    else{
      if(this.paymentMode && 
        (this.paymentMode ==='CardTap' || this.paymentMode ==='Interac' || this.paymentMode ==='Cheque'))
        {
          if(!this.transactionId)
          {
            this.paymentDetailMissing.transactionId=true;
            this.stopLoading();
            return;
          }
          // if(this.paymentMode ==='CardTap' && !this.cardNum){
          //   this.paymentDetailMissing.cardNum=true;
          //   return
          // }

          if(this.paymentMode ==='CardTap' && !this.transactionDate){
            this.paymentDetailMissing.transactionDate=true;
            this.stopLoading();
            return
          }
        }
      if(!this.paymentDetail || this.paymentDetail.trim() == ''){
        this.paymentDetailMissing.comments = true;
        this.stopLoading();
        return;
      }
      this.paymentIntent={
          paymentMode: this.paymentMode,
          transactionId: this.transactionId,
          cardNum: 0,
          paymentDetail: {
            detail: this.paymentDetail
          }
        }
    }
    
    let that = this;
    if(this.paymentMode ==='CardTap'){
      this.cloverAppService.verifyPaymentsByDateAndTransactionNoAndLast4(
        this.transactionDate,
        this.transactionId,
        0
      ).subscribe(res => {
        this.stopLoading();
        if(res){
          if(that.dollarAmount > res) {
            this.toastService.error('Provided transaction is for $'+ res +' which is less than the amount you are issuing receipt for, please fix the issue.');
            // alert('Provided transaction is for $'+ res +' which is less than the amount you are issuing receipt for, please fix the issue.');
            return;
          }
          this.paymentIntent.transactionDate= this.transactionDate;
          this.formOnSubmit.emit(this.paymentIntent);
        }
        else{
          this.toastService.error('Transaction not found, please verify the transaction details and try again');
          return;
        }
        //this.formOnSubmit.emit(this.paymentIntent);
      });
      return;
    }
    if(this.paymentMode ==='Interac'){
      this.interacService.verifyPaymentsByReferenceNumber(
        this.transactionId
      ).subscribe(res => {
        this.stopLoading();
        if(res){
          if(that.dollarAmount > parseFloat(res.amount.replace(',',''))){
            this.toastService.error('Provided transaction is for $'+ res.amount +' which is less than the amount you are issuing receipt for, please fix the issue.');
            return;
          }
          this.paymentIntent.transactionDate = res.dateReceived;
          this.formOnSubmit.emit(this.paymentIntent);
        }
        else{
          this.toastService.error('Transaction not found, please verify the transaction details and try again');
          return;
        }
        //this.formOnSubmit.emit(this.paymentIntent);
      });
      return;
    }
    else{
      if(this.transactionDate){

        var date = moment(this.transactionDate);
        var now = moment();
        date.add(now.hours(), 'hours');
        date.add(now.minutes(), 'minutes');
        date.add(now.seconds(), 'seconds');
        this.paymentIntent.transactionDate= date.toISOString();
      }
      this.formOnSubmit.emit(this.paymentIntent);
      this.stopLoading();
    }

  }
  reInitPayment() {
    var currentPayment=this.paymentMode;
    var vm=this;
    this.paymentMode='';
    if(currentPayment){ 
      setTimeout(() => {
        vm.paymentMode=currentPayment;
      }, 2000);
    }
  }

  async handlePayOnline() {
    this.startLoading();
    try {
      
      // Store payment details in session storage
      sessionStorage.setItem('paymentDetails', JSON.stringify({
        model: {
          // Add your donation model details here
          amount: this.dollarAmount,
          email: this.newUser?.email,
          name: this.newUser ? `${this.newUser.name} ${this.newUser.surname}` : '',
          // Add other necessary fields
        }
      }));

      const response = await this.stripeService.createCheckoutSessionByAmountAndCurrencyAndEmailAndNameAndSuccessUrlAndCancelUrl(
        this.dollarAmount * 100,
        'cad',
        this.newUser?.email,
        this.newUser ? `${this.newUser.name} ${this.newUser.surname}` : '',
        `${window.location.origin}/payment-success`,
        `${window.location.origin}/donate`
      ).toPromise();

      // Redirect to Stripe Checkout
      window.location.href = response;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      this.paymentStatus = 'Error processing payment. Please try again.';
      this.stopLoading();
    }
  }
}
