import { mapEnumToOptions } from '@abp/ng.core';

export enum BookingStatus {
  Received = 0,
  PendingPayment = 1,
  Confirmed = 2,
  CancellationRequested = 3,
  Cancelled = 4,
  Hold = 5,
}

export const bookingStatusOptions = mapEnumToOptions(BookingStatus);
