import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicService } from '@proxy/public';
import { ToasterService } from '@abp/ng.theme.shared';
import { StripeService } from '@proxy/stripe';
import { DonationCreateDto } from '@proxy/donations';
import { LocalizationService } from '@abp/ng.core';

@Component({
  selector: 'app-payment-success',
  standalone: true,
  imports: [],
  templateUrl: './payment-success.component.html',
  styleUrl: './payment-success.component.scss'
})
export class PaymentSuccessComponent implements OnInit {
  processingPayment = false;
  error: string = '';
  message: string = '';
  messageType: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private publicService: PublicService,
    private toasterService: ToasterService,
    private stripeService: StripeService,
    private localizationService: LocalizationService
  ) {}

  ngOnInit() {
    this.processPayment();
  }

  async processPayment() {
    
    this.processingPayment = true;
    try {
      // Get payment details from session storage
      let paymentDetails = JSON.parse(sessionStorage.getItem('paymentDetails') || '{}');
      
      if (!paymentDetails.model) {
        throw new Error('Payment details not found');
      }

      // Get session ID from URL parameters
      const queryParams = new URLSearchParams(window.location.search);
      const sessionId = queryParams.get('session_id');
      
      if (!sessionId) {
        throw new Error('Session ID not found');
      }

      // Retrieve the session to get the payment intent
      this.stripeService.retrieveCheckoutSessionBySessionId(sessionId).subscribe(session => {
        if (!session) {
          throw new Error('Payment intent not found in session');
        }
        let donationDto = JSON.parse(sessionStorage.getItem('paymentDetails')).model as DonationCreateDto;
        
        // Submit the donation with payment intent ID
        let model = {...donationDto, paymentToken: session.paymentToken, paymentIntent: session.paymentIntent};
        this.publicService.addDonationByModel(
          model
        ).subscribe(donation => {
          this.toasterService.success(this.localizationService.instant('::PaymentSuccess:DonationProcessed'));
          // Clear the payment details from session storage
          sessionStorage.removeItem('paymentDetails');
          this.message = this.localizationService.instant('::PaymentSuccess:DonationReceived');
          this.messageType = this.localizationService.instant('::PaymentSuccess:ThankYou');
          this.processingPayment = false;
        })
      })
    } catch (error) {
      console.error('Error processing payment:', error);
      this.message = this.localizationService.instant('::PaymentSuccess:ErrorProcessing');
      this.messageType = this.localizationService.instant('::PaymentSuccess:Error');
      this.error = this.localizationService.instant('::PaymentSuccess:ContactSupport');
      this.toasterService.error(this.localizationService.instant('::PaymentSuccess:ErrorProcessingDonation'));
      this.processingPayment = false;
    } finally {
      
    }
  }

  navigateHome() {
    this.router.navigate(['/']);
  }
}
