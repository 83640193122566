import { ABP, eLayoutType } from '@abp/ng.core';

export const RESOURCE_LINK_BASE_ROUTES: ABP.Route[] = [
  {
    path: '/resource-links',
    iconClass: 'fas fa-link',
    name: '::Menu:ResourceLinks',
    layout: eLayoutType.application,
    requiredPolicy: 'Mandir.ResourceLinks',
    breadcrumbText: '::ResourceLinks',
  },
];
