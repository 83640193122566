import { authGuard, permissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { EventListComponent } from './event-list/event-list.component';
import { MembershipComponent } from './membership/membership.component';
import { ContentPageComponent } from './content-page/content-page.component';
import { MemberServicesComponent } from './member-services/member-services.component';
import { ManageMembersComponent } from './manage-members/manage-members.component';
import { EventTicketAdminComponent } from './event-ticket-admin/event-ticket-admin.component';
import { DonationComponent } from './donation/donation.component';
import { EventSalesComponent } from './event-sales/event-sales.component';
import { ReceiptsComponent } from './receipts/receipts.component';
import { SlotBookingComponent } from './slot-booking/slot-booking/slot-booking.component';
import { SlotConfirmationComponent } from './slot-booking/slot-confirmation/slot-confirmation.component';
import { ReceiptComponent } from './receipts/receipt/receipt.component';
import { LoginComponent } from './accounts/login/login.component';
import { RegisterComponent } from './accounts/register/register.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentCancelComponent } from './payment-cancel/payment-cancel.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'pay/:paymentRequestId',
    component: DonationComponent,
  },
  {
    path: 'donation-programs',
    component: DonationComponent,
  },
  {
    path: 'donate',
    component: DonationComponent,
  },
  {
    path: 'donate-now',
    loadChildren: () => import('./donate-now/donate-now.module').then(m => m.DonateNowModule),
  },
  {
    path: 'donate/:campaignId',
    component: DonationComponent,
  },
  {
    path: 'donate/:campaignId/:userId',
    component: DonationComponent,
  },
  {
    path: 'donate/:campaignId/:userId/:paymentType',
    component: DonationComponent,
  },
  {
    path: 'event-slot',
    component: SlotBookingComponent,
  },
  {
    path: 'event-slot/:userId',
    component: SlotBookingComponent,
  },

  {
    path: 'slot-confirmation/:id',
    component: SlotConfirmationComponent,
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canActivate: [authGuard, permissionGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [authGuard, permissionGuard],
  },
  {
    path: 'account',
    loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule),
  },

  {
    path: 'gdpr',
    loadChildren: () => import('@volo/abp.ng.gdpr').then(m => m.GdprModule.forLazy()),
  },
  {
    path: 'identity',
    loadChildren: () => import('@volo/abp.ng.identity').then(m => m.IdentityModule.forLazy()),
  },
  {
    path: 'language-management',
    loadChildren: () =>
      import('@volo/abp.ng.language-management').then(m => m.LanguageManagementModule.forLazy()),
  },
  {
    path: 'saas',
    loadChildren: () => import('@volo/abp.ng.saas').then(m => m.SaasModule.forLazy()),
  },
  {
    path: 'audit-logs',
    loadChildren: () =>
      import('@volo/abp.ng.audit-logging').then(m => m.AuditLoggingModule.forLazy()),
  },
  {
    path: 'audit',
    loadChildren: () => import('./audit/audit.module').then(m => m.AuditModule),
    data: {
      routes: {
        name: 'Audit Logs',
      },
    },
  },
  {
    path: 'openiddict',
    loadChildren: () =>
      import('@volo/abp.ng.openiddictpro').then(m => m.OpeniddictproModule.forLazy()),
  },
  {
    path: 'text-template-management',
    loadChildren: () =>
      import('@volo/abp.ng.text-template-management').then(m =>
        m.TextTemplateManagementModule.forLazy()
      ),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then(m => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'gdpr-cookie-consent',
    loadChildren: () =>
      import('./gdpr-cookie-consent/gdpr-cookie-consent.module').then(
        m => m.GdprCookieConsentModule
      ),
  },
  {
    path: 'volunteers',
    loadChildren: () =>
      import('./volunteers/volunteer/volunteer.module').then(m => m.VolunteerModule),
  },
  {
    path: 'scheduled-events',
    loadChildren: () =>
      import('./scheduled-events/scheduled-event/scheduled-event.module').then(
        m => m.ScheduledEventModule
      ),
  },
  {
    path: 'service-categories',
    loadChildren: () =>
      import('./service-categories/service-category/service-category.module').then(
        m => m.ServiceCategoryModule
      ),
  },

  {
    path: 'bookings',
    loadChildren: () => import('./bookings/booking/booking.module').then(m => m.BookingModule),
  },
  {
    path: 'manage-bookings',
    loadChildren: () =>
      import('./bookings/manage-booking/manage-booking.module').then(m => m.ManageBookingModule),
  },
  {
    path: 'manage-bookings/:status',
    loadChildren: () =>
      import('./bookings/manage-booking/manage-booking.module').then(m => m.ManageBookingModule),
  },
  {
    path: 'events',
    component: EventListComponent,
  },
  {
    path: 'services',
    component: MemberServicesComponent,
  },
  // {
  //   path: 'receipts',
  //   loadChildren: () =>
  //     import('./receipts/receipts.module').then(m => m.ReceiptsModule),
  // },
  {
    path: 'receipts',
    component: ReceiptsComponent,
    canActivate: [authGuard],
  },
  {
    path: 'receipt/:id',
    component: ReceiptComponent,
    canActivate: [authGuard],
  },
  {
    path: 'membership',
    component: MembershipComponent,
    canActivate: [authGuard, permissionGuard],
  },
  {
    path: 'membership/:id',
    component: MembershipComponent,
    canActivate: [authGuard, permissionGuard],
  },
  {
    path: 'manage-members',
    loadChildren: () =>
      import('./manage-members/manage-members.module').then(m => m.ManageMembersModule),
  },
  {
    path: 'page/:slug',
    component: ContentPageComponent,
  },
  {
    path: 'manage-tickets',
    component: EventTicketAdminComponent,
  },
  {
    path: 'manage-tickets/:typeId',
    component: EventTicketAdminComponent,
  },
  {
    path: 'ticket',
    loadChildren: () => import('./event-ticket/event-ticket.module').then(m => m.EventTicketModule),
  },
  {
    path: 'event-sales',
    component: EventSalesComponent,
  },
  {
    path: 'event-detail/:id',
    loadChildren: () =>
      import('./shared/event-details/event-details.module').then(m => m.EventDetailsModule),
  },
  {
    path: 'event-detail/:id/:meta',
    loadChildren: () =>
      import('./shared/event-details/event-details.module').then(m => m.EventDetailsModule),
  },
  {
    path: 'pooja-events',
    loadChildren: () =>
      import('./pooja-events/pooja-event/pooja-event.module').then(m => m.PoojaEventModule),
  },
  {
    path: 'manage-services',
    loadChildren: () =>
      import('./pooja-events/pooja-event/pooja-event.module').then(m => m.PoojaEventModule),
  },
  {
    path: 'online-payments',
    loadChildren: () =>
      import('./online-payments/online-payments.module').then(m => m.OnlinePaymentsModule),
    canActivate: [authGuard, permissionGuard],
  },
  {
    path: 'payment-types',
    loadChildren: () =>
      import('./payment-types/payment-type/payment-type.module').then(m => m.PaymentTypeModule),
  },
  {
    path: 'stripe-payments',
    loadChildren: () =>
      import('./stripe-payments/stripe-payments.module').then(m => m.StripePaymentsModule),
    canActivate: [authGuard, permissionGuard],
  },
  {
    path: 'messaging',
    loadChildren: () => import('./messaging/messaging.module').then(m => m.MessagingModule),
    canActivate: [authGuard, permissionGuard],
    data: {
      requiredPolicy: 'Mandir.App.SendMessage'
    }
  },
  {
    path: 'payments',
    loadChildren: () => import('./payments/payment/payment.module').then(m => m.PaymentModule),
  },
  {
    path: 'manage-payments',
    loadChildren: () =>
      import('./payments/manage-payment/manage-payment.module').then(m => m.ManagePaymentModule),
  },
  {
    path: 'articles',
    loadChildren: () => import('./articles/article/article.module').then(m => m.ArticleModule),
  },
  {
    path: 'manage-articles',
    loadChildren: () => import('./articles/article/article.module').then(m => m.ArticleModule),
  },
  {
    path: 'snippets',
    loadChildren: () => import('./snippets/snippet/snippet.module').then(m => m.SnippetModule),
  },
  {
    path: 'manage-snippets',
    loadChildren: () =>
      import('./snippets/snippet/manage-snippet/manage-snippet.module').then(
        m => m.ManageSnippetModule
      ),
  },
  {
    path: 'email-templates',
    loadChildren: () =>
      import('./email-templates/email-template/email-template.module').then(
        m => m.EmailTemplateModule
      ),
  },
  {
    path: 'manage-email-templates',
    loadChildren: () =>
      import(
        './email-templates/email-template/manage-email-template/manage-email-template.module'
      ).then(m => m.ManageEmailTemplateModule),
  },
  {
    path: 'manage-email-templates/:type',
    loadChildren: () =>
      import(
        './email-templates/email-template/manage-email-template/manage-email-template.module'
      ).then(m => m.ManageEmailTemplateModule),
  },
  {
    path: 'campaigns',
    loadChildren: () => import('./campaigns/campaign/campaign.module').then(m => m.CampaignModule),
  },
  {
    path: 'donations',
    loadChildren: () => import('./donations/donation/donation.module').then(m => m.DonationModule),
  },
  {
    path: 'announcements',
    loadChildren: () =>
      import('./announcements/announcement/announcement.module').then(m => m.AnnouncementModule),
  },
  {
    path: 'banners',
    loadChildren: () => import('./banners/banner/banner.module').then(m => m.BannerModule),
  },
  {
    path: 'resource-links',
    loadChildren: () =>
      import('./resource-links/resource-link/resource-link.module').then(m => m.ResourceLinkModule),
  },
  {
    path: 'manage-resource-links',
    loadChildren: () =>
      import('./resource-links/resource-link/resource-link.module').then(m => m.ResourceLinkModule),
  },
  {
    path: 'event-ticket-types',
    loadChildren: () =>
      import('./event-ticket-types/event-ticket-type/event-ticket-type.module').then(
        m => m.EventTicketTypeModule
      ),
  },
  {
    path: 'ticket-sales',
    loadChildren: () =>
      import('./ticket-sales/ticket-sale/ticket-sale.module').then(m => m.TicketSaleModule),
  },
  {
    path: 'subscriptions',
    loadChildren: () =>
      import('./subscriptions/subscription/subscription.module').then(m => m.SubscriptionModule),
  },
  {
    path: 'program-list',
    loadChildren: () => import('./program-list/program-list.module').then(m => m.ProgramListModule),
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('./unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule),
  },
  {
    path: 'programs',
    loadChildren: () => import('./programs/program/program.module').then(m => m.ProgramModule),
  },
  {
    path: 'manage-programs',
    loadChildren: () => import('./programs/program/program.module').then(m => m.ProgramModule),
  },
  {
    path: 'students',
    loadChildren: () => import('./students/student/student.module').then(m => m.StudentModule),
  },
  {
    path: 'manage-students',
    loadChildren: () =>
      import('./students/manage-student/student.module').then(m => m.ManageStudentModule),
  },
  {
    path: 'attendances',
    loadChildren: () =>
      import('./attendances/attendance/attendance.module').then(m => m.AttendanceModule),
  },
  {
    path: 'attendance',
    loadChildren: () => import('./attendance/attendance.module').then(m => m.AttendanceModule),
  },
  {
    path: 'discount-codes',
    loadChildren: () =>
      import('./discount-codes/discount-code/discount-code.module').then(m => m.DiscountCodeModule),
  },
  {
    path: 'event-slots',
    loadChildren: () =>
      import('./event-slots/event-slot/event-slot.module').then(m => m.EventSlotModule),
  },
  {
    path: 'slot-bookings',
    loadChildren: () =>
      import('./slot-bookings/slot-booking/slot-booking.module').then(m => m.SlotBookingModule),
  },
  {
    path: 'payment-requests',
    loadChildren: () =>
      import('./payment-requests/payment-request/payment-request.module').then(
        m => m.PaymentRequestModule
      ),
  },
  {
    path: 'manage-payment-requests',
    loadChildren: () =>
      import('./payment-requests/manage-payment-request/manage-payment-request.module').then(
        m => m.ManagePaymentRequestModule
      ),
  },
  {
    path: 'class-schedules',
    loadChildren: () =>
      import('./class-schedules/class-schedule/class-schedule.module').then(
        m => m.ClassScheduleModule
      ),
  },
  {
    path: 'clover-payments',
    loadChildren: () =>
      import('./clover-payments/clover-payment/clover-payment.module').then(
        m => m.CloverPaymentModule
      ),
  },
  {
    path: 'interac-payments',
    loadChildren: () =>
      import('./interac-payments/interac-payment/interac-payment.module').then(
        m => m.InteracPaymentModule
      ),
  },
  {
    path: 'event-tickets',
    loadChildren: () =>
      import('./event-tickets/event-ticket/event-ticket.module').then(m => m.EventTicketModule),
  },
  {
    path: 'payment-success',
    component: PaymentSuccessComponent,
  },
  {
    path: 'payment-cancel',
    component: PaymentCancelComponent,
  },
  {
    path: 'sent-email-logs',
    loadChildren: () =>
      import('./sent-email-logs/sent-email-log/sent-email-log.module').then(
        m => m.SentEmailLogModule
      ),
  },
  {
    path: 'duplicate-users',
    loadChildren: () =>
      import('./duplicate-users/duplicate-users.module').then(m => m.DuplicateUsersModule),
  },
  {
    path: 'file-manager',
    loadChildren: () => import('./file-manager/file-manager.module').then(m => m.FileManagerModule),
    data: { requiredPolicy: 'Mandir.FileManager' }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
