<div class="forgot-password-container">
  <h2>Forgot Password</h2>

  <div *ngIf="!emailSent">
    <p>Enter your email address and we'll send you a link to reset your password.</p>

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="email">Email address</label>
        <input
          type="email"
          class="form-control"
          id="email"
          formControlName="email"
          [class.is-invalid]="form.get('email').invalid && form.get('email').touched"
        />
        <div class="invalid-feedback" *ngIf="form.get('email').errors?.required && form.get('email').touched">
          Email is required
        </div>
        <div class="invalid-feedback" *ngIf="form.get('email').errors?.email && form.get('email').touched">
          Please enter a valid email address
        </div>
      </div>

      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="form.invalid || inProgress"
      >
        <i class="fas fa-spinner fa-spin" *ngIf="inProgress"></i>
        Send Reset Link
      </button>
    </form>
  </div>

  <div *ngIf="emailSent" class="success-message">
    <i class="fas fa-check-circle"></i>
    <p>If you have an account with us, we've sent you an email with instructions to reset your password.</p>
    <p>Please check your inbox and follow the link in the email.</p>
  </div>
</div> 