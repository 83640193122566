<h2>{{ '::Settings' | abpLocalization }}</h2>
<h4>{{ message }}</h4>

<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::TestEmail' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <input
      type="text"
      style="width: 100%"
      pInputText
      class="p-inputtext-sm"
      [(ngModel)]="testEmail.value"
    />
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::TestPhone' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <input
      type="text"
      style="width: 100%"
      pInputText
      class="p-inputtext-sm"
      [(ngModel)]="testPhone.value"
    />
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::AnnualMembershipFee' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <input
      type="text"
      style="width: 100%"
      pInputText
      class="p-inputtext-sm"
      [(ngModel)]="annualMembershipFee.value"
    />
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::EventsToAvoid' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <input
      type="text"
      style="width: 100%"
      pInputText
      class="p-inputtext-sm"
      [(ngModel)]="eventsToAvoid.value"
    />
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::CurrentAccountingYear' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <input
      type="text"
      style="width: 100%"
      pInputText
      class="p-inputtext-sm"
      [(ngModel)]="currentAccountingYear.value"
    />
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::MembershipDonationTypeId' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <input
      type="text"
      style="width: 100%"
      pInputText
      class="p-inputtext-sm"
      [(ngModel)]="membershipDonationTypeId.value"
    />
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::LogoURL' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <input
      type="text"
      style="width: 100%"
      pInputText
      class="p-inputtext-sm"
      [(ngModel)]="logoUrl.value"
    />
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::BackgroundURL' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <input
      type="text"
      style="width: 100%"
      pInputText
      class="p-inputtext-sm"
      [(ngModel)]="backgroundUrl.value"
    />
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::CSSOverride' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <textarea
      type="text"
      rows="10"
      style="width: 100%"
      pInputTextarea
      placeholder='CSS Override'
      class="p-inputtext-sm"
      [(ngModel)]="cssOverride.value"
    ></textarea>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::ReceiptHtml' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <textarea
      type="text"
      rows="10"
      style="width: 100%"
      pInputTextarea
      placeholder='[{"name":"Receiver Name","number":"Receiver PhoneNumber"}]'
      class="p-inputtext-sm"
      [(ngModel)]="receiptHtml.value"
    ></textarea>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::EventNotificationPhoneNumbers' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <textarea
      type="text"
      rows="10"
      style="width: 100%"
      pInputTextarea
      class="p-inputtext-sm"
      [(ngModel)]="eventNotificationPhoneNumbers.value"
    ></textarea>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::AnnualReceiptHtml' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <textarea
      type="text"
      rows="10"
      style="width: 100%"
      pInputTextarea
      class="p-inputtext-sm"
      [(ngModel)]="annualReceiptHtml.value"
    ></textarea>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::MembershipReceiptHtml' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <textarea
      type="text"
      rows="10"
      style="width: 100%"
      pInputTextarea
      class="p-inputtext-sm"
      [(ngModel)]="membershipReceiptHtml.value"
    ></textarea>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::NonDonationReceiptHtml' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <textarea
      type="text"
      rows="10"
      style="width: 100%"
      pInputTextarea
      class="p-inputtext-sm"
      [(ngModel)]="nonDonationReceiptHtml.value"
    ></textarea>
  </div>
</div>
<div class="row mt-3">
  <div class="col-md-3 col-sm-12">
    {{ '::BlockUIEndPoints' | abpLocalization }}
  </div>
  <div class="col-md-9 col-sm-12">
    <!-- <p-chip styleClass="mr-2 primary-chip" *ngFor="let tag of emailTokens.value?.split(','); let tagIdx = index" [label]="tag"></p-chip>  -->
    <textarea
      type="text"
      rows="10"
      style="width: 100%"
      pInputTextarea
      class="p-inputtext-sm"
      [(ngModel)]="blockUIEndPoints.value"
    ></textarea>
  </div>
</div>
<div class="mt-3">
  <abp-button class="mt-3" (click)="saveSettings()">
    {{ 'AbpUi::Save' | abpLocalization }}
  </abp-button>
</div>