import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';
import { eAccountRouteNames } from '@volo/abp.ng.account/public';

export const ONLINE_PAYMENTS_ROUTE_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    useFactory: configureRoutes,
    deps: [RoutesService],
    multi: true,
  },
];

function configureRoutes(routesService: RoutesService) {
  return () => {
    routesService.add([
      {
        path: '/online-payments',
        name: '::OnlinePayments',
        iconClass: 'fas fa-credit-card',
        layout: eLayoutType.empty,
        order: 6,
      },
    ]);
  };
} 