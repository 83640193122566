import type { OrderVM } from './order/models';
import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CloverService {
  apiName = 'Default';
  

  decodeBarcodeByBarcode = (barcode: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>({
      method: 'GET',
      responseType: 'text',
      url: '/api/app/clover/DecodeBarcode',
      params: { barcode },
    },
    { apiName: this.apiName,...config });
  

  getPaymentDetailByDateAndTransactionNo = (date: string, transactionNo: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrderVM>({
      method: 'GET',
      url: '/api/app/clover/GetPaymentDetail',
      params: { date, transactionNo },
    },
    { apiName: this.apiName,...config });
  

  verifyPaymentsByDateAndTransactionNoAndLast4 = (date: string, transactionNo: string, last4: number, config?: Partial<Rest.Config>) =>
    this.restService.request<any, number>({
      method: 'GET',
      url: '/api/app/clover/VerifyPayments',
      params: { date, transactionNo, last4 },
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
