import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArticleDto, ArticleService, GetArticlesInput } from '@proxy/articles';
import { ScriptLoaderService, ScriptModel } from '../services/script.service';
import { StyleLoaderService, StyleModel } from '../services/style.service';
import { PublicService } from '@proxy/public';

@Component({
  selector: 'app-content-page',
  templateUrl: './content-page.component.html',
  styleUrls: ['./content-page.component.scss'],
  providers: [ScriptLoaderService, StyleLoaderService]
})
export class ContentPageComponent implements OnInit {

  page: ArticleDto = {} as ArticleDto;
  constructor(
    private route: ActivatedRoute,
    private pageService: PublicService,
    private scriptService: ScriptLoaderService,
    private styleService: StyleLoaderService
  ){

  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      
      if(params['slug']){
        this.pageService.getArticleBySlug(params['slug']).subscribe(page => {
        if(page){
          this.page = page;
          this.scriptService.load({
            name:'page-script-'+crypto.randomUUID(),
            content: this.page.script
          } as ScriptModel).subscribe(scriptResp => {

          });
          this.styleService.load({
            name: 'page-style-'+crypto.randomUUID(),
            content: this.page.styles
          } as StyleModel).subscribe(resp => {

          });
        }
      })
      }
      
   });
    
  }
}
