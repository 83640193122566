import { AuthGuard, PermissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventTicketComponent } from './event-ticket.component';

const routes: Routes = [
  {
    path: '',
    component: EventTicketComponent,
  },
  {
    path: 'ticket',
    component: EventTicketComponent,
  },
  {
    path: 'ticket/:id',
    component: EventTicketComponent,
  },
  {
    path: 'ticket/:id/:num',
    component: EventTicketComponent,
  },
  {
    path: 'ticket/:id/:num/:print',
    component: EventTicketComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventTicketRoutingModule {}
