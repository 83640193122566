import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GooglePlacesComponent } from './google-places.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [GooglePlacesComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports:[
    GooglePlacesComponent
  ]
})
export class GooglePlacesModule { }
