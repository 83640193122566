import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root',
})
export class DateTimeService {
  apiName = 'Default';
  utcToLocal(datetime:Date){
    return moment.utc(datetime).local().format()
  }

  toLocal(datetime:Date){
    return moment(datetime).local().format();
  }

  constructor(private restService: RestService) {}
}
