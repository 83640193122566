import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const SLOT_BOOKINGS_SLOT_BOOKING_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/slot-bookings',
        iconClass: 'fas fa-clock',
        name: '::Menu:SlotBookings',
        layout: eLayoutType.application,
        requiredPolicy: 'Mandir.SlotBookings',
      },
    ]);
  };
}
