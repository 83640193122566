<!-------------bannner------------>
<div class="banner-part"> <img src="/assets/images/donate-banner.jpg" alt="">
  <div class="container">
    <div class="wraper">
      <h1>{{ '::Receipts:Title' | abpLocalization }}</h1>
    </div>
  </div>
</div>

  <div class="container" style="padding: 10%" *ngIf="hasLoggedIn" [abpLoading]="uiBusy">
    <div class="row">
      <div class="col-12">
        <div *ngIf="memberInfo.isMember">
          <div class="row" >
            <div class="col-12 col-md-6">
              <h2 *ngIf="currentUserInfo">{{memberInfo.member.name}} {{memberInfo.member.surname}}</h2>
              <div>{{ '::Receipts:MembershipId' | abpLocalization }}: {{memberInfo.member.extraProperties.LegacyMembershipId}}</div>
              <div>{{memberInfo.member.addressLine1}}</div>
              <div>{{memberInfo.member.addressLine2}}</div>
              <div>{{memberInfo.member.addressLine3}} {{memberInfo.member.postalCode}}</div>
              <div>{{ '::Receipts:Phone' | abpLocalization }}: {{memberInfo.member.phoneNumber}}</div>
  
  
                <!-- {{memberInfo.member.addressLine1 | json}} -->
              <br><Br>
              
              <h3>{{ '::Receipts:AnnualReceipts' | abpLocalization }}</h3>
              <ul style="padding-left:0;">
                <li style="display: inline-block; margin-bottom: 20px" *ngFor="let year of previousYears">
                  <span 
                  (click)="getAnnualReceipt(getPaymentsByUser(memberInfo.payments, activeUserId)[0].id, year)"
                  class="btn btn-primary" style="margin-right: 10px; margin-left: 10px;">
                    <i class="fa cursor-pointer fa-download"></i>&nbsp; {{year}}
    
                  </span>
                </li>
              </ul>
            </div>
            <div class="col-12 col-md-6">
              <h5>{{ '::Receipts:DonationReceipts' | abpLocalization }}</h5>
              <div class="row">
                <div class="col-5"><strong>{{ '::Receipts:PaymentType' | abpLocalization }}</strong></div>
                <div class="col-2"><strong>{{ '::Receipts:Amount' | abpLocalization }}</strong></div>
    
                <!-- <div class="col-2"><strong>Year</strong></div> -->
                <div class="col-3"><strong>{{ '::Receipts:ReceivedDate' | abpLocalization }}</strong></div>
    
                
              </div>
              <div class="row" *ngFor="let payment of getPaymentsByUser(memberInfo.payments, activeUserId)">
    
                <div class="col-5">
                  <span (click)="getReceipt(payment.id)" class="fa cursor-pointer fa-download"></span> &nbsp;
                 
                  {{getPaymentType(payment.paymentTypeId)}}
                </div>
                <div class="col-2">${{payment.amount}}</div>
    
                <!-- <div class="col-2">{{payment.accountingYear}}</div> -->
                <div class="col-3">{{payment.creationTime | date : 'dd-MMM-yyyy'}}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <div class="row" *ngFor="let member of familyMembers">
                    <div class="col-12 col-md-6">
                      <!-- <h5>Info For:  <span>
                        {{member.name}}!
                      </span></h5> -->
                      <br><Br>
        
                      <h5>{{member.name}} ({{ '::Receipts:MembershipId' | abpLocalization }}: {{member.extraProperties.LegacyMembershipId}}) </h5>
                      {{ '::Receipts:MembershipReceipts' | abpLocalization }}
                      <ul style="padding-left:0;">
                        <li style="display: inline-block;" *ngFor="let payment of getPaymentsByUser(membershipPayments, member.id) | orderBy: 'accountingYear'">
                          <span (click)="getReceipt(payment.id)" class="btn btn-primary" style="margin-right: 5px; margin-left: 5px; margin-bottom: 10px;">
                            <i class="fa cursor-pointer fa-download" *ngIf="payment.id"></i>&nbsp;
        
                            <!-- <i class="fa fa-receipt" *ngIf="payment.id"></i> -->
                            {{payment.accountingYear}}
            
                          </span>
                        </li>
                      </ul>
                      
                    </div>
                    <div class="col-12 col-md-6">
                      <br><br>
                      <div class="row">
                        <div class="col-5"><strong>{{ '::Receipts:PaymentType' | abpLocalization }}</strong></div>
                        <div class="col-2"><strong>{{ '::Receipts:Amount' | abpLocalization }}</strong></div>
            
                        <div class="col-2"><strong>{{ '::Receipts:Year' | abpLocalization }}</strong></div>
                        <div class="col-3"><strong>{{ '::Receipts:ReceivedDate' | abpLocalization }}</strong></div>
            
                        
                      </div>
                      <div class="row" *ngFor="let payment of getPaymentsByUser(memberInfo.payments, member.id)">
            
                        <div class="col-5">
                          <span (click)="getReceipt(payment.id)" class="fa cursor-pointer fa-download"></span> &nbsp;
                 
                          {{getPaymentType(payment.paymentTypeId)}}</div>
                        <div class="col-2">${{payment.amount}}</div>
            
                        <div class="col-2">{{payment.accountingYear}}</div>
                        <div class="col-3">{{payment.creationTime | date}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
    
          </div>
        </div>
        <div class="row" *ngIf="!memberInfo">
          <div class="col-12">
            <div *ngIf="currentUserInfo" class="header-btn d-none d-sm-block ml-lg-4" style="margin-top: 15px">
              {{ '::Receipts:NoReceipts' | abpLocalization }}
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>



<p-dialog header="{{ '::Receipts:DialogTitle' | abpLocalization }}" [(visible)]="displayMessage">
  {{messageContent}}
</p-dialog>