import { AuthService, ConfigStateService, PermissionService } from '@abp/ng.core';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventSlotDto, EventSlotWithNavigationPropertiesDto } from '@proxy/event-slots';
import { PublicService } from '@proxy/public';
import { ScheduledEventDto } from '@proxy/scheduled-events';
import { SlotBookingDto, SlotBookingWithNavigationPropertiesDto } from '@proxy/slot-bookings';
import { IdentityUserService } from '@volo/abp.ng.identity/proxy';
import { filter, forkJoin, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-slot-booking',
  templateUrl: './slot-booking.component.html',
  styleUrls: ['./slot-booking.component.scss']
})
export class SlotBookingComponent implements OnInit{

  eventSlots: EventSlotWithNavigationPropertiesDto[] = [];
  events: any[]=[];
  myBookings:SlotBookingDto[]=[];
  myBookedEvents:string[]=[];
  currentUser:any;
  canValidate:boolean=false;
  myAngularxQrCode: string = environment.application.baseUrl+ '/slot-confirmation/';
  modalVisible:boolean=false;
  modalBusy:boolean=false;
  slotReservations:SlotBookingWithNavigationPropertiesDto[] = [];
  constructor(
    private service: PublicService,
    public authService: AuthService,
    private confirmationService: ConfirmationService,
    private configState: ConfigStateService,
    private activatedRoute: ActivatedRoute,
    private identityUserService: IdentityUserService,
    private permissionService: PermissionService,
    private toasterService: ToasterService

  )
  {

  }

  ngOnInit(): void {
    this.canValidate= this.permissionService.getGrantedPolicy('Mandir.App.ValidateSlotConfirmations');
    
    this.activatedRoute.params.subscribe(params => {
      
      if(params && params.userId){
        this.identityUserService.get(params.userId).subscribe(user => {
          this.currentUser = user;
          this.loadData();
        })
      }
      else{
        this.configState.getDeep$('currentUser').subscribe(user => {
          this.currentUser = user;
          this.loadData();
        });
      }
    })
    
    
  }

  showBookings(eventId:string){
    if(!this.canValidate){
      return;
    }
    // this.service.getBookingsByEventId(eventId).subscribe(result =>
    //   {
        
    //   })
  }

  showSlotBookings(eventSlotId:string){
    
    if(!this.canValidate){
      return;
    }
    this.service.getSlotBookingsByEventSlotId(eventSlotId).subscribe(result =>
      {
        this.modalVisible = true;
        this.slotReservations = result;
        
      })
  }

  loadData(){
    forkJoin([
      this.service.getEventSlots(),
      (this.authService.isAuthenticated ? this.service.getMySlotBookingsByUserId(this.currentUser.id) : of([]))
    ])
    .subscribe(result => {
      
      this.eventSlots = result[0];
      let events = result[0].map(e =>  e.scheduledEvent.id
      );
      this.events = [...new Set(events)];
      

        this.myBookings = result[1];
        var bookedSlots = result[1].map(e => e.eventSlotId);
        let myEvents = result[0].filter(e => bookedSlots.indexOf(e.eventSlot.id) > -1).map(e => e.scheduledEvent.id);
        this.myBookedEvents = [...new Set(myEvents)];
        this.preLoadSlot();
    })
  }

preLoadSlot(){
  let slotId = localStorage.getItem('slotId');
  localStorage.removeItem('slotId'); 

  
    if(slotId){
      let slot = this.eventSlots.filter(e => e.eventSlot.id == slotId);
      if(slot && slot.length > 0){
        this.bookSlot(slot[0]);
      }
    }
  
}

  isBookedEvent(slot:EventSlotWithNavigationPropertiesDto){
    return this.myBookedEvents.indexOf(slot.scheduledEvent.id) > -1;
  }

  isBookedSlot(slot:EventSlotWithNavigationPropertiesDto){
    
    return this.myBookings.map(e => e.eventSlotId).indexOf(slot.eventSlot.id) > -1;
  }

  getBooking(eventSlotId:string){
    return this.myBookings.filter(e=> e.eventSlotId == eventSlotId)[0];
  }

  getSlots(id:string){
    let bookedSlots = this.myBookings.map(e => e.eventSlotId);


    if(bookedSlots.length){
      let slots = this.eventSlots.filter(e => e.scheduledEvent.id == id);
      let hasBookings:boolean=false;
      slots.forEach(s => {
        if (this.myBookings.map(e => e.eventSlotId).indexOf(s.eventSlot.id) > -1){
          hasBookings = true;
        }
      })

      if(hasBookings){
      return this.eventSlots.filter(e => e.scheduledEvent.id == id && 
        (
        bookedSlots.indexOf(e.eventSlot.id) > -1))
        }
        else{
          return slots;
        }
    }
    

    return this.eventSlots.filter(e => e.scheduledEvent.id == id);
  }
  getEvent(id:string){
    return this.eventSlots.filter(e => e.scheduledEvent.id == id).map(e => e.scheduledEvent)[0];
  }

  getEventDescription(id:string){
    
    let event =  this.eventSlots.filter(e => e.scheduledEvent.id == id).map(e => e.scheduledEvent)[0]
    return event.description.replace(/\r\n/,'<br>');
  }

  removeSlot(slot:EventSlotWithNavigationPropertiesDto){
    if(!this.authService.isAuthenticated)
    {
      localStorage.setItem('AuthRedirectUri', '/event-slot');
      this.authService.navigateToLogin();
      return;
    }

    let id =this.getBooking(slot.eventSlot.id).id;

    this.confirmationService
      .info(
        'Are you sure you want to cancel your booking for '+ slot.eventSlot.name +'? ',
        'Cancel Booking',
      )
      .pipe(filter(status => status === Confirmation.Status.confirm))
      .subscribe(result => {
        this.service.removeSlotBookingByIdAndUserId(id, this.currentUser.id).subscribe(r => {
          
          this.loadData();
        })
      });
  }
  bookSlot(slot:EventSlotWithNavigationPropertiesDto){
    
    if(!this.authService.isAuthenticated)
    {
      //localStorage.setItem('AuthRedirectUri', '/event-slot');
      localStorage.setItem('slotId', slot.eventSlot.id);
      this.authService.navigateToLogin({
        returnUrl : '/event-slot' 
      });
      return;
    }
    
    let existing = this.myBookedEvents.indexOf(slot.scheduledEvent.id) > -1;
    if(existing){
      this.toasterService.success("You are already added to the virtual queue for "+ slot.scheduledEvent.name);
      return;
    }
    this.confirmationService
      .info(
        'Only one booking can be done for one event.  You are required to come to the Mandir only 5 mins prior to the scheduled time.  If you miss your booking time, you may have to wait for next availability.  Are you sure you want to book '+ slot.eventSlot.name +'? ',
        'Pooja Slot Booking',
      )
      .pipe(filter(status => status === Confirmation.Status.confirm))
      .subscribe(result => {
        this.service.addSlotBookingBySlotBookingDto({
          name: this.currentUser.name + ' ' + this.currentUser.surName,
          participantCount: 1,
          participantInfo: '{}',
          usedAt: null,
          eventSlotId:slot.eventSlot.id,
          identityUserId: this.currentUser.id,
          concurrencyStamp:''
        } as SlotBookingDto).subscribe(r => {
          this.toasterService.success("You are added to the virtual queue for "+ slot.eventSlot.name);
          this.loadData();
        })
      });

  }

}
