import { AuthService, ConfigStateService, HttpWaitService, PermissionService, SessionStateService } from '@abp/ng.core';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CampaignDto } from '@proxy/campaigns';
import { PublicService } from '@proxy/public';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { DonationCreateDto, DonationDto } from '@proxy/donations';
import { ActivatedRoute, Router } from '@angular/router';
import { HostInfoDto, UserInfoDto } from '@proxy/shared';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { AcceptPaymentComponent } from '../shared/accept-payment/accept-payment.component';
import { PaymentTypeDto } from '@proxy/payment-types';
import { forkJoin } from 'rxjs';
import { PaymentRequestService } from '@proxy/payment-requests';
import { IdentityUserDto } from '@volo/abp.ng.identity/proxy';
import { ScheduledEventDto } from '@proxy/scheduled-events';
import { EventTicketTypeDto } from '@proxy/event-ticket-types';
import * as moment from 'moment';
import { PaymentDto } from '@proxy/payments';
import { SettingsService } from '@proxy/settings';
import { SettingValue } from '@proxy/volo/abp/settings';
import { MultiLanguageCampaignDto } from '@proxy/public/models';

@Component({
  selector: 'app-user-donation',
  templateUrl: './donation.component.html',
  styleUrls: ['./donation.component.scss'],
  providers:[ConfirmationService]
})
export class DonationComponent implements OnInit{
  @ViewChild('payment') payment: AcceptPaymentComponent;
  //@ViewChild('stripe') stripe: StripeComponent;
  currentUser$ = this.configState.getDeep$('currentUser');
  @Input() userId?: string;
  @Input() campaignId?: string;
  @Input() paymentType?: string;
  @Output() closeModal = new EventEmitter<any>();
  @Input() currentPayments: PaymentDto[] = [];
  currentUser: any;
  paymentToken:string;
  campaign: CampaignDto={} as CampaignDto;
  multiLanguageCampaign: MultiLanguageCampaignDto;
  DonationForm!: FormGroup;
  items:MenuItem[]=[];
  activeStep: string='Personal';
  confirmationMessage:string="";
  stepActiveIndex:number=0;
  steps:string[]=['Personal','Payment','Confirmation'];
  hideAmountInput:boolean=true;
  newUser:any;
  donorId:string = '00000000-0000-0000-0000-000000000000';
  donor:UserInfoDto = {} as UserInfoDto;
  paymentTypes:PaymentTypeDto[]=[];
  selectedPaymentType: PaymentTypeDto;
  allowPaymentCategories:boolean=false;
  canAddNewMembers:boolean=false;
  isDonation:boolean=true;
  isPaymentRequest:boolean=false;
  paymentRequestId:string;
  ref:string;
  amt:string;
  mode:string;
  date:string;
  comments:string;
  events: ScheduledEventDto[] = [];
  selectedEvent:string;
  ticketTypes:EventTicketTypeDto[] = [];
  selectedTicketType:string;
  selectedTicketQuantity:number;
  allowOfflinePayments:boolean=false;
  bookingId: any;
  membershipDonationTypeId: any;
  currentAccountYear: number;
  constructor(
    public authService: AuthService,
    private publicService: PublicService,
    private formBuilder: FormBuilder,
    private configState: ConfigStateService,
    private router:Router,
    private activatedRoute: ActivatedRoute,
    private permissionService: PermissionService,
    private settingService: SettingsService,
    private sessionStateService: SessionStateService
  ){

    this.allowPaymentCategories = this.permissionService.getGrantedPolicy('Mandir.App.AllowPaymentCategories');
    this.canAddNewMembers = this.permissionService.getGrantedPolicy('Mandir.App.AddNewMembers');
    this.allowOfflinePayments = this.permissionService.getGrantedPolicy('Mandir.App.AllowOfflinePayments');

    this.activatedRoute.queryParams.subscribe(params => {
      
      if(params['ref']){
        this.ref = params['ref'];
      }
      if(params['amt']){
        this.amt = params['amt'];
      }
      if(params['mode']){
        this.mode = params['mode'];
      }
      if(params['date']){
        this.date = params['date'].trim();
      }
      if(params['comments']){
        this.comments = params['comments'].trim();
      }
      if(params['bookingId']){
        this.bookingId = params['bookingId'];
      }
    });

    this.items = [{label: 'Personal'},{label: 'Payment'}];
    if((this.userId!=undefined|| window.location.href.includes('donate'))){
      this.items.push({label: 'Confirmation'});
    }
  }

  setPaymentType(){
    
    if(this.membershipDonationTypeId && this.membershipDonationTypeId == this.DonationForm.value.PaymentTypeId){
      let existingPayments = this.currentPayments.filter(p => p.paymentTypeId == this.DonationForm.value.PaymentTypeId);
      if(existingPayments.length){
        // check if the there is an existing payment for this year
        let existingPayment = existingPayments.filter(p => this.getMembershipYear(p.paymentDate) == this.currentAccountYear);
        console.log(existingPayment);
        if(existingPayment.length){
          alert('You have already paid membership fee for this year on '+ moment(existingPayment[0].paymentDate).format('MMM DD, YYYY')+'. Are you sure you want to add another membership payment?');
          return;
        }
      }
    }
    

    var paymentType = this.paymentTypes.filter(p => p.id === this.DonationForm.value.PaymentTypeId)[0];
    if(paymentType){
      this.selectedPaymentType = paymentType;
      if(paymentType.name.toLowerCase() === 'event'){
        this.publicService.getEventwithTickets().subscribe(resp => {
          if(resp){
            this.events = resp;
          }
        });
      }
    }
  }

  getMembershipYear(date: string) {
    const dt = moment(date);
    const calendarYear = parseInt(dt.format('YYYY'));
    
    // If date is before April 1st of the calendar year, it belongs to the previous membership year
    // which started on April 1st of the previous year
    if (dt.isBefore(moment(`${calendarYear}-04-01`))) {
      return calendarYear;
    }
    
    // If date is on or after April 1st, it belongs to the next membership year
    // which will end on March 31st of the next year
    return calendarYear + 1;
  }

  setEvent(){
    this.publicService.getTicketTypesByEventIdAndIsActive(this.selectedEvent, false).subscribe(resp => {
      this.ticketTypes = resp;
    });
  }

  setQuantity(){
    var ticketType = this.ticketTypes.find(x => x.id == this.selectedTicketType);
    if(ticketType){
      this.DonationForm.patchValue({
        Amount: ticketType.price * this.selectedTicketQuantity
      })
    }
  }
  getPaymentType(id:string){
    return this.paymentTypes.filter(p => p.id ===id)[0];
  }
  ngOnInit(): void {
    this.currentUser$.subscribe(val => {
      this.currentUser = val;
    });
    this.activatedRoute.params.subscribe((result:any) => {
      if(result.paymentRequestId){
        if(!this.authService.isAuthenticated){
          this.authService.navigateToLogin({
            "returnUrl":'/pay/'+result.paymentRequestId
          })
        }
        this.isPaymentRequest = true;
        this.paymentRequestId = result.paymentRequestId;
        forkJoin([this.publicService.getPaymentTypesByPayeeId(result.userId||this.userId),
          this.publicService.getPaymentRequestByPaymentRequestId(result.paymentRequestId)
        ])
        .subscribe(
          resp => {
            this.paymentTypes = resp[0];
            if(resp && resp[1]){
              if(resp[1].status !== 'New'){
                alert('This payment link is not valid.  Please contact Admin.');
                return;
              }
              this.selectedPaymentType = this.paymentTypes.filter(p => p.id == resp[1].paymentTypeId)[0];


              this.createForm(resp[1].amount, resp[1].comments);
              this.moveStep(1);
            }
          }
        )
        //this is a payment Request, lets get the details...
          return;
      }
      let campaignId =  (result.campaignId) ? result.campaignId : '00000000-0000-0000-0000-000000000000';
      
      this.isDonation = (campaignId != '00000000-0000-0000-0000-000000000000');
      
      if(result.userId||this.userId){
        if((result.userId||this.userId) != '00000000-0000-0000-0000-000000000000'){
          this.publicService.getUserInfoById(result.userId||this.userId).subscribe(resp => {
            if(resp){
              this.donorId = result.userId||this.userId;
              this.donor = resp;
            }
          })
        }
        
      }

      // Get the current language
      const currentLang = this.sessionStateService.getLanguage();
      
      // If multilingual is enabled and we have a non-English language, use the translation API
      if (currentLang && currentLang !== 'en') {
        forkJoin([
          this.publicService.getPaymentTypesByPayeeId(result.userId||this.userId),
          this.publicService.getCampaignWithTranslationsByIdAndLanguages(campaignId, [currentLang])
        ])
        .subscribe(resp => {
          this.paymentTypes=resp[0];
          if(result.paymentType){
            this.selectedPaymentType = this.paymentTypes.filter(p => p.name.toLowerCase() === result.paymentType.toLowerCase())[0];
          }
          else{
            this.selectedPaymentType = this.paymentTypes.filter(p => p.name.toLowerCase() === 'general donation')[0];
          }
          
          this.multiLanguageCampaign = resp[1];
          
          // Use translated campaign if available for the current language
          if (this.multiLanguageCampaign.translations && 
              this.multiLanguageCampaign.translations[currentLang]) {
            const translatedCampaign = this.multiLanguageCampaign.translations[currentLang];
            this.campaign = {
              ...this.multiLanguageCampaign.original,
              name: translatedCampaign.name,
              description: translatedCampaign.description
            };
          } else {
            this.campaign = this.multiLanguageCampaign.original;
          }
          
          this.createForm(this.amt ? parseInt(this.amt): 51,'');
        });
      } else {
        // Use the original API for English
        forkJoin([
          this.publicService.getPaymentTypesByPayeeId(result.userId||this.userId),
          this.publicService.getCampaignByCampaignId(campaignId)
        ])
        .subscribe(resp => {
          this.paymentTypes=resp[0];
          if(result.paymentType){
            this.selectedPaymentType = this.paymentTypes.filter(p => p.name.toLowerCase() === result.paymentType.toLowerCase())[0];
          }
          else{
            this.selectedPaymentType = this.paymentTypes.filter(p => p.name.toLowerCase() === 'general donation')[0];
          }
          this.campaign = resp[1];
          this.createForm(this.amt ? parseInt(this.amt): 51,'');
        });
      }
    }) 
    
    this.settingService.getSettingsByNames("Mandir.CurrentAccountYear,Mandir.MembershipDonationTypeId").subscribe(
      setting => {
        if(setting.length){
          this.currentAccountYear = parseInt(this.getSetting(setting, 'Mandir.CurrentAccountYear').value);
          this.membershipDonationTypeId = this.getSetting(setting, 'Mandir.MembershipDonationTypeId').value;

        }
      })
  }
  getSetting(items: SettingValue[], name: string) {
    let values = items.filter(item => {
      return item.name == name;
    });
    if (!values.length) {
      return { name: name } as any;
    }
    return values[0];
  }
  createForm(amount:number, comments: string){
    
    if(this.authService.isAuthenticated || this.donor.id){
      
      this.DonationForm = this.formBuilder.group({
        // CompanyName: [''],
        PaymentTypeId: [this.selectedPaymentType.id, Validators.required],
        Amount: [amount,[Validators.required, Validators.min(1)]],
        Comments: [comments],
      })
    }
    else{
      this.DonationForm = this.formBuilder.group({
        Name: ['',[Validators.required]],
        SurName: ['',[Validators.required]],
        PhoneNumber: ['',[Validators.required, RxwebValidators.mask({mask:'(999)-999 9999' })]],
        Email: ['',[Validators.required, Validators.email]],
        CompanyName: [''],
        PaymentTypeId: [this.selectedPaymentType.id, Validators.required],
        Amount: [51,[Validators.required, Validators.min(1)]],
        Comments: [''],
      })
    }
    
  }

  setValue(value:string){
    this.DonationForm.patchValue({
      Amount: value
    })
    //this.DonationForm.controls[control].setValue(value);
  }

  moveStep(num:number){

    if(num > -1){
      Object.keys(this.DonationForm.controls).forEach(field => {
        const control = this.DonationForm.controls[field];
        control.updateValueAndValidity();
      });
    }
    if(this.DonationForm.valid){
      if(this.authService.isAuthenticated || this.donor.id){
        this.newUser = (this.donor?.id ? this.donor :  this.currentUser);
        this.stepActiveIndex = this.stepActiveIndex + num; 
        this.activeStep = this.steps[this.stepActiveIndex];
      }
      else{
        this.publicService.confirmUserByInputUserAndFamilyHostIdAndUpdateProperties({
          name: this.DonationForm.value.Name,
          surName:this.DonationForm.value.SurName,
          phoneNumber: this.DonationForm.value.PhoneNumber,
          email: this.DonationForm.value.Email,
          addressLine1:'3259 Regional Road 25',
          addressLine2:'Oakville',
          addressLine3:'ON',
          postalCode:'L6M 4J3',
          paid:false
        } as HostInfoDto, 'Host', false).subscribe(usr => {
          
          this.newUser = usr;
          this.stepActiveIndex = this.stepActiveIndex + num; 
          this.activeStep = this.steps[this.stepActiveIndex];
        })
      }
      
      
    }

  }

  donationAmountButton(amount){
    if(this.DonationForm.value.Amount == amount){
      return 'btn-outline-primary ml30'
    }
    return 'btn-primary';
  }
  // async onSubmit(){

  //     if (!this.stripe || this.stripe.form.invalid) {
  //       return;
  //     }
  //     if(this.DonationForm.invalid){
  //       return;
  //     }

  //     const { paymentIntent } = await this.stripe.stripe.confirmPayment({
  //       elements: this.stripe.elements,

  //       confirmParams: {
  //         return_url: `${window.location.origin}/return.html`,
  //       },
  //       redirect: 'if_required'
  //     });

  //     console.log(paymentIntent);
  //     if (paymentIntent) {
  //       this.submitDonation(paymentIntent);
  //     }
  // }

  
  onSubmit(){
    if(this.payment.paymentMode == 'Online' && !this.payment.paymentIntent)
    return;

    
    let model = {
      paymentTypeId: this.DonationForm.value.PaymentTypeId,
      companyName:this.DonationForm.value.CompanyName,
      amount:this.DonationForm.value.Amount,
      comments: this.DonationForm.value.Comments

    } as DonationCreateDto;

    model.campaignId = this.campaign.id;
    model.paymentDate = this.date || this.payment.paymentIntent.transactionDate;
    if(this.authService.isAuthenticated || this.donor.id){
      model.payeeId = (this.donor.id ? this.donor.id :  this.currentUser.id);
      model.ticketTypeId = this.selectedTicketType;
      model.quantity = this.selectedTicketQuantity;
    }
    else{
      model.name = this.DonationForm.value.Name;
      model.surName= this.DonationForm.value.SurName;
      model.phoneNumber=this.DonationForm.value.PhoneNumber;
      model.email = this.DonationForm.value.Email;
      model.ticketTypeId = this.selectedTicketType;
      model.quantity = this.selectedTicketQuantity;
    }

    let modelWithPayment = {...model, paymentToken: JSON.stringify(this.payment.paymentIntent),  paymentIntent: this.payment.paymentIntent.paymentIntent};
    this.publicService.addDonationByModel(modelWithPayment).subscribe(donation => {
      
      if(donation){
        if(this.isPaymentRequest){
          this.publicService.updatePaymentRequestByPaymentRequestIdAndPaymentId(this.paymentRequestId, donation.id).subscribe(r => {

          })
        }
        this.stepActiveIndex = 2; 
        this.activeStep = this.steps[this.stepActiveIndex];
      
        if(this.bookingId){
          this.router.navigateByUrl('/manage-bookings?bookingId='+this.bookingId);
        }
        try{
          if(this.closeModal){
            this.closeModal.emit();
          }
        }catch{}
      }
    })
  }

  sendRequest(e){

  }

}
