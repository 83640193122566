import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventTicketTypeDto } from '@proxy/event-ticket-types';
import { PublicService } from '@proxy/public';
import { ScheduledEventDto } from '@proxy/scheduled-events';

@Component({
  selector: 'app-event-ticket-admin',
  templateUrl: './event-ticket-admin.component.html',
  styleUrls: ['./event-ticket-admin.component.scss']
})
export class EventTicketAdminComponent implements OnInit{

  ticketTypeId:string;
  tickets:EventTicketTypeDto[]=[];
  events:ScheduledEventDto[] = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private publicService: PublicService
  ){

  }
  ngOnInit(): void {
    this.getEvents();
    this.activatedRoute.params.subscribe(result => {
      this.ticketTypeId = result.typeId;
      this.getTickets(this.ticketTypeId);
    });
    
  }

  getEvents(){
    this.publicService.getTicketedEvents()
    .subscribe(result => {
      this.events = result;
    })
  }

  getTickets(ticketTypeId:string){
    this.publicService.getTicketTypesByEventIdAndIsActive(ticketTypeId, false)
    .subscribe(result => {
      this.tickets=result;
    })
  }
}
