<!-------------bannner------------>
<div class="banner-part">
  <img src="/assets/images/donate-banner.jpg" alt="" />
  <div class="container">
    <div class="wraper">
      <h1>{{ '::EventList:Title' | abpLocalization }}</h1>
    </div>
  </div>
</div>
<!-------------banner------------>
<!-- ======== Hero Area End ========== -->
<!-- <h1 class="text-center" style="padding: 60px 0 0px 0">Upcoming Mandir Schedule</h1> -->
<div class="container">
  <!-- <h1 class="text-center" style="padding-top:60px">Upcoming Mandir Schedule</h1> -->
  <div
    *abpPermission="'Mandir.App.AllowGoogleCalendarSync'"
    class="header-btn text-lg-end d-none d-sm-block ml-lg-4"
  >
    <a (click)="loadGoogleCalendar()" class="btn-circle btn-default btn" role="button"
      ><i class="fa fa-calendar" aria-hidden="true"></i> {{ '::EventList:SyncCalendar' | abpLocalization }}</a
    >
  </div>
  <div style="margin-top: 40px; padding: 20px 40px; border: 1px solid black; border-radius: 20px">
    <h2>{{ '::EventList:FestivalDaysTitle' | abpLocalization }}</h2>
    <p style="font-size: 1.5em">
      {{ '::EventList:FestivalDaysExplanation' | abpLocalization }} <br />
      <br />
    </p>
  </div>
  <p-tabView>
    <p-tabPanel header="{{ '::EventList:ListView' | abpLocalization }}">
      <div class="card-grid">
        <!------->
        <div *ngFor="let item of items">
          <div class="bot-card">
            <div class="image-s">
              <img [src]="item.imageUrl" class="img-fluid" alt="Vaishno" />
            </div>
            <div class="bot-card-ps">
              <div class="Donate-s" style="height: 300px; overflow: hidden">
                <div class="d-flex align-items-center">
                  <i class="fa fa-calendar me-1 primary-color"
                  style="border-radius: 0; margin-right: 20px; margin-bottom: 7px"></i>
                  <span
                    >
                    <h3 *ngIf="!item.id.includes('00000000-0000')">{{ item.date | date : 'MMM dd, yyyy' }}</h3>
                    <h3 *ngIf="item.id.includes('00000000-0000')">{{ item.name }}</h3>
                    </span
                  >
                </div>
                <div
                  class="d-flex align-items-center"
                  style="margin-left: 40px; margin-top: -15px; margin-bottom: 20px"
                >
                  <span>
                    {{ item.startsAt | date : 'hh:mm a' }} to
                    {{ item.endsAt | date : 'hh:mm a' }}</span
                  >
                </div>
                <h4>{{ item.name }}</h4>
                <p [innerHTML]="item.description"></p>
              </div>

              <div class="button-more small-button-more d-flex justify-content-between">
                <span *ngIf="item.id.includes('00000000-0000')">&nbsp;</span>
                <a *ngIf="!item.id.includes('00000000-0000')" class="btn btn-primary" (click)="showEventDetails(item)" role="button"
                  >{{ '::EventList:Details' | abpLocalization }}</a
                >
                <a
                  class="btn btn-primary"
                  *ngIf="item.hasSlots"
                  [routerLink]="['/event-slot']"
                  role="button"
                  >{{ '::EventList:JoinQueue' | abpLocalization }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel header="{{ '::EventList:CalendarView' | abpLocalization }}">
      <div class="row">
        <div class="col-12 text-end">
          <a
            rel="noopener"
            target="_blank"
            class="btn btn-primary"
            href="https://calendar.google.com/calendar/ical/c_aedb1a89c0e33b1c2c5bf2fc7ef21f87f0fb280ce46fabdfaeb18bdd2215147b%40group.calendar.google.com/public/basic.ics"
            >{{ '::EventList:AddToCalendar' | abpLocalization }}</a
          >
        </div>
      </div>
      <iframe
        src="https://calendar.google.com/calendar/embed?src=c_aedb1a89c0e33b1c2c5bf2fc7ef21f87f0fb280ce46fabdfaeb18bdd2215147b%40group.calendar.google.com&ctz=America%2FToronto"
        style="border: 0; width: 100%; height: 100%; min-height: 600px"
        width="800"
        height="600"
        frameborder="0"
        scrolling="no"
      ></iframe>
    </p-tabPanel>
  </p-tabView>
</div>
