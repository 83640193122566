/// <reference types="@types/googlemaps" />

import { AuthService, ConfigStateService } from '@abp/ng.core';
import { getPasswordValidators, ToasterService } from '@abp/ng.theme.shared';
import { AfterViewInit, Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { of, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { AccountService, RecaptchaService, RECAPTCHA_STRATEGY, getRedirectUrl } from '@volo/abp.ng.account/public';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { AccountExtnService } from '@proxy/account';
// import {  } from '@types/googlemaps';
const { maxLength, required, email } = Validators;

@Component({
  selector: 'abp-register',
  templateUrl: './register.component.html',
  providers: [RecaptchaService],
})
export class RegisterComponent implements OnInit, AfterViewInit {
  @ViewChild('recaptcha', { static: false }) recaptchaRef:any;
  @ViewChild('addresstext') addresstext: any;

  adressType: string = 'geocode';
  place: google.maps.places.PlaceResult;

  //recaptchaRef: ElementRef<HTMLDivElement>;

  form: UntypedFormGroup;

  inProgress: boolean;

  isSelfRegistrationEnabled = true;

  protected fb: UntypedFormBuilder;
  protected accountService: AccountExtnService;
  protected toasterService: ToasterService;
  protected configState: ConfigStateService;
  protected authService: AuthService;
  protected recaptchaService: RecaptchaService;

  constructor(protected injector: Injector) {
    this.fb = injector.get(UntypedFormBuilder);
    this.accountService = injector.get(AccountExtnService);
    this.toasterService = injector.get(ToasterService);
    this.configState = injector.get(ConfigStateService);
    this.authService = injector.get(AuthService);
    this.recaptchaService = injector.get(RecaptchaService);
  }

  ngOnInit() {
    this.isSelfRegistrationEnabled =
      (
        (this.configState.getSetting('Abp.Account.IsSelfRegistrationEnabled') as string) || ''
      ).toLowerCase() !== 'false';
    if (!this.isSelfRegistrationEnabled) {
      this.toasterService.warn(
        {
          key: 'AbpAccount::SelfRegistrationDisabledMessage',
          defaultValue: 'Self registration is disabled.',
        },
        null,
        { life: 10000 },
      );
      return;
    }

    this.form = this.fb.group({
      name: ['', [required, maxLength(255)]],
      surname:['', [required, maxLength(255)]],
      //username: ['', [required, maxLength(255)]],
      password: ['', [required, ...getPasswordValidators(this.injector)]],
      email: ['', [required, email]],
      phoneNumber: ['',[required, RxwebValidators.mask({mask:'(999)-999 9999' })]]
    });
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
      this.recaptchaService.setStrategy(
        RECAPTCHA_STRATEGY.Register(this.configState, this.recaptchaRef.nativeElement),
      );
  }


  getPlaceAutocomplete() {
 
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
        {
            componentRestrictions: { country: ['CA','US'] },
            types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
        });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
        this.place = autocomplete.getPlace();
    });
}

getAddress($event):any{
  let address:any = {};
  let street_number =  $event.address_components.filter(a => a.types.indexOf('street_number') > -1);
  let street_name =  $event.address_components.filter(a => a.types.indexOf('route') > -1);
  let subpremise =  $event.address_components.filter(a => a.types.indexOf('subpremise') > -1);
  
  let city =  $event.address_components.filter(a => a.types.indexOf('locality') > -1);

  let province =  $event.address_components.filter(a => a.types.indexOf('administrative_area_level_1') > -1);

  let postal_code =  $event.address_components.filter(a => a.types.indexOf('postal_code') > -1);
  

  if(street_number.length){
    let addressLine1 = street_number[0].short_name;
          if(street_name.length){
      addressLine1 = addressLine1 + ' ' + street_name[0].short_name
    }

    if(subpremise.length){
      addressLine1 = addressLine1 + ' ' + subpremise[0].short_name
    }
    address.AddressLine1 = addressLine1;
  }

  if(city.length){
    address.AddressLine2 = city[0].short_name;
  }
  if(province.length){
    address.AddressLine3 = province[0].short_name;
  }
  if(postal_code.length){
    address.PostalCode = postal_code[0].short_name;
  }
  return address;
}

  onSubmit() {
    
    if (this.form.invalid) return;

    if(!this.place) {
      return;
    }

    this.inProgress = true;

    const newUser = {
      userName: this.form.get('email').value,
      password: this.form.get('password').value,
      emailAddress: this.form.get('email').value,
      appName: 'Angular',
      name: this.form.get('name').value,
      surname: this.form.get('surname').value,
      phoneNumber: this.form.get('phoneNumber').value,
      extraProperties: this.getAddress(this.place)
    };
    debugger;
    (this.recaptchaService.isEnabled ? this.recaptchaService.getVerificationToken() : of(undefined))
      .pipe(
        switchMap(captchaResponse =>
          this.accountService.register({ 
            ...newUser, 
            captchaResponse
          }).pipe(
            switchMap(() =>
              this.authService.login({
                username: newUser.userName,
                password: newUser.password,
                redirectUrl: getRedirectUrl(this.injector) || '/',
              }),
            ),
            catchError(err => {
              this.recaptchaService.reset();
              this.toasterService.error(
                err.error?.error_description ||
                  err.error?.error?.message ||
                  'AbpAccount::DefaultErrorMessage',
                null,
                { life: 7000 },
              );
              return throwError(err);
            }),
          ),
        ),

        finalize(() => (this.inProgress = false)),
      )

      .subscribe();
  }
}
