import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicService } from '@proxy/public';
import { TicketInfoDto } from '@proxy/ticket-sales';
import { QRCodeComponent } from 'angular2-qrcode';
import * as moment from 'moment';
import { DateTimeService } from '../services/date-time.service';

@Component({
  selector: 'app-event-ticket',
  templateUrl: './event-ticket.component.html',
  styleUrls: ['./event-ticket.component.scss']
})
export class EventTicketComponent implements OnInit {
  moment = moment;
  myAngularxQrCode: string = "";
  ticketId: string = "";
  ticketInfo: TicketInfoDto = {} as TicketInfoDto;
  tickets: TicketInfoDto[] = [];
  num: number = 0;
  counter: number[] = [1];
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly publicService: PublicService,
    private readonly confirmService: ConfirmationService,
    public readonly dateTimeService: DateTimeService,
    public readonly router: Router
  ) {

  }

  showTicket(ticket){
    this.router.navigate(['/ticket/'+ticket.id]);
  }
  isTicketUsed(item) {
    var currentUsed = this.ticketInfo.ticketUsed;
    if (!currentUsed) {
      return false;
    }
    var usedArray = JSON.parse(currentUsed);
    return usedArray.indexOf(item) > -1;
  }


  markAllTicketsUsed() {
    
    this.confirmService.warn('You are about to mark all tickets as used.  Are you sure you want to proceed?', 'Please Confirm').subscribe(result => {
      if (result == Confirmation.Status.confirm) {
        this.publicService.useTicketByTicketIdAndIds(this.ticketId, JSON.stringify(this.counter)).subscribe(result => {
          this.loadData(result);
        })
      }
    })

  }
  markTicketUsed(item) {
    
    this.confirmService.warn('You are about to mark this tickets as used.  Are you sure you want to proceed?', 'Please Confirm').subscribe(result => {
      if (result == Confirmation.Status.confirm) {

        var currentUsed = this.ticketInfo.ticketUsed;

        var usedArray = currentUsed ? JSON.parse(currentUsed) : [];
        usedArray.push(item);
        this.publicService.useTicketByTicketIdAndIds(this.ticketId, JSON.stringify(usedArray)).subscribe(result => {
          this.loadData(result);
        })
      }
    });
  }

  loadData(result: TicketInfoDto) {
    this.myAngularxQrCode = 'https://vaishnodevimandir.ca/ticket/' + result.id;
    this.ticketInfo = result;
    if (this.ticketInfo.quantity) {
      this.counter = [];
      let totalStubs = this.ticketInfo.quantity * this.ticketInfo.ticketType.stubsIncluded;
      for (let i = 0; i < totalStubs; i++) {
        if (this.num == 0 || this.num == i + 1) {
          this.counter.push(i + 1);

        }

      }
    }
  }

  setPrintout(hide){
    if(hide){
      var navBars = document.getElementsByClassName('navbar');
            for(let i =0; i<navBars.length;i++){
              document.getElementsByClassName('navbar')[i].classList.add('d-none');

            }
            document.getElementsByClassName('banner-part')[0].classList.add('d-none');
            document.getElementsByClassName('footer')[0].classList.add('d-none');

            document.getElementsByClassName('footer-copyright')[0].classList.add('d-none');
    }
    else{
      var navBars = document.getElementsByClassName('navbar');
      for(let i =0; i<navBars.length;i++){
        document.getElementsByClassName('navbar')[i].classList.remove('d-none');

      }
      document.getElementsByClassName('banner-part')[0].classList.remove('d-none');
      document.getElementsByClassName('footer')[0].classList.remove('d-none');

      document.getElementsByClassName('footer-copyright')[0].classList.remove('d-none');
    }
    
  }
  ngOnInit(): void {
    
    this.activatedRoute.params.subscribe(
      result => {        
        this.ticketId = result.id;
        this.num = result.num ? result.num : 0;
        if(this.ticketId){
          this.publicService.getTicketDetailsByTicketId(this.ticketId).subscribe(result => {
            
            this.loadData(result);
  
          })
        }
        else{
          this.publicService.getTickets().subscribe(result => {
            
            this.tickets = result;
          })
        }
        if(result.print){
          setTimeout(()=>{
            window.print();
            this.setPrintout(true);
            //this.setPrintout(false);
          },500);
        }
      }
    )
  }

  getTime(datetime){
    return this.dateTimeService.toLocal(datetime);
  }

  getTicketNumber(number:string, index:number){
    if(!number)
      return 'not assigned';
    if(index == null)
      return 'not assigned';

      let numbers = number.trim().split(',');
      if(numbers.length < index+1 )
      return 'not assigned';

      return numbers[index].trim();

  }

}
