<!-------------Wrapper------------>
<div class="wrapper">
    <app-header 
        [hasLoggedIn]="hasLoggedIn"
        [currentUser$]="currentUser$"
        [resourceLinks]="resourceLinks"
        (loginClick)="login()"
        (logoutClick)="logout()">
    </app-header>
    
    <!--====================  End of header area  ====================-->
    <router-outlet></router-outlet>
    
    <app-footer></app-footer>
</div>
<!-------------Wrapper------------>
