import { ConfigStateService, CurrentUserDto, PermissionService } from '@abp/ng.core';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SettingsService } from '@proxy/settings';
import { SettingValue } from '@proxy/volo/abp/settings';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ManidrSettingService {
  settings: SettingValue[] = [];
  apiUrl: string;
  currentUser: CurrentUserDto = this.configState.getOne('currentUser');
  isClientUser:boolean=false;
  isAdminUser:boolean=false;
  isExternalUser:boolean=false;
  isInternalUser: boolean=false;
  hasNDServiceAccountAccess:boolean=false;
  constructor(
    private http: HttpClient,     
    private configState: ConfigStateService,
    private permissionService: PermissionService,

    ) {}

  hasRole(roleName:string){
    return this.currentUser && this.currentUser.roles && this.currentUser.roles.length && this.currentUser.roles.filter(r => r.toLowerCase() == roleName.toLowerCase()).length > 0
  }
  public initliaze(): Promise<any> {
    return new Promise((resolve, reject) => {
      const subdomain =
        window.location.host.split('.').length > 2 ? window.location.host.split('.')[0] + '.' : '';
      let domain = environment.apis.default.url.replace('{0}.', subdomain);
      this.apiUrl = domain;

      var url =
        environment.apis.default.url.replace('{0}.', subdomain) + '/api/app/settings/mandir-settings';
      this.http.get(url).subscribe(
        response => {
          this.settings = response as any[];
          
          let blockUIEndPoints = this.settings.find(s => s.name == 'Mandir.BlockUIEndPoints');
          if(blockUIEndPoints){
            (window as any).blockUIEndPoints = blockUIEndPoints.value.split(',');
          }
          //always call resolve method, because it will freeze your app.
          resolve(true);
          
        },
        err => {}
      );
    });
  }

  setCurrentUser(user){
    
    this.currentUser = user;

    this.isAdminUser = this.hasRole('admin') || this.hasRole('tenantAdmin');
    this.isExternalUser = this.hasRole('external');
    this.isInternalUser = this.hasRole('internal');
    this.isClientUser = this.hasRole('client') && !this.isExternalUser && !this.isInternalUser && !this.isAdminUser;

  }
  getSettings(): SettingValue[] {
    return this.settings;
  }

  //get the setting value by name
  getSetting(name: string): string {
    var setting = this.settings.find(x => x.name === name);
    if(setting){
      return setting.value;
    }
    return null;
  }

  getLogoUrl(): string {
    return this.getSetting('Mandir.LogoURL');
  }

  getBackgroundUrl(): string {
    return this.getSetting('Mandir.BackgroundURL');
  }

  getApiUrl(): string {
    return this.apiUrl;
  }

  // for getting the updated value for showing the Doughnut Chart
  public showGraphDataDetails: any = [];
  private graphDisplaySource = new BehaviorSubject(this.showGraphDataDetails);
  currentGraphValue = this.graphDisplaySource.asObservable();
  changeGraphValue(value: string) {
    this.graphDisplaySource.next(value);
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
    
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}
}
