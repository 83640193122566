<div class="d-flex align-items-center main account-layout">
  <div class="container">
    <div class="row">
      <div class="col mx-auto" style="max-width: 600px">
            <div class="text-center mb-4">
              <img [src]="logoUrl" alt="{{ '::Logo' | abpLocalization }}" class="img-fluid" style="max-height: 100px;">
            </div>
            <div class="card styleCard">
                <div class="card-body">
            <router-outlet></router-outlet>
            </div>
            </div>
      </div>
    </div>
  </div>
</div> 