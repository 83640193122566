import { LocalizationService, PermissionService } from '@abp/ng.core';
import { ConfirmationService } from '@abp/ng.theme.shared';
import { Component } from '@angular/core';
import { SettingsService } from '@proxy/settings';
import { SettingValue } from '@proxy/volo/abp/settings';
import { MessageService } from 'primeng/api';
import { MandirSettingValue } from './setting.model';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss'],
  providers: [MessageService]
})
export class SettingComponent {
  message: string;

  original: SettingValue[] = [];
  settings: string[] = [
    'MandirSecure.TestEmail',
    'MandirSecure.TestPhone',
    'Mandir.AnnualMembershipFee',
    'MandirSecure.EventsToAvoid',
    'Mandir.CurrentAccountYear',
    'Mandir.MembershipDonationTypeId',
    'MandirSecure.ReceiptHtml',
    'MandirSecure.EventNotificationPhoneNumbers',
    'MandirSecure.AnnualReceiptHtml',
    'MandirSecure.MembershipReceiptHtml',
    'MandirSecure.NonDonationReceiptHtml',
    'Mandir.BlockUIEndPoints',
    'Mandir.LogoURL',
    'Mandir.BackgroundURL',
    'Mandir.CSSOverride'
  ];
  
  currentAccountingYear:SettingValue = { name: 'Mandir.CurrentAccountYear' } as any;
  membershipDonationTypeId:SettingValue = { name: 'Mandir.MembershipDonationTypeId' } as any;

  receiptHtml:SettingValue = { name: 'MandirSecure.ReceiptHtml' } as any;
  eventNotificationPhoneNumbers:SettingValue = { name: 'MandirSecure.EventNotificationPhoneNumbers' } as any;
  annualReceiptHtml:SettingValue = { name: 'MandirSecure.AnnualReceiptHtml' } as any;
  testEmail:SettingValue = { name: 'MandirSecure.TestEmail' } as any;
  testPhone:SettingValue = { name: 'MandirSecure.TestPhone' } as any;
  annualMembershipFee:SettingValue = { name: 'Mandir.AnnualMembershipFee' } as any;
  eventsToAvoid:SettingValue = { name: 'MandirSecure.EventsToAvoid' } as any;
  membershipReceiptHtml:SettingValue = { name: 'MandirSecure.MembershipReceiptHtml' } as any;
  nonDonationReceiptHtml:SettingValue = { name: 'MandirSecure.NonDonationReceiptHtml' } as any;
  blockUIEndPoints:SettingValue = { name: 'Mandir.BlockUIEndPoints' } as any;

  logoUrl:SettingValue = { name: 'Mandir.LogoURL' } as any;
  backgroundUrl:SettingValue = { name: 'Mandir.BackgroundURL' } as any;
  cssOverride:SettingValue = { name: 'Mandir.CSSOverride' } as any;

  constructor(
    public service: SettingsService,
    private confirmation: ConfirmationService,
    private localizationService: LocalizationService,
    private messageService: MessageService,
    private permissionService: PermissionService){
      this.service.getSettingsByNames(this.settings.join(',')).subscribe(result => {
        this.original = result;
        this.currentAccountingYear = this.getSetting(result, 'Mandir.CurrentAccountYear');
        this.membershipDonationTypeId = this.getSetting(result, 'Mandir.MembershipDonationTypeId');
        this.receiptHtml = this.getSetting(result, 'MandirSecure.ReceiptHtml');
        this.eventNotificationPhoneNumbers = this.getSetting(result, 'MandirSecure.EventNotificationPhoneNumbers');
        this.annualReceiptHtml = this.getSetting(result, 'MandirSecure.AnnualReceiptHtml');
        this.membershipReceiptHtml = this.getSetting(result, 'MandirSecure.MembershipReceiptHtml');
        this.nonDonationReceiptHtml = this.getSetting(result, 'MandirSecure.NonDonationReceiptHtml');
        this.blockUIEndPoints = this.getSetting(result, 'Mandir.BlockUIEndPoints');
        debugger
        this.logoUrl = this.getSetting(result, 'Mandir.LogoURL');
        this.backgroundUrl = this.getSetting(result, 'Mandir.BackgroundURL');
        this.cssOverride = this.getSetting(result, 'Mandir.CSSOverride');

        this.testEmail = this.getSetting(result, 'MandirSecure.TestEmail');
        this.testPhone = this.getSetting(result, 'MandirSecure.TestPhone');
        this.annualMembershipFee = this.getSetting(result, 'Mandir.AnnualMembershipFee');
        this.eventsToAvoid = this.getSetting(result, 'MandirSecure.EventsToAvoid');

      });
  }
  getSetting(items: SettingValue[], name: string) {
    let values = items.filter(item => {
      return item.name == name;
    });
    if (!values.length) {
      return { name: name } as any;
    }
    return values[0];
  }

  getArraySetting(items: SettingValue[], name: string) {
    let values = items.filter(item => {
      return item.name == name;
    });
    if (!values.length) {
      return { name: name } as MandirSettingValue<string[]>;
    }

    return { name: name, value: values[0].value.split(',') } as MandirSettingValue<string[]>;
  }

  getDateSetting(items: SettingValue[], name: string) {
    let values = items.filter(item => {
      return item.name == name;
    });
    if (!values.length) {
      return { name: name } as MandirSettingValue<Date>;
    }

    return { name: name, value: new Date(values[0].value) } as MandirSettingValue<Date>;
  }

  saveSettings() {
    let updatedSettings: SettingValue[] = [];
    let settings = [
      this.currentAccountingYear,
      this.membershipDonationTypeId,
      this.receiptHtml,
      this.eventNotificationPhoneNumbers,
      this.annualReceiptHtml,
      this.membershipReceiptHtml,
      this.nonDonationReceiptHtml,
      this.blockUIEndPoints,
      this.testEmail,
      this.testPhone,
      this.annualMembershipFee,
      this.eventsToAvoid,
      this.logoUrl,
      this.backgroundUrl,
      this.cssOverride
    ];
    settings.forEach(setting => {
      if (Array.isArray(setting.value)) {
        updatedSettings.push({
          name: setting.name,
          value: setting.value.join(','),
        } as any);
      } else {
        updatedSettings.push({ name: setting.name, value: setting.value } as any);
      }
    });
    this.service.setSettingsByInput(updatedSettings).subscribe(result => {
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: this.localizationService.instant('::SettingsUpdatedSuccessfully'),
      });
    });
  }

}
