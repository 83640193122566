<!-------------bannner------------>
<div class="banner-part"> <img src="/assets/images/donate-banner.jpg" alt="">
  <div class="container">
    <div class="wraper">
      <h1>{{ '::Membership:Title' | abpLocalization }}</h1>
    </div>
  </div>
</div>
<!-------------banner------------>
<!-- <button
    *abpPermission="'Mandir.EmailTemplates.Create'"
    class="btn btn-primary btn-sm"
    type="button"
    [routerLink]="['/manage-members']"
  >
    <i class="fa fa-plus me-1" aria-hidden="true"></i>
    {{ '::ManageMembers' | abpLocalization }}
  </button> -->
  <div class="container" style="padding: 10%" *ngIf="!hasLoggedIn">
    <div class="about-us-section">
      <div class="container">
        <div class="wraper">
        <p><strong>{{ '::Membership:DuesInfo' | abpLocalization }}</strong></p>
        <div class="notes">
        <p><strong>{{ '::Membership:PleaseNote' | abpLocalization }}</strong></p>
        <ul>
  <li>{{ '::Membership:Note1' | abpLocalization }}</li>
  <li>{{ '::Membership:Note2' | abpLocalization }}</li>
  <li>{{ '::Membership:Note3' | abpLocalization }}</li></ul>
        </div>
         
         <div class="membership-req">
          <h4>{{ '::Membership:Requirements' | abpLocalization }}</h4>
          <p><strong>{{ '::Membership:RequirementsIntro' | abpLocalization }}</strong></p>
  <ul>
      <li>{{ '::Membership:Requirement1' | abpLocalization }}</li>
      <li>{{ '::Membership:Requirement2' | abpLocalization }}</li>
       <li>{{ '::Membership:Requirement3' | abpLocalization }}</li>
  </ul>
  <div class="butn-group"><a (click)="membershipForm()" class="member-btn">{{ '::Membership:BecomeAMember' | abpLocalization }}</a>
  <a (click)="login()" class="login-btn">{{ '::Membership:LogIn' | abpLocalization }}</a></div>
         </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" style="padding: 10%" *ngIf="hasLoggedIn" [abpLoading]="uiBusy">
    <div class="row">
      <div class="col-12">
        <div *ngIf="memberInfo.isMember">
        <div class="row" style="margin-bottom: 20px;">
          <div class="col-12">
            <button
              class="btn btn-primary float-end"
              (click)="showPaymentModal()"
              *abpPermission="'Mandir.App.AllowOfflinePayments'"
              
            >
            <!-- [routerLink]="['/donate/'+emptyGuid + '/'+activeUserId+'/General Donation']"
              [queryParams]="{ ref:ref, amt:amt }"
              target="_blank"
              -->
              {{ '::Membership:AddPayment' | abpLocalization }}
              <!-- (click)="addPayment(row.id)" -->
            </button>
          </div>
        </div>
        <div class="row" >
          <div class="col-12 col-md-4">
            <h2 *ngIf="currentUserInfo">{{memberInfo.member.name}} {{memberInfo.member.surname}}
              
            </h2>
            <div>{{ '::Membership:MembershipId' | abpLocalization }}: {{memberInfo.member.extraProperties.LegacyMembershipId}}</div>
            <div>{{memberInfo.member.addressLine1}}</div>
            <div>{{memberInfo.member.addressLine2}}</div>
            <div>{{memberInfo.member.addressLine3}} {{memberInfo.member.postalCode}}</div>
            <div>{{ '::Membership:Phone' | abpLocalization }}: {{memberInfo.member.phoneNumber}}</div>
            <div>{{ '::Membership:Email' | abpLocalization }}: {{memberInfo.member.email}}</div>


              <!-- {{memberInfo.member.addressLine1 | json}} -->
            <br><Br>
            <h6>{{ '::Membership:MembershipReceipts' | abpLocalization }}</h6>
            <ul style="padding-left:0;">
              <li style="display: inline-block; margin-bottom: 20px" *ngFor="let payment of getPaymentsByUser(membershipPayments, activeUserId)">
                <span [title]="payment.paymentDate | date : 'dd-MMM-yyyy'" (click)="getReceipt(payment.id)" class="btn btn-primary" style="margin-right: 10px; margin-left: 10px;">
                  <i class="fa cursor-pointer fa-download" *ngIf="payment.id"></i>&nbsp;
                  {{getMembershipYear(payment.paymentDate)}}
                </span>
              </li>
            </ul>
            <!-- <a *ngIf="!hasCurrentYearMembership()" 
              (click)="membershipForm()" class="btn btn-primary hidden">Make this year's Membership Payment</a>   -->
             
              <div ngbDropdown container="body" class="d-inline-block" style="padding-left:0; width: 100%; margin-bottom: 10px;">

                <button
                class="btn btn-primary btn-sm dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  ngbDropdownToggle
                >
                  <!-- <i class="fa fa-cog me-1" aria-hidden="true"></i> -->
                  {{ '::Membership:SendAnnualReceipt' | abpLocalization }}

                  <!-- {{ 'AbpUi::Actions' | abpLocalization }} -->
                </button>
                <div ngbDropdownMenu>
                  <ng-container *ngFor="let year of previousYears">
                    <button
                      ngbDropdownItem
                      (click)="sendAnnualEmail(getPaymentsByUser(memberInfo.payments, activeUserId)[0].id, activeUserId, year)"
                    >
                      {{ '::Year' | abpLocalization }} : {{year}}
                    </button>
                  </ng-container>
                </div>
              </div>

              <div ngbDropdown container="body" class="d-inline-block" style="padding-left:0; width: 100%;">

                <button
                class="btn btn-primary btn-sm dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  ngbDropdownToggle
                >
                  <!-- <i class="fa fa-cog me-1" aria-hidden="true"></i> -->
                  {{ '::Membership:DownloadAnnualReceipt' | abpLocalization }}

                  <!-- {{ 'AbpUi::Actions' | abpLocalization }} -->
                </button>
                <div ngbDropdownMenu>
                  <ng-container *ngFor="let year of previousYears">
                    <button
                      ngbDropdownItem
                      (click)="getAnnualReceipt(getPaymentsByUser(memberInfo.payments, activeUserId)[0].id, year)"
                    >
                      {{ '::Year' | abpLocalization }} : {{year}}
                    </button>
                  </ng-container>
                </div>
              </div>

              <!-- <a
              *ngIf="canSeeAnnualReport"
              (click)="sendAnnualEmail(getPaymentsByUser(memberInfo.payments, activeUserId)[0].id,activeUserId, previousYear)" class="btn btn-secondary">Send Annual Receipt</a>
<br><br>
              <a
              *ngIf="canSeeAnnualReport"
              (click)="getAnnualReceipt(getPaymentsByUser(memberInfo.payments, activeUserId)[0].id, previousYear)" class="btn btn-secondary">Download Annual Receipt</a> -->
            </div>
          <div class="col-12 col-md-8">
            
            <div class="row" style="margin-bottom: 10px;">
              <div class="col-4"><strong>{{ '::Membership:PaymentType' | abpLocalization }}</strong></div>
              <!-- <div class="col-3"><strong>Payment Type</strong></div> -->
              <div class="col-2"><strong>{{ '::Membership:Amount' | abpLocalization }}</strong></div>
  
              <div  class="col-3"><strong>{{ '::Membership:ReceiptNumber' | abpLocalization }}</strong></div>
              <div class="col-3"><strong>{{ '::Membership:ReceivedDate' | abpLocalization }}</strong></div>
              
            </div>
            <div class="row" style="margin-bottom: 10px;"
             *ngFor="let payment of getPaymentsByUser(memberInfo.payments, activeUserId)">
              <div class="col-4" style="padding-left:0">
                <div ngbDropdown container="body" class="d-inline-block" style="padding-left:0; width: 100%;">

                  <div
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    ngbDropdownToggle
                    style="width: 100%; margin-left: 0;  text-align: left;"
                  >
                    <!-- <i class="fa fa-cog me-1" aria-hidden="true"></i> -->
                    {{getPaymentType(payment.paymentTypeId)}}

                    <!-- {{ 'AbpUi::Actions' | abpLocalization }} -->
                  </div>
                  

                  <div ngbDropdownMenu>
                    <button
                      ngbDropdownItem
                      [routerLink]="['/receipt/'+payment.id]"
                    >
                      {{ '::ViewReceipt' | abpLocalization }}
                    </button>
                    <button
                      ngbDropdownItem
                      (click)="sendEmail(payment)"
                    >
                      {{ '::EmailReceipt' | abpLocalization }}
                    </button>
                    <button
                      ngbDropdownItem
                      (click)="getReceipt(payment.id)"
                    >
                      {{ '::DownloadReceipt' | abpLocalization }}
                    </button>
                    <button
                      ngbDropdownItem
                      *abpPermission="'Mandir.App.AllowVoidPayments'"
                      (click)="showEditForm(payment)"
                    >
                      {{ 'AbpUi::Edit' | abpLocalization }}
                    </button>
    
                    <button
                      ngbDropdownItem
                      *abpPermission="'Mandir.App.AllowVoidPayments'"
                      (click)="showVoidPayment(payment)"
                    >
                      {{ 'AbpUi::Delete' | abpLocalization }}
                    </button>
                    <a
                    target="_blank"
                      ngbDropdownItem
                      *abpPermission="'Mandir.AuditLogs.View'"
                      [routerLink]="['/audit/history/'+payment.id]"
                    >
                      {{ '::AuditHistory' | abpLocalization }}
                    </a>
                  </div>
                </div>
              </div>
              <!-- <div class="col-3">
                <a [routerLink]="['/receipt/'+payment.id]">
                  {{getPaymentType(payment.paymentTypeId)}}</a></div> -->
              <div class="col-2">${{payment.amount}}</div>
  
              <div class="col-3">{{getReceiptNum(payment.id)}}</div>
              <div class="col-3">{{payment.paymentDate | date : 'dd-MMM-yyyy'}}</div>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">

                <div class="row" *ngFor="let member of familyMembers">
                  
                  <div class="col-12 col-md-6">
                    <!-- <h5>Info For:  <span>
                      {{member.name}}!
                    </span></h5> -->
                    <br><Br>
                      
                    <h5>{{member.name}} {{member.surname}}<br/> ({{ '::Membership:MembershipId' | abpLocalization }}: {{member.extraProperties.LegacyMembershipId}}) </h5>
                    {{ '::Membership:MembershipReceipts' | abpLocalization }}:
                    <ul style="padding-left:0;">
                      <li style="display: inline-block;" *ngFor="let payment of getPaymentsByUser(membershipPayments, member.id) ">
                        <!-- | orderBy: 'accountingYear' -->
                        <span (click)="getReceipt(payment.id)" class="btn btn-primary" style="margin-right: 5px; margin-left: 5px; margin-bottom: 10px;">
                          <i class="fa cursor-pointer fa-download" *ngIf="payment.id"></i>&nbsp;
      
                          <!-- <i class="fa fa-receipt" *ngIf="payment.id"></i> -->
                          {{getMembershipYear(payment.paymentDate)}}
          
                        </span>
                      </li>
                    </ul>
                    <!-- <a *ngIf="!hasCurrentYearMembership()" 
                    (click)="membershipForm()" class="btn btn-primary hidden">Make this year's Membership Payment</a> -->
                  </div>
                  <div class="col-12 col-md-6">
                    <br><br>
                    <div class="row">
                      <div class="col-5"><strong>{{ '::Membership:PaymentType' | abpLocalization }}</strong></div>
                      <div class="col-2"><strong>{{ '::Membership:Amount' | abpLocalization }}</strong></div>
          
                      <div class="col-2"><strong>{{ '::Membership:Year' | abpLocalization }}</strong></div>
                      <div class="col-3"><strong>{{ '::Membership:ReceivedDate' | abpLocalization }}</strong></div>
          
                      
                    </div>
                    <div class="row" *ngFor="let payment of getPaymentsByUser(memberInfo.payments, member.id)">
          
                      <div class="col-5">
                        <span (click)="getReceipt(payment.id)" class="fa cursor-pointer fa-download"></span> &nbsp;
               
                        <a [routerLink]="['/receipt/'+payment.id]">
                          {{getPaymentType(payment.paymentTypeId)}}</a> </div>
                      <div class="col-2">${{payment.amount}}</div>
          
                      <!-- <div class="col-2">{{payment.accountingYear}}</div> -->
                      <div class="col-3">{{payment.paymentDate | date}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-12">
                <a 
            (click)="membershipForm()" class="btn btn-primary">Add Member</a>
              </div>
            </div> -->
          </div>
  
        </div>
      </div>
        <div class="row" *ngIf="!memberInfo.isMember">
          <div class="col-12">
            <div *ngIf="currentUserInfo" class="header-btn d-none d-sm-block ml-lg-4" style="margin-top: 15px">
              <strong>{{ '::Membership:DuesInfo' | abpLocalization }}</strong> Annual membership dues of $51.00* per individual must be paid at the time of application.<br /><br />
  <strong>{{ '::Membership:PleaseNote' | abpLocalization }}</strong><br />
  • A single receipts will be issued for membership due for the amount of $51.<br />
  • A combined application can be made for all individual living at one address.<br />
  • Membership is effective immediately upon receipt of properly completed membership application form and payment of dues.<br /><br /><br />
  
              <h1>{{ '::Membership:Requirements' | abpLocalization }}:</h1>
              <div>
                By applying for Vaishno Devi Mandir, I swear under oath that I am<br>
  1. Interested in the pursuit of doctrines of Hinduism and objectives of Vaishnodevi Temple, Oakville<br />
  2. A Hindu and/or accept Hindu values of life and<br>
  3. At least 18 years of age <br>
  <br>
              </div>
              <a 
            (click)="membershipForm()" class="btn btn-primary">Become Member</a>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>



<p-dialog header="Title" [(visible)]="displayMessage">
  {{messageContent}}
</p-dialog>
<abp-modal [(visible)]="modalVisible" [busy]="modalBusy" [options]="{ size: 'xl' }">
  <ng-template #abpHeader>
    <h5>{{ '::Membership:VoidPayment' | abpLocalization }}</h5>
  </ng-template>
  <ng-template #abpBody>
    <form [formGroup]="form" id="payment-type" (ngSubmit)="submitForm()" validateOnSubmit>
      <div class="mt-2 fade-in-top">
        <div class="mb-3">
          <label class="form-label" for="verificationNote">
            {{ '::Membership:ReasonToVoid' | abpLocalization }} *
          </label>

          <textarea
            id="verificationNote"
            formControlName="verificationNote"
            class="form-control"
            autofocus
          ></textarea>
        </div>
        
      </div>
    </form>
  </ng-template>
  <ng-template #abpFooter>
    <button abpClose type="button" class="btn btn-secondary">
      {{ 'AbpAccount::Cancel' | abpLocalization }}
    </button>
    <abp-button type="abp-button" iconClass="fa fa-check" buttonType="submit" formName="form" (click)="submitForm()">
      {{ 'AbpAccount::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>

<abp-modal [(visible)]="editModalVisible" [busy]="editModalBusy" [options]="{ size: 'xl' }">
  <ng-template #abpHeader>
    <h5>{{ '::Membership:EditPayment' | abpLocalization }}</h5>
  </ng-template>
  <ng-template #abpBody>
    <form [formGroup]="editForm" id="payment-type" (ngSubmit)="submitEditForm()" validateOnSubmit>
      <div class="mt-2 fade-in-top">

          <div class="mb-3">
            <label class="form-label" for="isPresentFilter">
              {{ '::Membership:NewPaymentType' | abpLocalization }}
            </label>

            <select
                      id="paymentTypeId"
                      formControlName="paymentTypeId"
                      class="form-select form-control"
                      
                    >
                      <option
                        *ngFor="let option of paymentTypes"
                        [ngValue]="option.id"
                      >
                        {{option.name}}
                      </option>
                    </select>
          </div>
        
        <div class="mb-3">
          <label class="form-label" for="verificationNote">
            {{ '::Membership:ReasonToChange' | abpLocalization }} *
          </label>

          <textarea
            id="verificationNote"
            formControlName="verificationNote"
            class="form-control"
            autofocus
          ></textarea>
          <br><br>
          <strong>{{ '::Membership:ExistingNotes' | abpLocalization }}:</strong> <br/>
          <strong>{{getConfirmationCodeDetail(selected.confirmationCode)}}</strong><br>
          <span [innerHTML]="this.selected.verificationNote"></span>
        </div>
        
      </div>
    </form>
  </ng-template>
  <ng-template #abpFooter>
    <button abpClose type="button" class="btn btn-secondary">
      {{ 'AbpAccount::Cancel' | abpLocalization }}
    </button>
    <abp-button type="abp-button" iconClass="fa fa-check" buttonType="submit" formName="form" (click)="submitEditForm()">
      {{ 'AbpAccount::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>


<abp-modal [(visible)]="paymentModalVisible" [busy]="paymentModalBusy" [options]="{ size: 'xl' }">
  <ng-template #abpHeader>
    <h5>{{ '::Membership:AddPayment' | abpLocalization }}</h5>
  </ng-template>
  <ng-template #abpBody>
    <app-user-donation 
    [userId]="activeUserId" 
    (closeModal)="closeModal()" 
    paymentType="General Donation"
    [currentPayments]="getPaymentsByUser(memberInfo.payments, activeUserId)"
    ></app-user-donation>
  </ng-template>
</abp-modal>