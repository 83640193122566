import { Component, OnInit } from '@angular/core';
import { PublicService } from '@proxy/public';
import { ScheduledEventDto } from '@proxy/scheduled-events';
import { DialogService } from 'primeng/dynamicdialog';
import { GoogleCalendaryService } from '@proxy/google-calendar';
import { ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { Router } from '@angular/router';
import { MultiLanguageScheduledEventDto } from '@proxy/public/models';
import { SessionStateService } from '@abp/ng.core';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
  providers: [DialogService],
})
export class EventListComponent implements OnInit {

  items: ScheduledEventDto[]=[];
  multiLanguageItems: MultiLanguageScheduledEventDto[]=[];
  currentLanguage: string = 'en';
  
  constructor(
    private publicService: PublicService,
    private dialogService: DialogService,
    private calendarService: GoogleCalendaryService,
    private confirmService: ConfirmationService,
    private toastService: ToasterService,
    private readonly router: Router,
    private sessionStateService: SessionStateService
  ){
    // Get current language from ABP
    debugger;
    this.currentLanguage = this.sessionStateService.getLanguage() || 'en';
    
    // For consistency, get the base language code for translations
    if (this.currentLanguage.includes('-')) {
      console.log('Original language from ABP:', this.currentLanguage);
      // Save the full culture for ABP but use the base code for translations
      this.currentLanguage = this.currentLanguage.split('-')[0];
      console.log('Using base language code for translations:', this.currentLanguage);
    }
  }

  ngOnInit(): void {
    // Call the new endpoint with translations
    this.publicService.getEventsWithTranslationsByLanguages(['en', 'hi','gu','pa']).subscribe(data => {
      this.multiLanguageItems = data;
      
      // Process events based on current language
      this.processEvents();
    });
  }

  // Process events based on current language
  processEvents() {
    debugger;
    // If we have multilanguage items, process them
    if (this.multiLanguageItems && this.multiLanguageItems.length > 0) {
      // Map the events with appropriate translations
      this.items = this.multiLanguageItems.map(item => {
        // Start with the original event
        const event = { ...item.original };
        
        // Apply translation if available for current language
        // Use the base language code for translations lookup
        const langCode = this.currentLanguage.split('-')[0];
        
        if (langCode !== 'en' && item.translations && item.translations[langCode]) {
          const translation = item.translations[langCode];
          event.name = translation.name;
          event.description = translation.description;
          // Apply other translated fields as needed
        }
        
        return event;
      });
    }
    
    // Add static weekly events
    //this.addStaticEvents();
  }

  // Add static weekly events with appropriate translations
  addStaticEvents() {
    // Use the base language code for translations
    const langCode = this.currentLanguage.split('-')[0];
    
    if (langCode !== 'en') {
      if (langCode === 'hi') {
        // Add Hindi static events
        const eventTime = new Date();
        eventTime.setHours(18, 0, 0);
        this.items.unshift({
          id: "Weekly - Every Tuesday",
          name: "साप्ताहिक सत्संग, आरती, प्रीति भोज (हर मंगलवार)",
          description: "मंगलवार का साप्ताहिक कार्यक्रम जिसके बाद प्रीति भोज होता है।",
          imageUrl: "/assets/images/hero/Vaishno-Devi-Temple-2.jpg",
          date: eventTime.toISOString(),
          startsAt: eventTime.toISOString(),
          endsAt: new Date(eventTime.getTime() + 2 * 60 * 60 * 1000).toISOString(),
          requiresVolunteers: false,
          isAddDay: false,
          hasTickets: false,
          hasSlots: false
        } as ScheduledEventDto);

        const eventTime1 = new Date();
        eventTime1.setHours(15, 30, 0);
      
        this.items.unshift({
          id: "Weekly - Every Sunday",
          name: "साप्ताहिक सत्संग, आरती, प्रीति भोज (हर रविवार)",
          description: "रविवार का साप्ताहिक कार्यक्रम जिसके बाद प्रीति भोज होता है।",
          imageUrl: "/assets/images/hero/Vaishno-Devi-Temple-2.jpg",
          date: eventTime1.toISOString(),
          startsAt: eventTime1.toISOString(),
          endsAt: new Date(eventTime1.getTime() + 2 * 60 * 60 * 1000).toISOString(),
          requiresVolunteers: false,
          isAddDay: false,
          hasTickets: false,
          hasSlots: false
        } as ScheduledEventDto);
      } else if (langCode === 'gu') {
        // Add Gujarati static events
        const eventTime = new Date();
        eventTime.setHours(18, 0, 0);
        this.items.unshift({
          id: "Weekly - Every Tuesday",
          name: "સાપ્તાહિક સત્સંગ, આરતી, પ્રીતિ ભોજ (દર મંગળવારે)",
          description: "દર મંગળવારે સાંજે 6:00 વાગ્યે સત્સંગ, આરતી અને પ્રીતિ ભોજ.",
          posterUrl: "/assets/images/hero/Vaishno-Devi-Temple-2.jpg",
          date: eventTime.toISOString(),
          startsAt: eventTime.toISOString(),
          endsAt: new Date(eventTime.getTime() + 2 * 60 * 60 * 1000).toISOString(),
          venue: "વૈષ્ણો દેવી મંદિર",
          isRecurring: true,
          requiresVolunteers: false,
          isAddDay: false,
          hasTickets: false,
          hasSlots: false
        } as ScheduledEventDto);
      } else if (langCode === 'pa') {
        // Add Punjabi static events
        const eventTime = new Date();
        eventTime.setHours(18, 0, 0);
        this.items.unshift({
          id: "Weekly - Every Tuesday",
          name: "ਹਫਤਾਵਾਰੀ ਸਤਸੰਗ, ਆਰਤੀ, ਪ੍ਰੀਤੀ ਭੋਜ (ਹਰ ਮੰਗਲਵਾਰ)",
          description: "ਹਰ ਮੰਗਲਵਾਰ ਸ਼ਾਮ 6:00 ਵਜੇ ਸਤਸੰਗ, ਆਰਤੀ ਅਤੇ ਪ੍ਰੀਤੀ ਭੋਜ।",
          posterUrl: "/assets/images/hero/Vaishno-Devi-Temple-2.jpg",
          date: eventTime.toISOString(),
          startsAt: eventTime.toISOString(),
          endsAt: new Date(eventTime.getTime() + 2 * 60 * 60 * 1000).toISOString(),
          venue: "ਵੈਸ਼ਨੋ ਦੇਵੀ ਮੰਦਿਰ",
          isRecurring: true,
          requiresVolunteers: false,
          isAddDay: false,
          hasTickets: false,
          hasSlots: false
        } as ScheduledEventDto);
      } else {
        // Fallback to English for other languages
        this.addEnglishStaticEvents();
      }
    } else {
      // Add English static events
      this.addEnglishStaticEvents();
    }
  }
  
  // Helper method for English static events
  addEnglishStaticEvents() {
    const eventTime = new Date();
    eventTime.setHours(18, 0, 0);
    this.items.unshift({
      id: "Weekly - Every Tuesday",
      name: "Weekly Satsang, Aarti, Preeti Bhoj (Every Tuesday)",
      description: "Weekly Tuesday program followed by Preeti Bhoj.",
      imageUrl: "/assets/images/hero/Vaishno-Devi-Temple-2.jpg",
      date: eventTime.toISOString(),
      startsAt: eventTime.toISOString(),
      endsAt: new Date(eventTime.getTime() + 2 * 60 * 60 * 1000).toISOString(),
      requiresVolunteers: false,
      isAddDay: false,
      hasTickets: false,
      hasSlots: false
    } as ScheduledEventDto);

    const eventTime1 = new Date();
    eventTime1.setHours(15, 30, 0);
  
    this.items.unshift({
      id: "Weekly - Every Sunday",
      name: "Weekly Satsang, Aarti, Preeti Bhoj (Every Sunday)",
      description: "Weekly Sunday program followed by Preeti Bhoj.",
      imageUrl: "/assets/images/hero/Vaishno-Devi-Temple-2.jpg",
      date: eventTime1.toISOString(),
      startsAt: eventTime1.toISOString(),
      endsAt: new Date(eventTime1.getTime() + 2 * 60 * 60 * 1000).toISOString(),
      requiresVolunteers: false,
      isAddDay: false,
      hasTickets: false,
      hasSlots: false
    } as ScheduledEventDto);
  }

  showEventDetails(event:ScheduledEventDto){
    this.router.navigate(['/event-detail', event.id]);
  }

  loadGoogleCalendar(){
    this.confirmService.warn("This would synchronize site's calendar with Google Calendar, do you want to proceed?","Event Sync").subscribe(resp => {
      if(resp == "confirm"){
        this.calendarService.getEvents().subscribe(data => {
          this.toastService.success("Successfully synchronized the calendar");
        });
      }
    });
  }
}
