<div class="banner-part" *ngIf="!closeModal"> <img src="/assets/images/donate-banner.jpg" alt="">
  <div class="container">
    <div class="wraper">
      <h1>{{ '::Donation:Title' | abpLocalization }}</h1>
    </div>
  </div>
</div>
<div class="donate-sec">
  <div class="container">
    <div class="wraper">
      <h2 *ngIf="isDonation">{{campaign.name}}</h2>
      <h2 *ngIf="!isDonation && DonationForm">
        {{ '::Donation:Category' | abpLocalization }}: {{getPaymentType(DonationForm.value.PaymentTypeId).name}}</h2>
      <h3 *ngIf="donor.id" class="text-center">{{ '::Donation:Member' | abpLocalization }}: {{donor.name}} {{donor.surname}}</h3>
      <div class="form" *ngIf="DonationForm">

        <p-steps [model]="items" [readonly]="false" [activeIndex]="stepActiveIndex"></p-steps>

        <form [formGroup]="DonationForm">
          <br>
          <div>
            <div *ngIf="activeStep === 'Personal'">
              <div class="row" *ngIf="!authService.isAuthenticated && !donor.id">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>{{ '::Donation:FirstName' | abpLocalization }}<span class="give-required-indicator">*</span></label>
                    <input type="text" class="no-margin-bottom" id="Name" formControlName="Name" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>{{ '::Donation:SurName' | abpLocalization }}<span class="give-required-indicator">*</span></label>
                    <input type="text" class="no-margin-bottom" id="SurName" formControlName="SurName" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>{{ '::Donation:Email' | abpLocalization }}<span class="give-required-indicator">*</span></label>
                    <input type="text" class="no-margin-bottom" id="Email" formControlName="Email"
                      placeholder="name@email.com" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label>{{ '::Donation:PhoneNumber' | abpLocalization }}<span class="give-required-indicator">*</span></label>
                    <input type="text" class="no-margin-bottom" id="PhoneNumber" formControlName="PhoneNumber"
                      placeholder="111-222-3333" />
                  </div>
                </div>
              </div>

              <div class="row mb-20" >
                <div class="col-lg-6 col-md-6 col-sm-12" *ngIf="allowPaymentCategories">
                  <label>{{ '::Donation:PaymentCategory' | abpLocalization }} <span class="give-required-indicator">*</span></label>
                  
                  <select
                      id="PaymentTypeId"
                      formControlName="PaymentTypeId"
                      class="form-select form-control"
                      (change)="setPaymentType()"
                      
                    >
                      <option
                        *ngFor="let option of paymentTypes"
                        [ngValue]="option.id"
                      >
                        {{option.name}}
                      </option>
                    </select>
                </div>
              <div *ngIf="mode ==='Stripe'" class="col-lg-6 col-md-6 col-sm-12"></div>
              <div *ngIf="mode !=='Stripe'" class="col-lg-6 col-md-6 col-sm-12">
                <div class="col-12">
                  <div class="form-group">
                    <label>{{ '::Donation:Amount' | abpLocalization }}<span class="give-required-indicator">*</span></label>
                    <input type="text" class="no-margin-bottom" id="Amount" formControlName="Amount" 
                    style="font-size: 2.125rem;"
                    />
                  </div>
                </div>
                <div class="col-12 d-none d-sm-none d-md-block next-butn">
                  <a class="butn butn-outline butn-small" [ngClass]="{'donation-amount': DonationForm.value.Amount === 21}"
                  (click)="setValue(21)">$21</a> &nbsp;
                  <a class="butn butn-outline butn-small" [ngClass]="{'donation-amount': DonationForm.value.Amount === 51}"
                  (click)="setValue(51)">$51</a> &nbsp;
                  <a class="butn butn-outline butn-small" [ngClass]="{'donation-amount': DonationForm.value.Amount === 101}"
                  (click)="setValue(101)">$101</a> &nbsp;
                  <!-- <a class="butn butn-outline d-md-none d-lg-none d-xl-block" [ngClass]="{'donation-amount': DonationForm.value.Amount === 251}"
                  (click)="setValue(251)">$251</a> &nbsp; -->
                  <a class="butn butn-outline butn-small" 
                  [ngClass]="{'donation-amount': DonationForm.value.Amount !== 21 &&DonationForm.value.Amount !== 51 && DonationForm.value.Amount !== 101 && DonationForm.value.Amount !== 251}"
                  (click)="setValue()">{{ '::Donation:Custom' | abpLocalization }}</a> &nbsp;
                </div>
              </div>
            
                <div 
                *ngIf="!allowPaymentCategories">
                  <div class="form-group">
                    <label>{{ '::Donation:Comments' | abpLocalization }}</label>
                    <textarea type="text" class="form-control" id="Comments" formControlName="Comments"></textarea>
                  </div>
                </div>

              </div>
              <div class="row" *ngIf="events.length && allowOfflinePayments" style="margin-bottom: 20px;">
                <div class="col-lg-6 col-md-6 col-sm-12">
                  <label>{{ '::Donation:SelectEvent' | abpLocalization }}<span class="give-required-indicator">*</span></label>
                  
                  <select
                      id="select-event"
                      [(ngModel)]="selectedEvent"
                      class="form-select form-control"
                      [ngModelOptions]="{standalone: true}"
                      (change)="setEvent()"
                      
                    >
                      <option
                        *ngFor="let option of events"
                        [ngValue]="option.id"
                      >
                        {{option.name}}
                      </option>
                    </select>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                  <label>{{ '::Donation:SelectTicketType' | abpLocalization }}<span class="give-required-indicator">*</span></label>
                  
                  <select
                      id="select-event"
                      [(ngModel)]="selectedTicketType"
                      class="form-select form-control"
                      [ngModelOptions]="{standalone: true}"
                    >
                      <option
                        *ngFor="let option of ticketTypes"
                        [ngValue]="option.id"
                      >
                        {{option.name}}
                      </option>
                    </select>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                  <label>{{ '::Donation:Quantity' | abpLocalization }}<span class="give-required-indicator">*</span></label>
                  
                  <input type="number" class="form-control" id="Quantity" 
                   [(ngModel)]="selectedTicketQuantity" [ngModelOptions]="{standalone: true}"
                  (change)="setQuantity()" />
                  
                      
                </div>
              </div>
              <div class="col-12 next-butn">
                <button (click)="moveStep(1)" class="butn">{{ '::Donation:Next' | abpLocalization }}</button>
              </div>
            </div>
            <div class="row mb-20" *ngIf="activeStep === 'Payment'">
              <div>
                {{ '::Donation:PaymentCategoryLabel' | abpLocalization }}: {{selectedPaymentType.name}}<br>
                {{ '::Donation:AmountLabel' | abpLocalization }}: ${{DonationForm.value.Amount}}<br />
                {{ '::Donation:CommentsLabel' | abpLocalization }}: {{DonationForm.value.Comments}}<br /><br />
              </div>
              <app-accept-payment 
              #payment
              [newUser]="newUser"
              [dollarAmount]="DonationForm.value.Amount" 
              [interacReference]="ref"
              [paymentMode]="mode"
              [date]="date"
              [comments]="comments"
              (formOnSubmit)="onSubmit()"
              ></app-accept-payment>
              <div class="col-12 next-butn">
                <button (click)="this.stepActiveIndex = this.stepActiveIndex-1;activeStep = 'Personal'" class="butn">{{ '::Donation:Back' | abpLocalization }}</button>
              </div>
              <!-- <div class="col-12">
                <app-stripe #stripe [amount]="this.DonationForm.value.Amount* 5100"
                  description="'Membership fee for the year" name="Vaishno Devi Mandir"
                  (paymentVerified)="sendRequest($event)"></app-stripe>
              </div> -->
              <!-- <div class="col-12 mt-30">
                <div class="next-butn">
                  <button class="butn">Donate ${{DonationForm.value.Amount}}</button>

                </div>
              </div> -->
            </div>
            <div class="row mb-20" *ngIf="activeStep === 'Confirmation'">
              <div class="col-12">
                <h1>{{ '::Donation:ThankYou' | abpLocalization }}</h1>
                <p *ngIf="this.donor">{{donor.name}} {{donor.surname}}, {{ '::Donation:YourDonationOf' | abpLocalization }}
                  ${{DonationForm.value.Amount}} {{ '::Donation:IsReceived' | abpLocalization }}</p>
                  <p *ngIf="!this.donor">{{DonationForm.value.Name}} {{DonationForm.value.SurName}}, {{ '::Donation:YourDonationOf' | abpLocalization }}
                    ${{DonationForm.value.Amount}} {{ '::Donation:IsReceived' | abpLocalization }}</p>
                <!-- <a 
        class="btn btn-primary" (click)="ref.close()" >Close</a> -->
              </div>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</div>