import { Component, OnInit, ViewChild } from '@angular/core';
import { EventTicketTypeDto } from '@proxy/event-ticket-types';
import { PublicService } from '@proxy/public';
import { ScheduledEventDto } from '@proxy/scheduled-events';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { MenuItem, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, ConfigStateService } from '@abp/ng.core';
import { Location } from '@angular/common';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { HostInfoDto } from '@proxy/shared';
import { AcceptPaymentComponent } from '../accept-payment/accept-payment.component';
import { DiscountCodeDto } from '@proxy/discount-codes';
import { DiscountTypes } from '@proxy/enums/discount-types.enum';
import { IdentityUserDto } from '@volo/abp.ng.identity/proxy';
import { MultiLanguageScheduledEventDto, TranslatedScheduledEventDto } from '@proxy/public/models';
import { SessionStateService } from '@abp/ng.core';
import { NgZone } from '@angular/core';

interface TicketCount {
  name: number;
  id: number;
}

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {
  @ViewChild('payment') payment: AcceptPaymentComponent;

  //@ViewChild('stripe') stripe: StripeComponent;
  promoCode:string;
  discountCode: DiscountCodeDto;
  promoMessage:string;
  showGoogleAddress:boolean=true;
  items:MenuItem[]=[];
  stepActiveIndex:number=0;
  event: ScheduledEventDto={} as ScheduledEventDto;
  multiLanguageEvent: MultiLanguageScheduledEventDto;
  eventTypes: EventTicketTypeDto[]=[];
  selectedTicketType: EventTicketTypeDto = {} as EventTicketTypeDto;
  tickeCount:TicketCount[]=[];
  EventForm!: FormGroup;
  steps:string[]=['Event','Personal','Payment','Confirmation'];
  activeStep: string='Event';
  paymentMode:string = "Online";
  paymentDetail:string;
  quantity:number=0;
  userId:string;
  uiBusy:boolean=false;
  newUser:any;
  currentLanguage: string = 'en';
  
  constructor(    
    public location: Location,
    private publicService: PublicService,
    private confirmService: ConfirmationService,
    private router: Router,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    private readonly activatedRoute: ActivatedRoute,
    private configState: ConfigStateService,
    private sessionStateService: SessionStateService,
    private ngZone: NgZone
    ){
      // Get current language from ABP
      this.currentLanguage = this.sessionStateService.getLanguage() || 'en';
      
      // For consistency, get the base language code for translations
      if (this.currentLanguage.includes('-')) {
        console.log('Original language from ABP:', this.currentLanguage);
        // Save the full culture for ABP but use the base code for translations
        this.currentLanguage = this.currentLanguage.split('-')[0];
        console.log('Using base language code for translations:', this.currentLanguage);
      }
    }

    ngOnInit(): void {
      this.items = [{
        label: 'Event',
        
    },
        {
            label: 'Personal',
            
        },
        {
            label: 'Payment',
           
        },
        {
            label: 'Confirmation',
  
          }
    ];
    
    this.configState.getDeep$('currentUser').subscribe(result => {
      this.userId = result.id;
    })
    this.activatedRoute.params.subscribe((result : any) => {
      
      // Replace getEventById with getEventWithTranslations
      this.publicService.getEventWithTranslationsByIdAndLanguages(result.id, ['en', 'hi','gu','pa']).subscribe(resp => {
        this.multiLanguageEvent = resp;
        
        // Set the event with appropriate translation
        this.processEvent();
        
        if(this.event.hasTickets){
          this.createForm();
        }
      })
      this.publicService.getTicketTypesByEventIdAndIsActive(result.id, true).subscribe(
              e => {
                this.eventTypes=e;
                if(result.meta){
                  
                  let meta = result.meta.split("|");
                  let ticketType = meta[0].split(":")[1];
                  let quantity = meta[1].split(":")[1];
                  this.selectedTicketType = this.eventTypes.find(x => x.id == ticketType);
                  this.quantity = parseInt(quantity);
                  this.activeStep = "Payment";
                  this.stepActiveIndex = this.steps.indexOf(this.activeStep); 
                  if(!this.authService.isAuthenticated){
                    this.ticketSelected();
                  }
                  //this.getAvailableTickets(this.selectedTicketType);
                }
              }
            )
    })
    }
    
    // Process event based on current language
    processEvent() {
      if (this.multiLanguageEvent) {
        // Start with the original event
        this.event = { ...this.multiLanguageEvent.original };
        
        // Apply translation if available for current language
        if (this.currentLanguage !== 'en' && 
            this.multiLanguageEvent.translations) {
          const langCode = this.currentLanguage.split('-')[0]; // Get base language code
          
          if (this.multiLanguageEvent.translations[langCode]) {
            const translation = this.multiLanguageEvent.translations[langCode];
            this.event.name = translation.name;
            this.event.description = translation.description;
            // Apply other translated fields as needed
          }
        }
      }
    }

    isSoldOut(ticketType: EventTicketTypeDto){
      
      return (ticketType.maxAvailability - (ticketType.totalSold / ticketType.stubsIncluded)) < 1;
    }
    getAvailableTickets(ticketType: EventTicketTypeDto){
      
      var availableTickets = ticketType.maxAvailability - ticketType.totalSold;
      if(availableTickets > 100){
        availableTickets = 100;
      }
      this.tickeCount = [];
      for(let i=0;i<availableTickets;i++){
        this.tickeCount.push({
          id:i+1,
          name:i+1
        } as TicketCount)
      }      
    }

  //   async onSubmit1(){

  //     if(this.paymentMode === 'Online'){
         
  //         if (!this.stripe || this.stripe.form.invalid) {
  //           return;
  //         }
  //         if(!this.selectedTicketType){
  //           return;
  //         }

  //         const { paymentIntent } = await this.stripe.stripe.confirmPayment({
  //           elements: this.stripe.elements,

  //           confirmParams: {
  //             return_url: `${window.location.origin}/return.html`,
  //           },
  //           redirect: 'if_required'
  //         });

  //         console.log(paymentIntent);
  //         if (paymentIntent) {
  //           this.purchaseTicket(paymentIntent);
  //         }
  //     }
  //     else{
  //       this.purchaseTicket({
  //         paymentMode: this.paymentMode,
  //         paymentDetail: this.paymentDetail
  //       })
  //     }
      
  // }

  async onSubmit(){
    
    this.uiBusy=true;
    if(!this.payment || !this.payment.paymentIntent){
      this.confirmService.error('Payment not completed','Payment', {hideCancelBtn: true, hideYesBtn: false, dismissible: false, cancelText: "Close", yesText: "OK"}).subscribe(resp => {
        this.uiBusy=false;
      });
      return
    }
    this.publicService.purchaseEventTicketsByUserIdAndEventTicketTypeIdAndQuantityAndAmountAndPaymentConfiramtionCode(
      this.userId,
      this.selectedTicketType.id, 
      this.quantity, 
      this.getAmount().toString(),
      JSON.stringify(this.payment.paymentIntent)).subscribe(result => {
        this.uiBusy=false;
        const options: Partial<Confirmation.Options> = {
          hideCancelBtn: true,
          hideYesBtn: false,
          dismissible: false,
          cancelText: "Close",
          yesText: "OK",
          messageLocalizationParams: ["Demo"],
          titleLocalizationParams: [],
          // You can customize icon 
          // icon: 'fa fa-exclamation-triangle', // or
          // iconTemplate : '<img src="custom-image-path.jpg" alt=""/>'
        } 
        this.confirmService.success('Thank you for your purchase.  Please look for tickets in your mailbox.','Purchase', options).subscribe(resp => {
          this.uiBusy=false;
          if(resp == Confirmation.Status.confirm){
            this.router.navigate(['/ticket/'+result.id]);
          }
        })
        
      })

  }

  createForm(){
    if(this.authService.isAuthenticated){
      
    }
    else{
      
      this.EventForm = this.formBuilder.group({
        Name: ['',[Validators.required]],
        SurName: ['',[Validators.required]],
        PhoneNumber: ['',[Validators.required, RxwebValidators.mask({mask:'(999)-999 9999' })]],
        Email: ['',[Validators.required, Validators.email]],
        AddressLine1: ['', [Validators.required]],
      AddressLine2: [''],
      AddressLine3: ['', [Validators.required]],
      PostalCode: ['', [Validators.required]],
      });
      this.subscribeEmailAvailable(this.EventForm.get("Email"));

    }
    
  }

  setValue(control:string, value:string){
    this.EventForm.patchValue({
      Amount: value
    })
    //this.DonationForm.controls[control].setValue(value);
  }

  selectOption(item){
    if(!this.isSoldOut(item))
      {
        this.selectedTicketType = item;
        this.getAvailableTickets(item);
      }
  }

  confirmUser(){
    
      if(this.EventForm.controls.Email?.errors?.notAvailable){
        return;
      }

      Object.keys(this.EventForm.controls).forEach(field => {
        const control = this.EventForm.get(field);
        control.updateValueAndValidity();
      });
  
      if(!this.EventForm.valid){
        return;
      }
      

      if(!this.authService.isAuthenticated){
        this.uiBusy = true;
        this.publicService.confirmUserByInputUserAndFamilyHostIdAndUpdateProperties({
          name : this.EventForm.get("Name").value,
          surName : this.EventForm.get("SurName").value,
          email : this.EventForm.get("Email").value,
          phoneNumber : this.EventForm.get("PhoneNumber").value,
          addressLine1 : this.EventForm.get("AddressLine1").value,
          addressLine2 : this.EventForm.get("AddressLine2").value,
          addressLine3 : this.EventForm.get("AddressLine3").value,
          postalCode : this.EventForm.get("PostalCode").value,
          paid: false
        } as HostInfoDto, "Host",false).subscribe(result => {
          
          this.uiBusy = false;
          this.userId = result.id;
          this.newUser = result;
          this.moveStep(1,true);
        })
      } 
  }

  ticketSelected(){
    if(this.authService.isAuthenticated){
      this.router.navigate(['/event-detail/'+this.event.id+"/t:"+this.selectedTicketType.id+"|q:"+this.quantity]);
    }
    else{
      this.confirmService.success('You need to login to purchase tickets.  If don\'t have an account yet you can easily signup for free.','Login / Register', {hideCancelBtn: false, hideYesBtn: false, dismissible: false, cancelText: "Close", yesText: "Login"}).subscribe(resp => {
      
        if(resp == Confirmation.Status.confirm){
          this.authService.navigateToLogin({returnUrl: '/event-detail/'+this.event.id+"/t:"+this.selectedTicketType.id+"|q:"+this.quantity});
        }
      });
    }
  }

  moveStep(num:number, validate:boolean){

    
    if(this.steps[this.stepActiveIndex] == "Event" && this.authService.isAuthenticated){
      num = num +1;
    }
    this.stepActiveIndex = this.stepActiveIndex + num; 
      this.activeStep = this.steps[this.stepActiveIndex];

  }

  subscribeEmailAvailable(control){
    control.valueChanges.subscribe(x => {
      this.publicService.emailAvailableByEmail(x).subscribe(result => {
        if(result){
          if (control.hasError('notAvailable')) {
            delete control.errors['notAvailable'];
            control.updateValueAndValidity();
          }
        }
        else{
          control.setErrors({notAvailable:true});
        }
      })
   })
  }

  getAddress($event){
    this.showGoogleAddress=false;

    let street_number =  $event.address_components.filter(a => a.types.indexOf('street_number') > -1);
    let street_name =  $event.address_components.filter(a => a.types.indexOf('route') > -1);
    let subpremise =  $event.address_components.filter(a => a.types.indexOf('subpremise') > -1);
    
    let city =  $event.address_components.filter(a => a.types.indexOf('locality') > -1);

    let province =  $event.address_components.filter(a => a.types.indexOf('administrative_area_level_1') > -1);

    let postal_code =  $event.address_components.filter(a => a.types.indexOf('postal_code') > -1);
    

    if(street_number.length){
      let addressLine1 = street_number[0].short_name;
            if(street_name.length){
        addressLine1 = addressLine1 + ' ' + street_name[0].short_name
      }

      if(subpremise.length){
        addressLine1 = addressLine1 + ' ' + subpremise[0].short_name
      }
      this.EventForm.get("AddressLine1").patchValue(addressLine1);
      //this.AddressInfo.addressLine1 = street_number[0].short_name + street_name[0].short_name;
    }

    if(city.length){
      this.EventForm.get("AddressLine2").patchValue(city[0].short_name);
    }
    if(province.length){
      this.EventForm.get("AddressLine3").patchValue(province[0].short_name);
    }
    if(postal_code.length){
      this.EventForm.get("PostalCode").patchValue(postal_code[0].short_name);
    }
    
  }

  applyPromo(){
    if(!this.promoCode || this.promoCode.trim() == '')
      return;

    this.publicService.getPromoByCodeAndEventTypeId(this.promoCode, this.selectedTicketType.id).subscribe(result => {
      if(result){
        this.promoMessage = "Promotion Applied";
        this.discountCode = result;
      }
      else{
        this.promoMessage = "Promo Code not valid";
        this.discountCode=null;
      }
    })
  }

  getAmount(){
    let price = this.quantity * this.selectedTicketType.price;
    if(!this.discountCode){
      return price;
    }
    if(this.discountCode){
      let type = this.discountCode.discountType;
      if(type == DiscountTypes.DollarAmount)
      {
        let ret =  price - (this.quantity * this.discountCode.value);
        return (ret > 0 ? ret : 0);
      }
      if(type == DiscountTypes.Percentage){
        let discount = (price * 100) / this.discountCode.value;
        let ret =  price - discount;
        return (ret > 0 ? ret : 0);
      }
      return price;
    }
  }
}
