import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const INTERAC_PAYMENTS_INTERAC_PAYMENT_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/interac-payments',
        iconClass: 'fas fa-money',
        name: '::Menu:InteracPayments',
        layout: eLayoutType.application,
        requiredPolicy: 'Mandir.InteracPayments',
      },
    ]);
  };
}
