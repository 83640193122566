<!-------------bannner------------>
<div class="banner-part"> <img src="/assets/images/donate-banner.jpg" alt="">
  <div class="container">
    <div class="wraper">
      <h1>Tickets</h1>
    </div>
  </div>
</div>
<!-------------banner------------>
<div class="ticket-container" *ngIf="tickets.length">
  <h1 class="ticket-header">Your tickets</h1>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4" 
      (click)="showTicket(ticket)"
      *ngFor="let ticket of tickets">
        <div class="card">
          <div class="card-header">
            <h2>{{ticket.eventInfo.name}}</h2>
            
          </div>
          <div class="card-body">
            <h3>{{ticket.ticketType.name}} ({{ticket.quantity * ticket.ticketType.stubsIncluded}})</h3>
            <p>
              {{getTime(ticket.eventInfo.startsAt) | date : 'EEEE MMM dd, YYYY'}} from
              {{getTime(ticket.eventInfo.startsAt) | date : 'hh:mm a'}} <span>TO</span> {{getTime(ticket.eventInfo.endsAt) | date : 'hh:mm a'}}</p>          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="ticket-container" *ngIf="ticketInfo.eventInfo">
  <h1 class="ticket-header d-print-none">Your tickets for {{ticketInfo.eventInfo.name}}
    <br />   <button 
    *abpPermission="'Mandir.TicketSales.Create'"
    
    class="btn btn-primary" (click)="markAllTicketsUsed()">Use All Tickets</button>

    <a class="btn btn-primary" 
    target="_blank"
    [routerLink]="['/ticket/'+this.ticketId+'/'+this.num+'/print']"
    
    >Print Tickets</a>
  </h1>
  <div class="ticket-group" *ngFor="let item of counter">
    <div class="ticket" style="margin-top:10px">
      <div class="ticket-used" [ngClass]="{'hidden': !isTicketUsed(item)}">
        <h2>       
          xxxx Ticket Used xxxx <br />
          xxxx Ticket Used xxxx <br />xxxx Ticket Used xxxx
        </h2>
      </div>
      <div class="ticket-used" [ngClass]="{'hidden': ticketInfo.paymentVerified}">
        <h2>       
          xxxx Payment Not Verified xxxx <br />
          xxxx Payment Not Verified xxxx <br />xxxx Payment Not Verified xxxx
        </h2>
      </div>
  
      <div class="left">
        <div class="image">
          <div class="image-back">
            <img [src]="ticketInfo.eventInfo.imageUrl" alt="">
          </div>
          <p class="admit-one">
            <span>ADMIT ONE</span>
            <span>ADMIT ONE</span>
            <span>ADMIT ONE</span>
          </p>
          
          <div class="ticket-number">
            <p>
              {{item}} of {{ticketInfo.quantity * ticketInfo.ticketType.stubsIncluded}}
            </p>
          </div>
        </div>
        <div class="ticket-info">
          <div class="stub-number">
            <p style="font-size:1.5rem">
              Ticket#{{getTicketNumber(ticketInfo.ticketNumber,item-1)}}
            </p>
          </div>
          <p class="date">
            <span>{{getTime(ticketInfo.eventInfo.startsAt) | date : 'EEEE'}}</span>
            <span class="june-29">{{getTime(ticketInfo.eventInfo.startsAt) | date : 'MMM'}} {{getTime(ticketInfo.eventInfo.startsAt) | date : 'dd'}}</span>
            <span>{{getTime(ticketInfo.eventInfo.startsAt) | date : 'YYYY'}}</span>
          </p>
          <div class="show-name">
            <h1>Vaishno Devi Mandir</h1>
            <h2>{{ticketInfo.eventInfo.name}}
              </h2>
          </div>
          <div class="time">
            
          </div>
          <div class="table event-location">
            <p [innerHTML]="ticketInfo.eventInfo.location"></p>
          </div>
          <p class="location">
            <span>{{ticketInfo.buyer.name}} {{ticketInfo.buyer.surname}}</span>
            <span class="separator"><i class="fa fa-utensils"></i></span>
            <span *ngIf="ticketInfo.location">{{ticketInfo.location}}</span>
            <span *ngIf="!ticketInfo.location">Table TBA &#64; Venue</span>

            
          </p>
        </div>
      </div>
      <div class="right">
        <p class="admit-one">
          <span>ADMIT ONE</span>
          <span>ADMIT ONE</span>
          <span>ADMIT ONE</span>
        </p>
        <div class="right-info-container">
          <div class="show-name">
            <h1>{{ticketInfo.eventInfo.name}}</h1>
          </div>
          <div class="time">
            <p>{{getTime(ticketInfo.eventInfo.startsAt) | date : 'MMM dd, yyyy'}}</p>
  
            <p>{{getTime(ticketInfo.eventInfo.startsAt) | date : 'hh:mm a'}} <span>TO</span> {{getTime(ticketInfo.eventInfo.endsAt) | date : 'hh:mm a'}}</p>
          </div>
          <div class="barcode">
            <qr-code [value]="myAngularxQrCode+'/'+item" size="100" level="M"></qr-code>
          </div>
          <p class="ticket-number">
            #{{getTicketNumber(ticketInfo.ticketNumber,item-1)}}<br/>
            <button 
            *abpPermission="'Mandir.TicketSales.Create'"
            class="btn btn-primary"
            (click)="markTicketUsed(item)"
            >Use it</button>
          </p>
        </div>
      </div>
    </div>
    <div class="ticket ticket-back">
      <div class="left" style="width: 610px">
        
        <div class="row">
          <div class="col-12">
            <h3 style="color:#FFF">Event Schedule: {{getTime(ticketInfo.eventInfo.startsAt) | date : 'h:mm a'}} <span> - </span> {{getTime(ticketInfo.eventInfo.endsAt) | date : 'hh:mm a'}} </h3>
            1. Gate opens - 6:00 PM.<br />
            2. Snacks and Drinks - 7:00 PM.to 8:00 PM<br />
            3. Opening Ceremony - 8:00 PM to 8:15 PM <br />
            4. Cultural Dance Performances - 8:15 PM to 9:30 PM<br />
            5. Dinner & DJ - 9:30 PM to 11:30 PM. <br />
            <!-- ***** Open Cash Bar ****** -->
            </div>
          
          <div class="col-12 text-center ticket-footer" ><br />
            <strong>Contact Meenakshi Sharma: Programs&#64;VaishnoDeviMandir.ca <br />
              For Sponsorships, Advertisements, Stalls Booking, VIP Table. </strong>
          </div>
        </div>
      </div>
      <div class="right" style="width: 180px">

      </div>
      
    </div>
  </div>
</div>

<div class="ticket-container" *ngIf="!tickets.length && !ticketInfo.eventInfo">

  <h1 class="ticket-header d-print-none">You do not have any tickets yet.</h1>

</div>