<div >
<div class="row" *ngIf="paymentModes.length > 1">
  <div class="col-12">
    <div class="flex flex-wrap gap-3">
      <div class="flex align-items-center mb-20" *ngFor="let mode of paymentModes">
        <p-radioButton name="paymentMode" [value]="mode.key" [(ngModel)]="paymentMode" [ngModelOptions]="{standalone: true}"
        (click)="setPaymentModeMessate()"
          [inputId]="'paymentMode_'+mode.key"></p-radioButton>
        <label [for]="'paymentMode_'+mode.key" class="ml-10">{{mode.name}}</label>
      </div>
      
    </div>

  </div>
</div>

<div class="row" *ngIf="paymentMode ==='Online'">
  <div class="col-12 next-butn">
    <button class="butn" (click)="handlePayOnline()" [disabled]="uiBusy">{{ '::AcceptPayment:PayOnline' | abpLocalization }}</button>
    <div *ngIf="paymentStatus" class="payment-status mt-2">
      {{paymentStatus}}`
    </div>
    <!-- <app-stripe #stripe
    [email]="newUser?.email"
    [name]="(newUser ? newUser.name + ' '+ newUser.surname : '')"
      [amount]="dollarAmount * 100" (paymentVerified)="sendRequest($event)"></app-stripe> -->
  </div>
</div>

<div class="row" *ngIf="paymentMode !=='Online'">
  <div class="col-12" *ngIf="receiptCorrection && paymentMode && (paymentMode ==='Cash' || paymentMode ==='Cheque')">
    <div class="col-3"
    [ngClass]="{'text-danger':paymentDetailMissing.transactionDate}">
    <strong >{{ '::AcceptPayment:SelectTransactionDate' | abpLocalization }}</strong>
    </div>
    <input type="date"
    [(ngModel)]="transactionDate" [ngModelOptions]="{standalone: true}" 
    [ngClass]="{'border border-danger':paymentDetailMissing.transactionDate}"/>
  </div>
  <div *ngIf="paymentMode && 
    (paymentMode ==='Stripe' || paymentMode ==='CardTap' || paymentMode ==='Interac' || paymentMode ==='Cheque')">
    <div class="col-12" *ngIf="paymentMode ==='CardTap'">
      <div class="col-3"
      [ngClass]="{'text-danger':paymentDetailMissing.transactionDate}">
      <strong >{{ '::AcceptPayment:SelectTransactionDate' | abpLocalization }}</strong>
      </div>
      <input type="date"
      [(ngModel)]="transactionDate" [ngModelOptions]="{standalone: true}" 
      [ngClass]="{'border border-danger':paymentDetailMissing.transactionDate}"/>
    </div>
    <div class="col-12" *ngIf="paymentMode !=='Stripe'">
      <div class="col-3"
      [ngClass]="{'text-danger':paymentDetailMissing.transactionId}">
      
      <strong *ngIf="paymentMode ==='CardTap' ">{{ '::AcceptPayment:EnterCardTransactionID' | abpLocalization }}</strong>
      <strong *ngIf="paymentMode ==='Interac' ">{{ '::AcceptPayment:EnterInteracTransactionID' | abpLocalization }}</strong>
      <strong *ngIf="paymentMode ==='Cheque' ">{{ '::AcceptPayment:EnterChequeNumber' | abpLocalization }}</strong>

      </div>
      <input 
      [(ngModel)]="transactionId" [ngModelOptions]="{standalone: true}" 
      [ngClass]="{'border border-danger':paymentDetailMissing.transactionId}"/>
    </div>
    <!-- <div class="col-12 mt-2" *ngIf="paymentMode ==='CardTap'">
      <div class="col-3"
      [ngClass]="{'text-danger':paymentDetailMissing.cardNum}"><strong>Enter Last 4 Digits of Card Number:</strong></div>
      <input type="number"
      [(ngModel)]="cardNum" [ngModelOptions]="{standalone: true}" 
      [ngClass]="{'border border-danger':paymentDetailMissing.cardNum}"/>
    </div> -->
  </div>
  <div class="col-12 mt-2"  >
    <span [ngClass]="{'text-danger':paymentDetailMissing.comments}"><strong>{{paymentModeMessage}}:</strong></span>

  </div>
  <div class="col-12">
    <textarea rows="5" cols="50" *ngIf="paymentMode && paymentMode !=='Online'"
    [(ngModel)]="paymentDetail" [ngModelOptions]="{standalone: true}" 
    [ngClass]="{'border border-danger':paymentDetailMissing.comments}"></textarea>
  </div>
</div>
<div class="row mt-30">
  <div class="col-12 next-butn">
    <button class="butn" *ngIf="paymentMode !=='Online'" (click)="onSubmit()"
    [disabled]="uiBusy">{{ '::AcceptPayment:Submit' | abpLocalization }}</button>

    <!-- <button *ngIf="paymentMode==='Online'" class="butn" (click)="reInitPayment()" [disabled]="uiBusy">{{'::Retry'|abpLocalization}} </button> -->
  </div>
</div>
</div>