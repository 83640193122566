import { Component, Input, Output, EventEmitter, AfterViewInit, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalizationService, ConfigStateService } from '@abp/ng.core';
import { SessionStateService } from '@abp/ng.core';
import { ManidrSettingService } from 'src/app/services/settings.service';
import { ResourceLinkDto } from '@proxy/resource-links';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {
  @Input() hasLoggedIn: boolean;
  @Input() currentUser$: Observable<any>;
  @Input() resourceLinks: ResourceLinkDto[];
  
  @Output() loginClick = new EventEmitter<void>();
  @Output() logoutClick = new EventEmitter<void>();

  currentLanguage: string = 'en-GB';
  isMultilingualEnabled: boolean = false;
  logoUrl: string = '';
  isHeaderShrink: boolean = false;
  webSiteMode: string = environment.websiteMode;
  
  constructor(
    private localizationService: LocalizationService,
    private sessionStateService: SessionStateService,
    private configStateService: ConfigStateService,
    private settingService: ManidrSettingService
  ) {
    this.logoUrl = this.settingService.getLogoUrl();
    // Get current language from ABP
    this.currentLanguage = this.sessionStateService.getLanguage() || 'en-GB';
    
    // Set the initial language attribute on the HTML element
    const langCode = this.currentLanguage.split('-')[0];
    document.documentElement.setAttribute('lang', langCode);
    
    // Keep the body class for backward compatibility
    if (langCode === 'hi') {
      document.body.classList.add('hindi-text');
    } else {
      document.body.classList.remove('hindi-text');
    }

    // Check if the Multilingual feature is enabled
    this.isMultilingualEnabled = this.configStateService.getFeature('Premium.Multilingual') === 'true';
  }

  ngAfterViewInit(): void {
    // No additional initialization needed
  }

  // Add scroll event listener for header shrink effect
  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isHeaderShrink = scrollPosition > 50;
    
    // Apply class to header element for animation
    const headerElement = document.querySelector('.header');
    if (headerElement) {
      if (this.isHeaderShrink) {
        headerElement.classList.add('header-shrink');
        headerElement.classList.add('scrolled');
      } else {
        headerElement.classList.remove('header-shrink');
        headerElement.classList.remove('scrolled');
      }
    }
  }

  login() {
    this.loginClick.emit();
  }

  logout() {
    this.logoutClick.emit();
  }

  // Get parent resource links (where parentId is null)
  getParentResourceLinks(): ResourceLinkDto[] {
    if (!this.resourceLinks) return [];
    return this.resourceLinks.filter(link => !link.parentId);
  }

  // Get child resource links for a specific parent ID
  getChildResourceLinks(parentId: string): ResourceLinkDto[] {
    if (!this.resourceLinks) return [];
    return this.resourceLinks.filter(link => link.parentId === parentId);
  }

  // Check if a resource link has children
  hasChildren(parentId: string): boolean {
    return this.getChildResourceLinks(parentId).length > 0;
  }

  switchLanguage(culture: string) {
    // Convert simple language codes to region-specific codes for better ABP compatibility
    let abpCulture = culture;
    if (culture === 'gu') {
      abpCulture = 'gu-IN'; // Gujarati (India)
    } else if (culture === 'pa') {
      abpCulture = 'pa'; // Punjabi (India)
    } else if (culture === 'hi') {
      abpCulture = 'hi-IN'; // Hindi (India)
    }
    
    // Store the language preference in ABP session with region code
    this.sessionStateService.setLanguage(abpCulture);
    
    // Set the language attribute on the HTML element (use base code for HTML)
    const langCode = culture.split('-')[0];
    document.documentElement.setAttribute('lang', langCode);
    
    // Remove all language-specific classes
    document.body.classList.remove('hindi-text', 'gujarati-text', 'punjabi-text');
    
    // Add the appropriate language class
    if (langCode === 'hi') {
      document.body.classList.add('hindi-text');
    } else if (langCode === 'gu') {
      document.body.classList.add('gujarati-text');
    } else if (langCode === 'pa') {
      document.body.classList.add('punjabi-text');
    }
    
    // Update current language
    this.currentLanguage = abpCulture;
    
    // Log to confirm language setting
    console.log('Language changed to:', abpCulture, 'Lang code:', langCode);
    console.log('HTML lang attribute:', document.documentElement.getAttribute('lang'));
    console.log('Body classes:', document.body.className);
    
    // This will cause a page refresh to apply the new language
    //window.location.reload();
  }

  // Helper method to determine if current language is English
  isEnglish(): boolean {
    return this.currentLanguage === 'en-GB' || this.currentLanguage === 'en';
  }
  
  // Helper method to get the display name of the current language
  getLanguageDisplayName(): string {
    const langCode = this.currentLanguage.split('-')[0];
    
    switch (langCode) {
      case 'en':
        return 'English';
      case 'hi':
        return 'हिंदी';
      case 'gu':
        return 'ગુજરાતી';
      case 'pa':
        return 'ਪੰਜਾਬੀ';
      default:
        return 'English';
    }
  }
} 