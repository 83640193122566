import { RestService, Rest } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type { SettingValue } from '../volo/abp/settings/models';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  apiName = 'Default';
  

  getMandirSettings = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, SettingValue[]>({
      method: 'GET',
      url: '/api/app/settings/mandir-settings',
    },
    { apiName: this.apiName,...config });
  

  getSettingsByNames = (names: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SettingValue[]>({
      method: 'GET',
      url: '/api/app/settings/settings',
      params: { names },
    },
    { apiName: this.apiName,...config });
  

  getUserSettingsByNames = (names: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, SettingValue[]>({
      method: 'GET',
      url: '/api/app/settings/user-settings',
      params: { names },
    },
    { apiName: this.apiName,...config });
  

  setSettingsByInput = (input: SettingValue[], config?: Partial<Rest.Config>) =>
    this.restService.request<any, SettingValue[]>({
      method: 'POST',
      url: '/api/app/settings/set-settings',
      body: input,
    },
    { apiName: this.apiName,...config });
  

  syncLanguageTextsByLanguagesAndOverrideLanguageTexts = (languages: string[], overrideLanguageTexts: boolean, config?: Partial<Rest.Config>) =>
    this.restService.request<any, void>({
      method: 'POST',
      url: '/api/app/settings/sync-language-texts',
      params: { overrideLanguageTexts },
      body: languages,
    },
    { apiName: this.apiName,...config });

  constructor(private restService: RestService) {}
}
