import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { CalendarComponent } from './calendar/calendar.component';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { PoojaDetailsComponent } from './pooja-details/pooja-details.component';
import { CalendarModule as PrimengCalendar }   from 'primeng/calendar';
import { TopNavComponent } from './top-nav/top-nav.component';
import { MenuModule } from 'primeng/menu';
import { DropdownModule } from 'primeng/dropdown';
import { StepsModule } from 'primeng/steps';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileUploadModule } from 'primeng/fileupload';
import { AcceptPaymentComponent } from './accept-payment/accept-payment.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollTextComponent } from './scroll-text/scroll-text.component';
import {
  NgbCollapseModule,
  NgbDatepickerModule
} from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [
    CalendarComponent,
    PoojaDetailsComponent,
    TopNavComponent,
    FileUploadComponent,
    AcceptPaymentComponent,
    ScrollTextComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgbDatepickerModule,
    NgxValidateCoreModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    PrimengCalendar,
    MenuModule,
    DropdownModule,
    StepsModule,
    FileUploadModule,
    RadioButtonModule,
    CommonModule,
    RouterModule
  ],
  exports: [
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgbDropdownModule,
    NgxValidateCoreModule,
    CalendarComponent,
    
    TopNavComponent,
    FileUploadComponent,
    AcceptPaymentComponent,
    ScrollTextComponent,
    HeaderComponent,
    FooterComponent
  ],
  providers: [CustomDateFormatter]
})
export class SharedModule {}
