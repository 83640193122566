import { AuthService, ConfigStateService, PermissionService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicService } from '@proxy/public';
import { SlotBookingWithNavigationPropertiesDto } from '@proxy/slot-bookings';
import { Location } from '@angular/common';

@Component({
  selector: 'app-slot-confirmation',
  templateUrl: './slot-confirmation.component.html',
  styleUrls: ['./slot-confirmation.component.scss']
})
export class SlotConfirmationComponent implements OnInit{

  canValidate:boolean=false;
  slot:SlotBookingWithNavigationPropertiesDto;
  currentUser:any;

  constructor(
      private permissionService: PermissionService,
      private service: PublicService,
      private activatedRoute: ActivatedRoute,
      private configState: ConfigStateService,
      private authService: AuthService,
      public location: Location
  ){
    
    
  }

  ngOnInit(): void {

    this.canValidate= this.permissionService.getGrantedPolicy('Mandir.App.ValidateSlotConfirmations');
    this.configState.getDeep$('currentUser').subscribe(user => {
      this.currentUser = user;
      this.loadData();
    });
    
    
  }

  loadData(){
    if(!this.authService.isAuthenticated )
    return;

    this.activatedRoute.params.subscribe(result => {
      
      if(result.id){
        this.service.getSlotBookingById(result.id).subscribe(resp => {
          if(resp.identityUser.id == this.currentUser.id || this.canValidate){
            this.slot = resp;
          }
        })
      }
    })
  }
  useSlot(slot:SlotBookingWithNavigationPropertiesDto){
    this.service.markSlotBookingUsedById(slot.slotBooking.id).subscribe(result => {
      this.loadData();
    })
  }

}
