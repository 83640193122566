import { mapEnumToOptions } from '@abp/ng.core';

export enum BookingSources {
  Website = 0,
  Phone = 1,
  Email = 2,
  InPerson = 3,
  Other = 4,
}

export const bookingSourcesOptions = mapEnumToOptions(BookingSources);
