<div class="card styleCard">
  <div class="card-header">
    <h2 class="card-title d-inline-block styleCardTitle">
      {{ 'AbpAccount::ResetPassword' | abpLocalization }}
    </h2>
  </div>
  <div class="card-body">
    <p>{{ 'AbpAccount::ResetPassword_Information' | abpLocalization }}</p>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" validateOnSubmit>
      <div class="mb-3">
        <label for="password" class="form-label">{{ 'AbpAccount::Password' | abpLocalization }}</label>
        <span> * </span>
        <input
          type="password"
          id="password"
          class="form-control"
          formControlName="password"
          autofocus
        />
      </div>

      <div class="mb-3">
        <label for="confirm-password" class="form-label">{{ 'AbpAccount::ConfirmPassword' | abpLocalization }}</label>
        <span> * </span>
        <input
          type="password"
          id="confirm-password"
          class="form-control"
          formControlName="confirmPassword"
        />
      </div>

      <abp-button
        class="d-grid"
        buttonClass="mt-2 mb-3 btn btn-primary"
        [loading]="inProgress"
        buttonType="submit"
      >
        {{ 'AbpAccount::Submit' | abpLocalization }}
      </abp-button>

      <a routerLink="/account/login">
        <i class="fas fa-arrow-left"></i>
        {{ 'AbpAccount::BackToLogin' | abpLocalization }}
      </a>
    </form>
  </div>
</div> 